<div class="bigContainer" *ngIf="!loading.main">
  <div class="leftContainer">
    <div class="news" *ngIf="type == 1"> {{'news' | translate}}</div>
    <div class="news" *ngIf="type == 2"> {{'notification' | translate}}</div>

    <div *ngIf="type !=3">
      <img class="image" src="/assets/defaultImage.png"  [ngClass]="{'invisibleImage' : obj.loaded}">
      <img class="image" [src]="obj.image" (load)="obj.loaded = true" (error)="obj.image='assets/defaultImage.png'" [ngClass]="{'invisibleImage' : !obj.loaded}">
    </div>

    <div *ngIf="type ==3">
      <img class="image" src="/assets/defaultImage.png"  [ngClass]="{'invisibleImage' : obj.loaded}">
      <img class="image" [src]="obj.logo" (load)="obj.loaded = true" (error)="obj.logo='assets/defaultImage.png'" [ngClass]="{'invisibleImage' : !obj.loaded}">
    </div>

    <div class="text" *ngIf="type != 3">
      <div class="title">
        {{obj.title}}
      </div>
      <div class="subtitle">
        {{obj.subtitle}}
      </div>
      <div class="body" [innerHTML]="obj.body">
      </div>
    </div>
    <div class="text" *ngIf="type == 3">
      <div class="title">
        {{obj.name}}
      </div>
      <div class="subtitle">
        {{obj.address}} {{obj.city}}
      </div>
      <div class="body">
        {{'phone' | translate}}: {{obj.phone}} <br>
        {{'orderTime' | translate}}: {{obj.orderTime}} <br>
        {{'deliveryTime' | translate}}: {{obj.deliveryTime}} <br>
        {{'minimalCart' | translate}}: {{obj.minDelivery}} <br>
      </div>
    </div>
    <div class="extraData" *ngIf="type != 3">
      <hr class="separator">
      <div class="autorAndDate float-left" *ngIf="obj.admin != null">
        {{'author' | translate}}: <span class="font-weight-normal">{{obj.admin}}</span>
      </div>
      <div class="autorAndDate float-right">
        {{'date' | translate}}: <span class="font-weight-normal">{{obj.createdAt | date: 'dd.MM.y.'}}</span>
      </div>
    </div>

  </div>
  <div class="rightContainer">
    <div class="option-right" (click)="delete()"> <mat-icon>delete</mat-icon>  {{'delete' | translate}}</div> <br class="web-break">
    <div class="option-right" (click)="edit()"><mat-icon>edit</mat-icon>  {{'edit' | translate}}</div>
  </div>

</div>

<div class="footerBig" *ngIf="!loading.main">
  <app-footer-big></app-footer-big>
</div>

<div class="loadingContainer"  [ngClass]="{'visibleLoading' : loading.other && loading.main}">
  <div class="divForSpinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
