<div class="main-container" (scroll)="scrollWindow($event)" (window:resize)="onResized()">
  <div class="section-header" [ngClass]="{'smaller-header' : !showHeader}">
    <div class="section-hide-show" (click)="showHeader = !showHeader">
      <span [ngClass]="{'up-arrow' : !showHeader, 'down-arrow' : showHeader}"><mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div class="small-section-title" [ngClass]="{'hidden-small-title' : showHeader}">
      <img src="assets/cart_blue.png" class="section-icon-small">  {{'customers' | translate}} ({{total}})
    </div>
    <div class="section-header-inner" [ngClass]="{'hide-inner' : !showHeader}">
      <div class="section-title">
        <div class="inner-section-title">
          <img src="assets/cart_blue.png" class="section-icon">  {{'customers' | translate}} ({{total}})
        </div>
      </div>
      <div class="section-track">
        <div class="section-item" [matMenuTriggerFor]="searchPer">
          <span *ngIf="showText">{{'searchPer' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>settings</mat-icon></span>
        </div>
        <div class="section-item" [matMenuTriggerFor]="date">
          <span *ngIf="showText">{{'date' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>date_range</mat-icon></span>
        </div>
        <div class="section-item" [matMenuTriggerFor]="deliveries">
          <span *ngIf="showText">{{'deliveries' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>euro_symbol</mat-icon></span>
        </div>
<!--        <div class="section-item" [matMenuTriggerFor]="active">-->
<!--          <span *ngIf="showText">{{'active' | translate}} </span>-->
<!--          <span *ngIf="!showText"><mat-icon>visibility</mat-icon></span>-->
<!--        </div>-->
        <div class="search-item">
          <div class="search-left" (click)="page=0; filterCustomers()">
            <span *ngIf="showText">{{'search' | translate}} </span>
            <span *ngIf="!showText"><mat-icon>search</mat-icon></span>
          </div>
          <div class="search-right" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-menu #searchPer="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.id" placeholder="ID">
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="text" [(ngModel)]="filter.name" placeholder="{{'fullName' | translate}}">
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="text" [(ngModel)]="filter.email" placeholder="{{'emal' | translate}}">
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="text" [(ngModel)]="filter.identifier" placeholder="{{'identifierLegal' | translate}}">
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="text" [(ngModel)]="filter.phone" placeholder="{{'phone' | translate}}">
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #date="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'dateFrom' | translate}}"
               (dateChange)="filter.dateFrom = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="dateTo" placeholder="{{'dateTo' | translate}}"
               (dateChange)="filter.dateTo = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #deliveries="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.numberOfOrdersMin"
               placeholder="{{'numberOfOrdersMin' | translate}}">
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.numberOfOrdersMax"
               placeholder="{{'numberOfOrdersMax' | translate}}">
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #active="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-select [(ngModel)]="filter.active" placeholder="{{'active' | translate}}">
        <mat-option [value]="-1">{{'all' | translate}}</mat-option>
        <mat-option [value]="0">{{'unactive' | translate}}</mat-option>
        <mat-option [value]="1">{{'active' | translate}}</mat-option>

      </mat-select>
    </div>
  </mat-menu>
  <div class="main-body" [ngClass]="{'main-body-higher' : !showHeader}">
    <div class="chips-list">
      <mat-chip-list>
        <mat-chip *ngIf="filter.id != ''">ID: {{filter.id}}
          <mat-icon class="chips-icon" (click)="filter.id = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.name != ''">{{'fullName' | translate}}: {{filter.name}}
          <mat-icon class="chips-icon" (click)="filter.name = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.email != ''">{{'email' | translate}}: {{filter.email}}
          <mat-icon class="chips-icon" (click)="filter.email = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.identifier != ''">{{'identifierLegal' | translate}}: {{filter.identifier}}
          <mat-icon class="chips-icon" (click)="filter.identifier = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.phone != ''">{{'phone' | translate}}: {{filter.phone}}
          <mat-icon class="chips-icon" (click)="filter.phone = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.city != ''">{{'city' | translate}}: {{getCity(filter.city)}}
          <mat-icon class="chips-icon" (click)="filter.city = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.dateFrom != -1">{{'dateFrom' | translate}}: {{filter.dateFrom | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.dateFrom = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.dateTo != -1">{{'dateTo' | translate}}: {{filter.dateTo | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.dateTo = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.numberOfOrdersMin != 0">{{'minDeliveryPriceFrom' | translate}}
          : {{filter.numberOfOrdersMin}}
          <mat-icon class="chips-icon" (click)="filter.numberOfOrdersMin = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.numberOfOrdersMax != 0">{{'minDeliveryPriceTo' | translate}}
          : {{filter.numberOfOrdersMax}}
          <mat-icon class="chips-icon" (click)="filter.numberOfOrdersMax = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.deliveryPriceMin != 0">{{'deliveryPriceFrom' | translate}}: {{filter.deliveryPriceMin}}
          RSD
          <mat-icon class="chips-icon" (click)="filter.deliveryPriceMin = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.deliveryPriceMax != 0">{{'deliveryPriceTo' | translate}}: {{filter.deliveryPriceMax}}
          RSD
          <mat-icon class="chips-icon" (click)="filter.deliveryPriceMax = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.active != -1">{{'active' | translate}}: {{getActive(filter.active) | translate}}
          <mat-icon class="chips-icon" (click)="filter.active = -1">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="action-list" [ngClass]="{'short-action-list' : selected.length == 0}" *ngIf="supplier.role === 3">
      <div class="action-list-inner" [ngClass]="{'invisible-action' : selected.length == 0}">
        <div class="button-action delete" (click)="deleteCustomers()">{{'deleteSelected' | translate}}
          ({{selected.length}})
        </div>
      </div>
    </div>
    <div class="table-container">
      <ngx-datatable
        class="material table-list"
        infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]=true
        [infiniteScrollThrottle]="150"
        (scrolled)="onScroll()"
        [rows]="customers"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [selected]="selected"
        [rowClass]="getRowClass"
        [loadingIndicator]="loading"
        [selectionType]="'checkbox'"
        (activate)='onActivate($event)'
        [rowHeight]="50"
        (sort)="onSort($event)"
        (select)="onSelect($event)">
        <ngx-datatable-column *ngIf="supplier.role === 3"
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true">
        </ngx-datatable-column>
        <ngx-datatable-column name="Id" sortable="false" prop="id" [width]="10">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'productName' | translate}}" sortable="false" prop="objectName">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <div style="display: flex">
              {{value}}
              <div *ngIf="row.blockCount > 0 && supplier.role == 2" class="supplier-block" title="{{'customerBlocked' | translate}}"></div>
              <div *ngIf="row.blockCount > 0 && supplier.role == 3" class="supplier-block" title="{{'numberOfBlocks' | translate}}">{{row.blockCount}}</div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'emal' | translate}}" sortable="false" prop="email">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'phone' | translate}}" sortable="false" prop="purchasePhone">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'numberOfOrders' | translate}}" sortable="false" prop="numberOfOrders">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'date' | translate}}" sortable="false" prop="createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date : 'dd/MM/yy' : 'Europe/Belgrade'}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
    <div class="big-footer" *ngIf="!loading">
      <app-footer-big></app-footer-big>
    </div>
</div>
