<div class="mainPart">
  <div class="box">
    <div class="breadcrumbs">
      <div class="float-left">
        <span [ngClass]="{'activeType' : type == 1}" class="crumb"
              (click)="onNavClick('main/list',-1, '1');"> {{'news' | translate}} </span>
        /
        <span [ngClass]="{'activeType' : type == 2}" class="crumb"
              (click)="onNavClick('main/list',-1, '2')"> {{'notifications' | translate}} </span>
      </div>
      <div class="float-right add-new" (click)="addNew()">
        <span *ngIf="type==1">
          {{'newNews' | translate}}
        </span>
        <span *ngIf="type==2">
          {{'newNotification' | translate}}
        </span></div>
    </div>
    <div  *ngIf="supplier.role == 3" class="add-new-m" (click)="addNew()">
         <span *ngIf="type==1">
          {{'newNews' | translate}}
        </span>
      <span *ngIf="type==2">
          {{'newNotification' | translate}}
        </span>
    </div>
    <div class="boxContainer" infiniteScroll [infiniteScrollDistance]="5" [scrollWindow]=true
         [infiniteScrollThrottle]="10"
         (scrolled)="onScroll()">

      <div class="specificBox" *ngFor="let o of objects" (click)="onNavClick('main/specifics', o.id , type)">
        <img [src]="o.image" (load)="o.loaded = true" class="boxImage" (error)="o.image = 'assets/default.png'"
             [hidden]="!o.loaded">
        <img class="boxImage" src="/assets/default.png" [hidden]="o.loaded">
        <div class="downBottomBox">
        </div>
        <div class="downBottomBoxText">
          <span class="boxName"> {{o.title}} </span>
          <br>
          <span class="boxDescription"> {{o.subtitle}} </span>
        </div>
      </div>
      <div class="specificBox" *ngFor="let o of suppliers" (click)="onNavClick('main/specifics', o.id , type)">
        <img [src]="o.logo" (load)="o.loaded = true" class="boxImage" (error)="o.logo = 'assets/default.png'"
             [hidden]="!o.loaded">
        <img class="boxImage" src="/assets/default.png" [hidden]="o.loaded">
        <div class="downBottomBox">
        </div>
        <div class="downBottomBoxText">
          <span class="boxName"> {{o.name}} </span>
          <br>
          <span class="boxDescription"> {{o.address}} </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footerBig">
  <app-footer-big></app-footer-big>
</div>

