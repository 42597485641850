import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'enabavka';

  public ngOnInit()
  {
    console.log('Version 3.0');
    navigator.serviceWorker.register('service-worker.js').then((registration) => {
    });
  }
}
