import {Component, HostListener, OnInit} from '@angular/core';
import {City, ProductEdit, Supplier} from '../model';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {DialogComponent} from '../dialog/dialog.component';
import {Router} from '@angular/router';
import {EditProductComponent} from '../edit-product/edit-product.component';
import {EditProductSpecificComponent} from '../edit-product-specific/edit-product-specific.component';

@Component({
  selector: 'app-product-edits',
  templateUrl: './product-edits.component.html',
  styleUrls: ['./product-edits.component.scss']
})
export class ProductEditsComponent implements OnInit {

  public loading = false;
  public sort = 'created_at';
  public dir = 'desc';
  public page = 0;
  public edits: ProductEdit[];
  public allSuppliersList: Supplier[];
  public cities: City[];
  public supplier: Supplier;
  public total = 0;
  public selected = [];
  public showText = true;
  public showHeader = true;
  public logoWidth = 30;
  public filter = {};

  constructor(private router: Router, private translate: TranslateService, private dialog: MatDialog, private apiService: ApiServiceService, private main: MainComponent) {
  }

  public ngOnInit() {

    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role !== 3) {
      this.router.navigate(['main/start']);
    }

    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 50;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }

    this.filterEdits();

    this.apiService.getAllSuppliers().subscribe(
      (suppliers: Supplier[]) => {
        this.allSuppliersList = suppliers;
      },
      (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );
  }

  @HostListener('window:scroll', ['$event'])
  public scrollWindow(event) {
    if (window.pageYOffset > 100) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }

  public buttonAction(type) {
    const ids = [];
    const index = [];
    this.selected.forEach((x) => {
      ids.push(x.id);
    });
    let text = '';
    if (type === 1) { // activate
      text = this.translate.instant('areYouSureYouWantToApplyChanges');
    } else if (type === 2) { // deactivate
      text = this.translate.instant('areYouSureYouWantToCancelChanges');
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'actionButton', text}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.applyProductEdits(ids, type).subscribe(
          (data) => {
            this.selected = [];
            ids.forEach((id) => {
              const i = this.edits.findIndex((x) => x.id = id);
              if (i != -1) {
                this.edits.splice(i, 1);
                this.edits = [...this.edits];
              }
            });
            this.total = this.total - ids.length;
          }, (err) => {
            this.main.autoLogin(err.status);
          },
        );
      }
    });
  }

  public onResized() {
    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 50;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }
  }

  public onSort(event) {
    this.dir = event.sorts[0].dir;
    this.sort = event.sorts[0].prop;

    if (this.sort === 'createdAt') {
      this.sort = 'created_at';
    } else if (this.sort === 'deliveryPrice') {
      this.sort = 'delivery_price';
    } else if (this.sort === 'minDelivery') {
      this.sort = 'min_delivery';
    } else if (this.sort === 'numberOfOrders') {
      this.sort = 'number_of_orders';
    } else if (this.sort === 'isActive') {
      this.sort = 'is_active';
    }

    this.filterEdits();
  }

  public onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  public onScroll() {
    this.page++;
    this.apiService.filterEdits(this.filter, this.sort, this.dir, this.page).subscribe(
      (data: { edits: ProductEdit[], total: number }) => {
        this.edits = [...this.edits, ...data.edits];
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public getSelectedCount(type: number) {
    let count = 0;
    if (type === 1) {
      this.selected.forEach((x) => {
        if (x.isActive === type) {
          count++;
        }
      });
    } else if (type === 0) {
      this.selected.forEach((x) => {
        if (!x.isActive) {
          count++;
        }
      });
    }

    return count;
  }

  public onActivate(event) {
    if (event.type == 'click') {
      if (event.cellIndex != 0) {
        const edit = event.row;
        const dialogRef = this.dialog.open(EditProductSpecificComponent, {
          data: {suppliers: this.allSuppliersList, product: edit}, panelClass: 'warningDialog'
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const index = this.edits.findIndex((x) => x.id = result);
            if (index != -1) {
              this.edits.splice(index, 1);
              this.edits = [...this.edits];
              this.total = this.total - 1;
            }
          }
        });
      }
    }
  }

  public clearFilter() {
    this.filter = {};
  }

  public getActive(id) {
    if (id == 1) {
      return 'active';
    } else if (id == 0) {
      return 'unactive';
    } else if (id == -1) {
      return 'all';
    }
  }

  public getRowClass(row) {

  }

  public filterEdits() {
    this.loading = true;
    this.apiService.filterEdits(this.filter, this.sort, this.dir, this.page).subscribe(
      (data: { edits: ProductEdit[], total: number }) => {
        this.edits = data.edits;
        this.total = data.total;
        this.loading = false;
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

}
