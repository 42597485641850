import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {NewsOrNotice, Supplier} from '../model';

@Component({
  selector: 'app-specific-list',
  templateUrl: './specific-list.component.html',
  styleUrls: ['./specific-list.component.scss'],
})
export class SpecificListComponent implements OnInit {

  public id: number;
  public type: number;
  public page = 0;
  public objects: NewsOrNotice[];
  public suppliers: Supplier[];
  public loading = true;
  public supplier: Supplier;

  constructor(private route: ActivatedRoute, private main: MainComponent, private apiService: ApiServiceService, private router: Router) { }

  public ngOnInit() {

    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role !== 3) {
      this.router.navigate(['main/start']);
    }

    this.page = 0;
    this.route.params.subscribe((params) => {
      this.type = +params.type;

      if (this.type === 1) {
        this.main.text = 'news';
        this.apiService.getNews(this.page).subscribe(
          (data: NewsOrNotice[]) => {
            this.objects = data;
            this.loading = false;
          },
          (err) => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          },
        );
      } else if (this.type === 2) {
        this.main.text = 'notifications';
        this.apiService.getNotices(this.page).subscribe(
          (data: NewsOrNotice[]) => {
            this.objects = data;
            this.loading = false;
          },
          (err) => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          },
        );
      } else if (this.type === 3) {
        this.main.text = 'suppliers';
        const filter = {active : 1};
        this.apiService.filterSuppliers(filter).subscribe(
          (suppliers: Supplier[]) => {
            this.suppliers = suppliers;
            this.loading = false;
          },
          (err) => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);

          },
        );
      }
    });
  }

  public onNavClick(address: string, id, type) {
    if (address === 'main/list') {
      this.page = 0;
      this.type = +type;
      this.loading = true;
      this.objects = [];
      this.suppliers = [];

    }
    if (id != -1) {
      this.router.navigate([address, id, type]);
    } else {
      this.router.navigate([address, type]);
    }

  }

  public onScroll() {
    this.page++;
    if (this.type === 1) {
      this.main.text = 'news';
      this.apiService.getNews(this.page).subscribe(
        (data: NewsOrNotice[]) => {
          this.objects = [...this.objects, ...data];
          this.loading = false;
        },
        (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        },
      );
    } else if (this.type === 2) {
      this.main.text = 'notifications';
      this.apiService.getNotices(this.page).subscribe(
        (data: NewsOrNotice[]) => {
          this.objects = [...this.objects, ...data];
          this.loading = false;
        },
        (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        },
      );
    } else if (this.type === 3) {
      this.main.text = 'suppliers';
      const filter = {active : 1};
      this.apiService.filterSuppliers(filter).subscribe(
        (suppliers: Supplier[]) => {
          this.suppliers = [...this.suppliers, ...suppliers];
          this.loading = false;
        },
        (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);

        },
      );
    }
  }

  public addNew() {
    localStorage.setItem('newsType', String(this.type));
    this.router.navigate(['main/specifics/edit']);
  }

}
