import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {DateAdapter, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ChartOptions} from 'chart.js';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {AllTimeCustomer, AllTimeProducts, NewsOrNotice, Product, Supplier} from '../model';
import {Color} from 'ng2-charts';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  public allTimeCustomers: AllTimeCustomer[] = [];
  public allTimeProducts: AllTimeProducts[] = [];
  public lineChartColors: Color[] = [
    {
      borderColor: 'rgba(46, 90, 128, 0.6)',
      backgroundColor: 'rgba(35, 81, 120, 0.6)',
    },
  ];
  public ordersLabel: string[] = [];
  public suppliersLabel: string[] = [];
  public suppliersData = [{data: [], label: ''}];
  public ordersData = [{data: [], label: ''}];
  public earningsData = [{data: [], label: ''}];
  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  public searchText = '';
  public countRead = 0;
  public totalEarning = 0;
  public timer;
  public showPlaceholder = true;
  public actionsPage = 0;
  public suppliersPage = 0;
  public newsPage = 0;
  public notificationsPage = 0;
  public actions: Product[];
  public suppliers: Supplier[];
  public news: NewsOrNotice[];
  public notices: NewsOrNotice[];
  public supplier: Supplier;
  public end = new Date();
  public loading = false;
  public start = new Date(this.end.getTime() - (30 * 24 * 60 * 60 * 1000));
  // @ts-ignore
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    legend: {
      display: false
    },
  };

  constructor(private location: Location, private apiService: ApiServiceService,
              private snackBar: MatSnackBar, private translate: TranslateService,
              private router: Router, public main: MainComponent, private dateAdapter: DateAdapter<Date>) {
    translate.get('table_total', {value: ''})
      .subscribe((res: string) => this.my_messages.totalMessage = res);
    translate.get('table_empty', {value: ''})
      .subscribe((res: string) => this.my_messages.emptyMessage = res);
  }

  public ngOnInit() {
    this.apiService.getCountOrders().subscribe(
      (response: number) => {
       this.countRead = response;

      });
    this.dateAdapter.setLocale('sr');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.supplier = JSON.parse(localStorage.getItem('supplier'));
    this.getChart();
  }

  public getChart() {
    this.loading = true;
    this.apiService.getStatistics(this.start, this.end).subscribe(
      (data: { totalEarning: number, suppliers: AllTimeProducts[], dailyEarning: number[], allTimeProducts: AllTimeProducts[], allTimeCustomers: AllTimeCustomer[], ordersLabel: string[], ordersData: number[] }) => {
        console.log(data);
        this.ordersData[0].data = data.ordersData;
        this.ordersLabel = data.ordersLabel;
        this.allTimeCustomers = data.allTimeCustomers;
        this.allTimeProducts = data.allTimeProducts;
        this.earningsData[0].data = data.dailyEarning;
        this.totalEarning = data.totalEarning;
        if (this.supplier.role == 3) { // only if global admin
          data.suppliers.forEach((supplier) => {
            this.suppliersLabel.push(supplier.name);
            this.suppliersData[0].data.push(supplier.count);
          });
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.main.autoLogin(err.status);
      },
    );
  }

  public openProductDetails(id) {
    this.router.navigate(['main/product', id]);
  }

  public searchProducts() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      localStorage.setItem('searchText', this.searchText);
      this.router.navigate(['main/products']);
    }, 500);
  }

  public openActions() {

    const filter = {suppliers: [0], action: 1};
    localStorage.setItem('actionFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'actions');
    this.router.navigate(['main/actions']);

  }

  public openFavorites() {
    this.main.text = 'favorites';
    this.router.navigate(['main/favorite']);

  }

  public resetAndOpenProducts() {
    const filter = {suppliers: [0]};
    localStorage.setItem('productFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'products');
    this.router.navigate(['main/products']);
  }

  public onNavClick(address: string, id, type) {
    if (id == -1 && type == -1) {
      this.router.navigate([address]);
    } else if (id == -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  public onFocus() {
    this.showPlaceholder = false;
  }

  public onBlur() {
    if (this.searchText === '') {
      this.showPlaceholder = true;
    }
  }

  public logOut() {
    localStorage.removeItem('supplier');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText');
    this.router.navigate(['login']);
  }

}
