

<div class="container">
  <div class="row">
    <div class="col-lg-2 col-md-12"></div>
    <div class="col-lg-8 col-md-12 pad">
      <div class="text-right">
        <button type="button" class="btn btn-outline-dark" (click)="openNewAdmin()"> &nbsp;&nbsp; Dodaj admina + &nbsp;&nbsp;</button>
      </div>
      <table class="table">
        <thead class="heade_rcolor text-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">EMAIL</th>
          <th scope="col">EDIT</th>
          <th scope="col">DELETE</th>
        </tr>
        </thead>
        <tbody *ngFor="let admin of admins">

        <tr>

          <td>{{admin.id}}</td>
          <td>{{admin.email}}</td>
          <td><button type="button" class="btn btn-outline-dark" (click)="openAdminUpdate(admin)"><mat-icon>edit
          </mat-icon></button></td>
          <td><button type="button" class="btn btn-outline-dark" (click)="deleteAdmin(admin)"><mat-icon>delete
          </mat-icon></button>
          </td>
        </tr>

        </tbody>
      </table>


    </div>
    <div class="col-lg-2 col-md-12"></div>
  </div>
</div>

