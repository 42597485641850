<div class="main-container" (scroll)="scrollWindow($event)" (window:resize)="onResized()">
  <div class="section-header" [ngClass]="{'smaller-header' : !showHeader}">
    <div class="section-hide-show" (click)="showHeader = !showHeader">
      <span [ngClass]="{'up-arrow' : !showHeader, 'down-arrow' : showHeader}"><mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div class="small-section-title" [ngClass]="{'hidden-small-title' : showHeader}">
      <img src="assets/products.png" class="section-icon-small">  {{'productEdits' | translate}} ({{total}})
    </div>
    <div class="section-header-inner" [ngClass]="{'hide-inner' : !showHeader}">
      <div class="section-title">
        <div class="inner-section-title">
          <img src="assets/products.png" class="section-icon">  {{'productEdits' | translate}} ({{total}})
        </div>
      </div>
<!--      <div class="section-track">-->
<!--        <div class="section-item" [matMenuTriggerFor]="searchPer">-->
<!--          <span *ngIf="showText">{{'searchPer' | translate}} </span>-->
<!--          <span *ngIf="!showText"><mat-icon>settings</mat-icon></span>-->
<!--        </div>-->
<!--        <div class="section-item" [matMenuTriggerFor]="date">-->
<!--          <span *ngIf="showText">{{'date' | translate}} </span>-->
<!--          <span *ngIf="!showText"><mat-icon>date_range</mat-icon></span>-->
<!--        </div>-->
<!--        <div class="section-item" [matMenuTriggerFor]="deliveries">-->
<!--          <span *ngIf="showText">{{'deliveries' | translate}} </span>-->
<!--          <span *ngIf="!showText"><mat-icon>euro_symbol</mat-icon></span>-->
<!--        </div>-->
<!--        <div class="section-item" [matMenuTriggerFor]="active">-->
<!--          <span *ngIf="showText">{{'active' | translate}} </span>-->
<!--          <span *ngIf="!showText"><mat-icon>visibility</mat-icon></span>-->
<!--        </div>-->
<!--        <div class="search-item">-->
<!--          <div class="search-left" (click)="filterSupplers()">-->
<!--            <span *ngIf="showText">{{'search' | translate}} </span>-->
<!--            <span *ngIf="!showText"><mat-icon>search</mat-icon></span>-->
<!--          </div>-->
<!--          <div class="search-right" (click)="clearFilter()">-->
<!--            <mat-icon>clear</mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
<!--  <mat-menu #searchPer="matMenu" xPosition="after">-->
<!--    <div class="section-menu" (click)="$event.stopPropagation()">-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="number" [(ngModel)]="filter.id" placeholder="ID">-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="text" [(ngModel)]="filter.name" placeholder="{{'fullName' | translate}}">-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="text" [(ngModel)]="filter.email" placeholder="{{'emal' | translate}}">-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="text" [(ngModel)]="filter.identifier" placeholder="{{'identifierLegal' | translate}}">-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="text" [(ngModel)]="filter.PIB" placeholder="{{'pib' | translate}}">-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="text" [(ngModel)]="filter.phone" placeholder="{{'phone' | translate}}">-->
<!--      </mat-form-field>-->
<!--      <mat-select [(ngModel)]="filter.city" placeholder="{{'city' | translate}}">-->
<!--        <mat-option [value]="-1">{{'all' | translate}}</mat-option>-->
<!--        <mat-option *ngFor="let c of cities" [value]="c.id">{{c.name}}</mat-option>-->
<!--      </mat-select>-->

<!--    </div>-->
<!--  </mat-menu>-->
<!--  <mat-menu #date="matMenu" xPosition="after">-->
<!--    <div class="section-menu" (click)="$event.stopPropagation()">-->
<!--      <mat-form-field class="section-form">-->
<!--        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'dateFrom' | translate}}"-->
<!--               (dateChange)="filter.dateFrom = $event.value.toISOString()">-->
<!--        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #pickerFrom></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input #fromInput matInput [matDatepicker]="dateTo" placeholder="{{'dateTo' | translate}}"-->
<!--               (dateChange)="filter.dateTo = $event.value.toISOString()">-->
<!--        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #dateTo></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--    </div>-->
<!--  </mat-menu>-->
<!--  <mat-menu #deliveries="matMenu" xPosition="after">-->
<!--    <div class="section-menu" (click)="$event.stopPropagation()">-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="number" [(ngModel)]="filter.minDeliveryMin"-->
<!--               placeholder="{{'minDeliveryPriceFrom' | translate}}">-->
<!--        <span matSuffix>RSD</span>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="number" [(ngModel)]="filter.minDeliveryMax"-->
<!--               placeholder="{{'minDeliveryPriceTo' | translate}}">-->
<!--        <span matSuffix>RSD</span>-->
<!--      </mat-form-field>-->
<!--      <hr>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="number" [(ngModel)]="filter.deliveryPriceMin"-->
<!--               placeholder="{{'deliveryPriceFrom' | translate}}">-->
<!--        <span matSuffix>RSD</span>-->
<!--      </mat-form-field>-->
<!--      <mat-form-field class="section-form">-->
<!--        <input matInput type="number" [(ngModel)]="filter.deliveryPriceMax"-->
<!--               placeholder="{{'deliveryPriceTo' | translate}}">-->
<!--        <span matSuffix>RSD</span>-->
<!--      </mat-form-field>-->
<!--    </div>-->
<!--  </mat-menu>-->
<!--  <mat-menu #active="matMenu" xPosition="after">-->
<!--    <div class="section-menu" (click)="$event.stopPropagation()">-->
<!--      <mat-select [(ngModel)]="filter.active" placeholder="{{'active' | translate}}">-->
<!--        <mat-option [value]="-1">{{'all' | translate}}</mat-option>-->
<!--        <mat-option [value]="0">{{'unactive' | translate}}</mat-option>-->
<!--        <mat-option [value]="1">{{'active' | translate}}</mat-option>-->

<!--      </mat-select>-->
<!--    </div>-->
<!--  </mat-menu>-->
  <div class="main-body" [ngClass]="{'main-body-higher' : !showHeader}">
<!--    <div class="chips-list">-->
<!--      <mat-chip-list>-->
<!--        <mat-chip *ngIf="filter.id != ''">ID: {{filter.id}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.id = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.name != ''">{{'fullName' | translate}}: {{filter.name}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.name = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.email != ''">{{'email' | translate}}: {{filter.email}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.email = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.identifier != ''">{{'identifierLegal' | translate}}: {{filter.identifier}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.identifier = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.PIB != ''">{{'pib' | translate}}: {{filter.PIB}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.PIB = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.phone != ''">{{'phone' | translate}}: {{filter.phone}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.phone = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.city != ''">{{'city' | translate}}: {{getCity(filter.city)}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.city = ''">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.dateFrom != -1">{{'dateFrom' | translate}}: {{filter.dateFrom | date : 'shortDate'}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.dateFrom = -1">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.dateTo != -1">{{'dateTo' | translate}}: {{filter.dateTo | date : 'shortDate'}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.dateTo = -1">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.minDeliveryMin != 0">{{'minDeliveryPriceFrom' | translate}}: {{filter.minDeliveryMin}}-->
<!--          RSD-->
<!--          <mat-icon class="chips-icon" (click)="filter.minDeliveryMin = 0">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.minDeliveryMax != 0">{{'minDeliveryPriceTo' | translate}}: {{filter.minDeliveryMax}} RSD-->
<!--          <mat-icon class="chips-icon" (click)="filter.minDeliveryMax = 0">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.deliveryPriceMin != 0">{{'deliveryPriceFrom' | translate}}: {{filter.deliveryPriceMin}}-->
<!--          RSD-->
<!--          <mat-icon class="chips-icon" (click)="filter.deliveryPriceMin = 0">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.deliveryPriceMax != 0">{{'deliveryPriceTo' | translate}}: {{filter.deliveryPriceMax}}-->
<!--          RSD-->
<!--          <mat-icon class="chips-icon" (click)="filter.deliveryPriceMax = 0">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--        <mat-chip *ngIf="filter.active != -1">{{'active' | translate}}: {{getActive(filter.active) | translate}}-->
<!--          <mat-icon class="chips-icon" (click)="filter.active = -1">cancel</mat-icon>-->
<!--        </mat-chip>-->
<!--      </mat-chip-list>-->
<!--    </div>-->
    <div class="action-list" [ngClass]="{'short-action-list' : selected.length == 0}">
      <div class="action-list-inner" [ngClass]="{'invisible-action' : selected.length == 0}">
        <div class="button-action delete" (click)="buttonAction(1)">{{'applyChanges' | translate}} ({{selected.length}})</div>
        <div class="button-action delete" (click)="buttonAction(2)">{{'cancelChanges' | translate}} ({{selected.length}})</div>

      </div>
    </div>
    <div class="table-container">
      <ngx-datatable
        class="material table-list"
        infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]=true
        [infiniteScrollThrottle]="150"
        (scrolled)="onScroll()"
        [rows]="edits"
        [rowClass]="getRowClass"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [selected]="selected"
        [loadingIndicator]="loading"
        (activate)='onActivate($event)'
        [selectionType]="'checkbox'"
        [rowHeight]="50"
        (select)="onSelect($event)">
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true">
        </ngx-datatable-column>
        <ngx-datatable-column name="Id" sortable="false" prop="id" [width]="10">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'image' | translate}}" sortable="false" prop="image" [width]="logoWidth">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <img [src]="value" class="table-logo">
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'productName' | translate}}" sortable="false" prop="name">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'supplier' | translate}}" sortable="false" prop="supplierName">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'nameChanged' | translate}}" sortable="false" prop="isNameEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'priceChanged' | translate}}" sortable="false" prop="isPriceEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'manufacturerChanged' | translate}}" sortable="false" prop="isManufacturerEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'volumeChanged' | translate}}" sortable="false" prop="isUnitEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'descriptionChanged' | translate}}" sortable="false" prop="isDescriptionEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'imageChanged' | translate}}" sortable="false" prop="isImageEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'packetNumberChanged' | translate}}" sortable="false" prop="isPacketEdited">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value"><mat-icon>check</mat-icon></span>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'date' | translate}}" sortable="false" prop="createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date : 'dd/MM/yy' : 'Europe/Belgrade'}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
    <div class="big-footer">
      <app-footer-big></app-footer-big>
    </div>
</div>
