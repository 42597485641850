<div class="full">
  <div class="upperHeader">
    <div class="contactBig" (click)="openContact()">
      {{'contact' | translate }}
    </div>
  </div>
  <div class="mainDiv" (window:resize)="onResize()" [ngClass]="{'longerDiv' : keyboardOpen}">
  </div>
</div>

<div class="contanersLeft">
  <img class="logoBig" src="/assets/logo.png">
  <div class="logoText">
    <img class="logoTextImg" src="/assets/enabavka.png">
  </div>

</div>

<div class="contaners">
  <div class="divForPhone" (click)="openContact()">
    <img src="/assets/phone.png" class="phone">
    <div class="contact">{{'contact' | translate | uppercase}}</div>
  </div>
  <div class="divForLogo">
    <img class="logo" src="/assets/logo.png">
  </div>
  <div class="form">
    <div class="inputForm">
      <mat-label class="label">{{'email' | translate | uppercase}}</mat-label>
      <input type="text" #userInput  required class="userInput" [(ngModel)]="supplier.email" (keyup.enter)="passwordInput.focus()">
    </div>
    <div class="inputForm">
      <mat-label class="label">{{'password' | translate | uppercase}}</mat-label>
      <input type="password"  #passwordInput (keyup.enter)="login()" required class="userInput" [(ngModel)]="supplier.password">
    </div>
  </div>

  <div class="rememberMe">
    <span class="rememberMeText"> {{'rememberMe' | translate}} </span>
    <div class="round">
      <input type="checkbox" id="checkbox"  [(ngModel)]="supplier.rememberMe"/>
      <label for="checkbox"></label>
    </div>
  </div>

  <div class="logInBig">
    <div class="footerText" (click)="login()"> {{'login' | translate | uppercase}}</div>
  </div>

  <div class="divForButtons">
    <div class="forgotPass button" (click)="onNavClick('password')">
      <div class="buttonText"> {{'iForgotMyPass' | translate | uppercase}} </div>
    </div>
    <div class="newAccount button">
      <div class="buttonText" (click)="onNavClick('register')"> {{'createNewProfile' | translate | uppercase}} </div>
    </div>
  </div>

  <div class="footer">
    <div class="footerText" (click)="login()" > {{'login' | translate | uppercase}}</div>
  </div>
</div>






