import {Component, HostListener, OnInit} from '@angular/core';
import {DateAdapter, MatDialog} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {DialogComponent} from '../dialog/dialog.component';
import {EditProductComponent} from '../edit-product/edit-product.component';
import {MainComponent} from '../main/main.component';
import {Cart, City, Customer, CustomerLite, Supplier} from '../model';
import {OrderComponent} from '../order/order.component';
import {CommunicationService} from "../communication.service";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {

  public loading = false;
  public sort = 'created_at';
  public dir = 'desc';
  public page = 0;
  public orders: Cart[];
  public customers: CustomerLite[];
  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };
  public total = 0;
  public selected = [];
  public showText = true;
  public showHeader = true;
  public logoWidth = 30;
  public supplier: Supplier;
  public suppliers: Supplier[];
  public sum = 0;
  public filter = {
    id: '', supplier: 0, customer: [ 0 ], dateFrom: -1, dateTo: -1, priceMin: 0, priceMax: 0, deliveryPriceMin: 0, deliveryPriceMax: 0,
  };

  constructor(private communicator: CommunicationService, private router: Router, private translate: TranslateService, private dialog: MatDialog, private apiService: ApiServiceService, private main: MainComponent, private dateAdapter: DateAdapter<Date>) {
    translate.get('table_total', {value: ''})
      .subscribe((res: string) => this.my_messages.totalMessage = res);
    translate.get('table_empty', {value: ''})
      .subscribe((res: string) => this.my_messages.emptyMessage = res);
  }

  public ngOnInit() {
    this.dateAdapter.setLocale('sr');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 30;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }

    this.apiService.getAllSuppliers().subscribe(
      (data: Supplier[]) => {
        this.suppliers = data;
      }
    );

    this.getAllCustomers();
    this.filterOrders();

    this.communicator.updatedOrder.subscribe(
      (order: Cart) => {
        const index = this.orders.findIndex((x) => x.id == order.id);
        if (index != -1) {
          this.orders[index] = order;
          this.orders = [...this.orders];
        }
      }
    )
  }

  public findSupplier(id) {
    const index = this.suppliers.findIndex((x) => x.id == id);

    if (index != -1) {
      return this.suppliers[index].name;
    }
  }

  @HostListener('window:scroll', ['$event'])
  public scrollWindow(event) {
    if (window.pageYOffset > 100) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }

  public onResized() {
    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 30;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }
  }

  public onSort(event) {
    this.dir = event.sorts[0].dir;
    this.sort = event.sorts[0].prop;

    if (this.sort === 'createdAt') {
      this.sort = 'created_at';
    } else if (this.sort === 'deliveryPrice') {
      this.sort = 'delivery_price';
    } else if (this.sort === 'minDelivery') {
      this.sort = 'min_delivery';
    } else if (this.sort === 'numberOfOrders') {
      this.sort = 'number_of_orders';
    } else if (this.sort === 'isActive') {
      this.sort = 'is_active';
    } else if (this.sort === 'legalName') {
      this.sort = 'legal_name';
    } else if (this.sort === 'puchasePhone') {
      this.sort = 'purchase_phone';
    }

    this.filterOrders();
  }

  public onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  public checkCustomers() {
    if (this.filter.customer.length > 1 && this.filter.customer.includes(0)) {
      const indexZero = this.filter.customer.findIndex((x) => x == 0);

      if (indexZero != -1) {
        this.filter.customer.splice(indexZero, 1);
        this.filter.customer = [...this.filter.customer];
      }
    }
  }
  public getRowClassRead(row): any {
    if (row.isRead) {
      return 'bg-light';
    } else {
      return 'bg-danger';
  }
  }
  public resetCustomers() {
    this.filter.customer = [0];
    this.filter.customer = [...this.filter.customer];
  }

  public onScroll() {
    this.page++;
    this.apiService.filterOrders(this.filter, this.sort, this.dir, this.page).subscribe(
      (data: { orders: Cart[], total: number }) => {
        this.orders = [...this.orders, ...data.orders];
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public getSelectedCount(type: number) {
    let count = 0;
    if (type === 1) {
      this.selected.forEach((x) => {
        if (x.isActive === type) {
          count++;
        }
      });
    } else if (type === 0) {
      this.selected.forEach((x) => {
        if (!x.isActive) {
          count++;
        }
      });
    }

    return count;
  }

  public onActivate(event) {
    if (event.type == 'click') {
      if (event.cellIndex != 0) {
      console.log(event);
      const dialogRef = this.dialog.open(OrderComponent, {
        data: {order: event.row, panelClass: 'warningDialog'}});

      dialogRef.afterClosed().subscribe((result: Cart) => {
        if (result) {
        }
    });
  }
    }
  }

    public getAllCustomers() {
    this.apiService.getAllCustomers().subscribe(
      (data: CustomerLite[]) => {
        this.customers = data;
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public clearFilter() {
    this.filter = {
      id: '', supplier: 0, customer: [ 0 ], dateFrom: -1, dateTo: -1, priceMin: 0, priceMax: 0, deliveryPriceMin: 0, deliveryPriceMax: 0,
    };
    this.customers.forEach((customer) => {
      this.filter.customer.push(customer.id);
    });
  }

  public getActive(id) {
    if (id == 1) {
      return 'active';
    } else if (id == 0) {
      return 'unactive';
    } else if (id == -1) {
      return 'all';
    }
  }

  public getRowClass(row) {
    if (row.isActive == 1) {
      return 'active-row';
    } else if (!row.isActive) {
      return 'unactive-row';
    }
  }

  public filterOrders() {
    this.loading = true;
    this.apiService.filterOrders(this.filter, this.sort, this.dir, this.page).subscribe(
      (data: { orders: Cart[], total: number, sum: number }) => {
        this.orders = data.orders;
        this.total = data.total;
        this.sum = data.sum;
        this.loading = false;
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public findCustomerName(id) {
    const index = this.customers.findIndex((x) => x.id == id);

    if (index != -1) {
      return this.customers[index].legal_name;
    }
  }

  public resetFilterCustomer() {
    this.filter.customer = [ 0 ];
  }
  public changeMultipleRead(type){
    let text = '';

    text = this.translate.instant('Pročitane porudžbine') + ' (' + this.selected.length + ')';

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'actionButton', text}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const ids = [];
        this.selected.forEach((x) => {
          ids.push(x);
        });

        this.apiService.updateMultipleRead(ids).subscribe(
          (data: any) => {

            ids.forEach((id) => {
              const index = this.orders.findIndex((x) => x.id == id.id);
              if (index != -1) {
                this.orders[index].isRead = true;
              }
            });


            this.orders = [...this.orders];
            console.log(this.orders);


          },
          (err) => {
            this.main.autoLogin(err.status);
          },
        );
      }
    });
  }

}
