<div class="mainContainer"(window:resize)="onResize($event)">
  <mat-form-field class="suppliers selectField" *ngIf="supplier.role == 3">
    <mat-label>{{'suppliers' | translate}}</mat-label>
    <div class="forSelect">
      <mat-select multiple [(ngModel)]="productFilter.suppliers" (selectionChange)="checkSuppliers($event)">
        <mat-option [value]="0" (click)="resetSuppliers()">{{'allSuppliers' | translate}}</mat-option>
        <mat-option *ngFor="let s of suppliers" [value]="s.id">{{s.name}}</mat-option>
      </mat-select>
    </div>
  </mat-form-field>

  <mat-button-toggle class="actionButton" [ngClass]="{'actionButtonChecked' : productFilter.active == 1}"  (click)="toggleActive()">{{'onlyActive' | translate}}</mat-button-toggle>
  <mat-button-toggle class="actionButton" [ngClass]="{'actionButtonChecked' : productFilter.stock == 1}"  (click)="toggleStock()">{{'onlyStock' | translate}}</mat-button-toggle>
  <mat-button-toggle class="actionButton" [ngClass]="{'actionButtonChecked' : productFilter.action == 1}"  (click)="toggleAction()">{{'productsWithActions' | translate}}</mat-button-toggle>

  <div class="footerButtons">
    <mat-button-toggle class="resetButton" (click)="reset()">{{'reset' | translate | uppercase}}</mat-button-toggle>
    <mat-button-toggle class="applyButton" (click)="apply()">{{'apply' | translate | uppercase}}</mat-button-toggle>
  </div>
</div>
