<div class="block-list">
  <h3>{{'blocks' | translate}}</h3>
  <div *ngIf="this.data.list.length == 0" class="no-blocks">
    {{'noBlocks' | translate}}
  </div>
  <table>
    <div *ngFor="let block of this.data.list">
      <tr>
        <td class="block-title"><span class="block-title">{{block.supplierName}}</span></td>
        <td class="block-right">
          <div class="block-button"
            (click)="toggleBlock(block.customer_id, block.supplier_id, block.id)">
            <span>{{'unblock' | translate}}</span>
          </div>
        </td>
      </tr>

    </div>
  </table>
  <button mat-flat-button class="toggle-block"  (click)="toAdd = !toAdd" >
    <span>{{'addNew' | translate}}</span>
  </button>
  <div [ngClass]="{'smallAdd' : !toAdd, 'bigAdd' : toAdd}">
    <mat-select placeholder="{{'supplier' | translate}}"  [(ngModel)]="selectedSupplier" [ngClass]="{'invisible' : !toAdd, 'visible' : toAdd}">
      <mat-option *ngFor="let s of suppliers" [value]="s.id">{{s.name}}</mat-option>
    </mat-select>
    <button mat-flat-button class="toggle-block-real" [disabled]="selectedSupplier == 0" (click)="addNew()"  [ngClass]="{'invisible' : !toAdd, 'visible' : toAdd}">
      <span>{{'confirm' | translate}}</span>
    </button>
  </div>
</div>
