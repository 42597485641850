import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {City, Country, Customer, Supplier} from '../model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  public cities: City[];
  public countries: Country[];
  public startDelivery = '09:00';
  public endDelivery = '22:00';
  public startOrder = '09:00';
  public endOrder = '22:00';
  public verification = false;

  constructor(private router: Router, private apiService: ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) { }

  public supplier: Supplier = {email: '', description: '', password: '', id: 0, minDelivery: 0, deliveryPrice: 0, name: '', logo: '', city: '', address: '', identifier: '', PIB: '', postalCode: '', link: ''};

  public onNavClick(address: string) {
    this.router.navigate([address]);
  }

  public ngOnInit() {
    if (localStorage.getItem('supplier') != null) {
      this.router.navigate(['main/start']);
    }

    if (localStorage.getItem('verification') && localStorage.getItem('verification') === '1') {
      this.verification = true;
      localStorage.removeItem('verification');
    }

    this.apiService.getLocale().subscribe(
      (data: {locale}) => {
        this.supplier.locale = data.locale;
      },
      (err) => {
        this.supplier.locale = 'sr';
      },
    );
    this.apiService.getCitiesAndCountries().subscribe(
      (data: {cities: City[], countries: Country[]}) => {
        this.cities = data.cities;
        this.countries = data.countries;

        // assign first values to supplier
        this.supplier.city = this.cities[0].id.toString();
        this.supplier.country = this.countries[0].code;
      },
    );
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadImage(formData).subscribe(
      (data: {path, fullUrl}) => {
        this.supplier.logo = data.fullUrl;
      });
  }

  public emailIsValid() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.supplier.email);
  }

  public register() {

    this.supplier.deliveryTime = this.startDelivery + ' - ' + this.endDelivery;
    this.supplier.orderTime = this.startOrder + ' - ' + this.endOrder;

    if (!this.emailIsValid()) {
      this.snackBar.open(this.translate.instant('emailNotValid'), 'OK', {duration: 3000});
      return;
    } else if (this.supplier.email === '' || this.supplier.address === '' || this.supplier.password === '' || this.supplier.phone === '' || this.supplier.name === ''
      || this.supplier.logo === '' || this.supplier.link === '' || this.supplier.city === '' || this.supplier.identifier === '' || this.supplier.PIB === '' ||
      this.supplier.description === '' || this.supplier.centralPhone === '' || this.supplier.orderTime === '' || this.supplier.deliveryTime === '' || this.supplier.postalCode === ''
    ) {
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFieldsUP'), 'OK', {duration : 3000});
      return;
    }

    if (this.supplier.password !== this.supplier.confirmPassword) {
      this.snackBar.open(this.translate.instant('passwordsAreNotSame'), 'OK', {duration : 3000});
      return;
    }

    if (this.supplier.termsOfUse !== true) {
      this.snackBar.open(this.translate.instant('pleaseAcceptTermsOfUse'), 'OK', {duration : 3000});
      return;
    }

    this.apiService.register(this.supplier).subscribe(
      (result: {user, jwt}) => {
        this.supplier = result.user;
        if (this.supplier.isActive != 1) {
          this.verification = true;
        } else {
          localStorage.setItem('JWT', result.jwt);
          localStorage.setItem('supplier', JSON.stringify(this.supplier));
          if (localStorage.getItem('url')) {
            this.router.navigate([localStorage.getItem('url')]);
            localStorage.removeItem('url');
          } else {
            this.router.navigate(['main/start']);
          }
        }

      },
      (err) => {
        this.snackBar.open(err.error.message, 'OK', {duration : 3000});
      },
    );
  }

}
