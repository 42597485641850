<div class="mainContainer"(window:resize)="onResize($event)">
  <div class="containingDiv">
    <div class="filterLeft">
      <div class="productsTitle">{{this.title | translate}}</div>
      <div class="filterTitle">{{'filter' | translate | uppercase}}</div>
      <div class="manufacturers">
        <div class="manufacturerTitle">
          {{'active' | translate}}
        </div>
        <div class="checkboxes">
          <div class="checkboxItem">
            <label class="container"> <div class="label"> {{'active' | translate}} </div>
              <input type="checkbox" (change)="toggleCheck(1, 'a')" [checked]="productFilter.active">
              <span class="checkmark" ></span>
            </label>
            <label class="container"> <div class="label"> {{'unactive' | translate}} </div>
              <input type="checkbox" (change)="toggleCheck(0, 'a')"   [checked]="productFilter.unactive">
              <span class="checkmark" ></span>
            </label>
          </div>
        </div>
      </div>
      <div class="manufacturers">
        <div class="manufacturerTitle">
          {{'stock' | translate}}
        </div>
        <div class="checkboxes">
          <div class="checkboxItem">
            <label class="container"> <div class="label"> {{'inStock' | translate}} </div>
              <input type="checkbox"  (click)="toggleCheck(1, 's')"  [checked]="productFilter.stock">
              <span class="checkmark" ></span>
            </label>
            <label class="container"> <div class="label"> {{'outStock' | translate}} </div>
              <input type="checkbox"  (click)="toggleCheck(0, 's')"  [checked]="productFilter.nonStock">
              <span class="checkmark" ></span>
            </label>
          </div>
        </div>
      </div>
      <div class="manufacturers">
        <div class="manufacturerTitle">
          {{'actions' | translate}}
        </div>
        <div class="checkboxes">
          <label class="container"> <div class="label"> {{'inAction' | translate}} </div>
            <input type="checkbox"  (click)="toggleCheck(1, 'act')"  [checked]="productFilter.actionOnly">
            <span class="checkmark" ></span>
          </label>
          <label class="container"> <div class="label"> {{'notAction' | translate}} </div>
            <input type="checkbox"  (click)="toggleCheck(0, 'act')"  [checked]="productFilter.actionNo">
            <span class="checkmark" ></span>
          </label>
        </div>
      </div>
      <div class="manufacturers" *ngIf="supplier.role == 3">
        <div class="manufacturerTitle">
          {{'supplier' | translate}}
        </div>
        <div class="checkboxes">
          <div class="checkboxItem">
            <label class="container"> <div class="label"> {{'all' | translate}} </div>
              <input type="checkbox"  (click)="toggleCheck(0, 'supplier')"  [checked]="checkIfSelected(0, 'supplier')">
              <span class="checkmark" ></span>
            </label>
          </div>
          <div class="checkboxItem" *ngFor="let s of suppliers">
            <label class="container"> <div class="label"> {{s.name}} </div>
              <input type="checkbox" (click)="toggleCheck(s.id, 'supplier')" [checked]="checkIfSelected(s.id, 'supplier')">
              <span class="checkmark" ></span>
            </label>
          </div>
        </div>
        <div class="showAll" *ngIf="!allSuppliers" (click)="showAll('s')">{{'showAll' | translate}}</div>
      </div>
      <div class="resetFilter" (click)="resetFilter()">{{'resetFilter' | translate}}</div>
    </div>
    <div class="productsList"  infiniteScroll [infiniteScrollDistance]="5" [scrollWindow] = true
         [infiniteScrollThrottle]="10"
         (scrolled)="onScroll()">
      <div class="search">
        <mat-form-field class="searchProducts">
          <p (click)="searchInput.focus()" *ngIf="showPlaceholder"
             class="searchPlaceholder">{{'searchProducts' | translate}}...</p>
          <input matInput id="searchInput" #searchInput (blur)="onBlur()" (keyup)="searchProducts()" (focus)="onFocus()" [(ngModel)]="start.searchText" class="searchField"
                 type="text">
          <img class="searchIcon" [src]="srcIcon" (click)="ifMobileFilter(); main.text = 'filter'">
          <div class="prediction" *ngIf="predictions.length > 0"  clickOutsideEnabled="true" (clickOutside)="closePrediction()">
            <div class="specificPrediction" *ngFor="let p of predictions"  (click)="chosePrediction(p)">
              {{p}}
            </div>
          </div>
        </mat-form-field>
        <div class="add-new-product" (click)="newProduct()" *ngIf="title != 'actions'">
          {{'addNewProduct' | translate}}
        </div>
      </div>
      <div class="innerProducts"  *ngIf="!loading" >
        <div class="noProducts" *ngIf="products.length == 0">{{'noProducts' | translate}}</div>
        <div class="product" (mouseenter)="addToHovered(p.id)" (mouseleave)="removeHovered(p.id)" *ngFor="let p of products" [ngClass]="{'longProduct' : p.expanded}" >
          <img src="assets/added.png" title="{{'activate' | translate}}" class="toActivate" (click)="activate(p.id)" *ngIf="supplier.role == 3 && p.active == 0 && checkIfHovered(p.id)">
          <img src="assets/trashbin.png" title="{{'delete' | translate}}" class="toRemove" (click)="delete(p.id)" *ngIf="checkIfHovered(p.id)"  [ngClass]="{'rightDelete' : supplier.role != 3 || p.active == 1}">
          <div class="productBody" [ngClass]="{'unactive' : p.active === 0}">
            <img [src]="p.image" class="productImage" (click)="openProductDetails(p.id)" (error)="p.image = 'assets/defaultImage.png'">
            <div class="mainProductText">
              <span class="productName" (click)="openProductDetails(p.id)">{{p.name}}</span> <br>
              <span class="mainProductInfo">
          <span class="productInfo">{{'manufacturer' | translate}}: {{p.manufacturerName}}</span>
          <span class="productInfo">{{'supplier' | translate}}: {{p.supplierName}}</span>
          <span class="productInfo">{{'volume' | translate}}: {{p.measurementUnit}}</span>
        </span>
              <span class="productPrice" *ngIf="p.price == p.fullPrice">{{p.fullPrice}} {{p.currency}}</span>
              <span class="productPrice" *ngIf="p.price != p.fullPrice">
            <span class="fullPrice">{{p.fullPrice}} {{p.currency}} </span>
             <span class="realPrice">{{p.price}} {{p.currency}} </span>
          </span>

            </div>
            <img src="/assets/downArrow.png"  [ngClass]="{'downArrow' : !p.expanded, 'upArrow' : p.expanded}"  (click)="expandProduct(p.id)">
            <div class="expandedBodyBig" >
              <div class="leftToggle">
                <div class="sign editButton" title="{{'edit' | translate}}"  (click)="editProduct(p.id)">
                  <img class="editImage" src="/assets/edit_icon.png">
                </div>
              </div>
              <div class="sign editButtonGray"  (click)="changeAction('add', p.id)"  title="{{'addAction' | translate}}" *ngIf="p.action === 0">
                <img class="editImage" src="/assets/Akcije.png" >
              </div>
              <div class="sign editButton" (click)="changeAction('remove', p.id)"  title="{{'removeAction' | translate}}" *ngIf="p.action > 0">
                <img class="editImage" src="/assets/Akcije.png">
              </div>
              <div (click)="changeStock('remove', p.id)" class="sign editButton"  title="{{'outOfStock' | translate}}" *ngIf="p.inStock === 1">
                <img class="editImage" src="/assets/box.png" >
              </div>
              <div (click)="changeStock('add', p.id)" class="sign editButtonGray"  title="{{'hasInStock' | translate}}" *ngIf="p.inStock === 0">
                <img class="editImage" src="/assets/box.png" >
              </div>
            </div>
          </div>
          <div class="downBody" [ngClass]="{'invisibleExpand' : !p.expanded, 'visibleExpand' : p.expanded }">
            <hr class="productSeparator" *ngIf="p.expanded">
            <div class="expandedBody" *ngIf="p.expanded"  [ngClass]="{'unactive' : p.active === 0}">
              <div class="leftToggle">
                <div class="sign editButton" title="{{'edit' | translate}}" (click)="editProduct(p.id)">
                  <img class="editImage" src="/assets/edit_icon.png">
                </div>
              </div>
              <div (click)="changeAction('add', p.id)" class="sign editButtonGray" title="{{'addAction' | translate}}" *ngIf="p.action === 0">
                <img class="editImage" src="/assets/Akcije.png">
              </div>
              <div (click)="changeAction('remove', p.id)"  class="sign editButton" title="{{'removeAction' | translate}}" *ngIf="p.action > 0">
                <img class="editImage" src="/assets/Akcije.png">
              </div>
              <div (click)="changeStock('remove', p.id)" class="sign editButton"  title="{{'outOfStock' | translate}}" *ngIf="p.inStock === 1">
                <img class="editImage" src="/assets/box.png" >
              </div>
              <div (click)="changeStock('add', p.id)" class="sign editButtonGray"  title="{{'hasInStock' | translate}}" *ngIf="p.inStock === 0">
                <img class="editImage" src="/assets/box.png">
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
  <div class="footerBig" *ngIf="!loading">
    <app-footer-big></app-footer-big>
  </div>
</div>


<div class="loadingContainer"  [ngClass]="{'visibleLoading' : loading}">
  <div class="divForSpinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
