import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {City, Supplier} from '../model';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {DialogComponent} from '../dialog/dialog.component';
import {DateAdapter, MatDialog, MatDialogModule} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {CustomerComponent} from '../customer/customer.component';
import {SupplierComponent} from '../supplier/supplier.component';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuppliersComponent implements OnInit {

  public loading = false;
  public sort = 'created_at';
  public dir = 'desc';
  public page = 0;
  public suppliers: Supplier[];
  public cities: City[];
  public total = 0;
  public selected = [];
  public showText = true;
  public showHeader = true;
  public logoWidth = 30;
  public supplier: Supplier;
  public filter = {
    id: '', active: -1, name: '', identifier: '', email: '', PIB: '',
    phone: '', dateFrom: -1, dateTo: -1, city: '', minDeliveryMin: 0,
    minDeliveryMax: 0, deliveryPriceMin: 0, deliveryPriceMax: 0,
  };

  constructor(private router: Router, private translate: TranslateService, private dialog: MatDialog, private apiService: ApiServiceService, private main: MainComponent,  private dateAdapter: DateAdapter<Date>) {
  }

  public ngOnInit() {
    this.dateAdapter.setLocale('sr');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role !== 3) {
      this.router.navigate(['main/start']);
    }

    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 30;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }

    this.getCities();
    this.filterSupplers();
  }

  @HostListener('window:scroll', ['$event'])
  public scrollWindow(event) {
    if (window.pageYOffset > 100) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }

  public buttonAction(type) {
    let text = '';
    if (type === 1) { // activate
      text = this.translate.instant('areYouSureYouWantToActivateSuppliers') + ' (' + this.getSelectedCount(0) + ')';
    } else if (type === 2) { // deactivate
      text = this.translate.instant('areYouSureYouWantToDeactivateSuppliers') + ' (' + this.getSelectedCount(1) + ')';
    } else if (type === 3) { // delete
      text = this.translate.instant('areYouSureYouWantToDeleteSuppliers') + ' (' + this.selected.length + ')';
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'actionButton', text}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type == 1) {
          const ids = [];
          this.selected.forEach((x) => {
            if (!x.isActive) {
              ids.push(x.id);
            }
          });
          this.apiService.activateSuppliers(ids, 1).subscribe(
            (data) => {
              this.suppliers.forEach((supplier) => {
                ids.forEach((id) => {
                  if (supplier.id === id ) {
                    const index = this.suppliers.findIndex( (x) => x.id === supplier.id);
                    this.suppliers[index].isActive = 1;
                  }
                });
              });
              this.suppliers = [...this.suppliers];
              this.selected = [];
            },
            (err) => {
              this.main.autoLogin(err.status);
            },
          );
        } else if (type == 2) {
          const ids = [];
          this.selected.forEach((x) => {
            if (x.isActive == 1) {
              ids.push(x.id);
            }
          });

          this.apiService.activateSuppliers(ids, 0).subscribe(
            (data) => {
              this.suppliers.forEach((supplier) => {
                ids.forEach((id) => {
                  if (supplier.id == id ) {
                    const index = this.suppliers.findIndex( (x) => x.id === supplier.id);
                    this.suppliers[index].isActive = 0;
                  }
                });
              });
              this.suppliers = [...this.suppliers];
              this.selected = [];
            },
            (err) => {
              this.main.autoLogin(err.status);
            },
          );
        } else if (type === 3) {
          const ids = [];
          this.selected.forEach((x) => {
            ids.push(x.id);
          });

          this.apiService.deleteSuppliers(ids).subscribe(
            (data) => {
              this.suppliers.forEach((supplier) => {
                ids.forEach((id) => {
                  if (supplier.id == id ) {
                    const index = this.suppliers.findIndex( (x) => x.id === supplier.id);
                    this.suppliers.splice(index, 1);
                  }
                });
              });
              this.suppliers = [...this.suppliers];
              this.selected = [];
            },
            (err) => {
              this.main.autoLogin(err.status);
            },
          );
        }
      }
    });
  }

  public onResized() {
    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 30;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }
  }

  public onSort(event) {
    this.dir = event.sorts[0].dir;
    this.sort = event.sorts[0].prop;

    if (this.sort === 'createdAt') {
      this.sort = 'created_at';
    } else if (this.sort === 'deliveryPrice') {
      this.sort = 'delivery_price';
    } else if (this.sort === 'minDelivery') {
      this.sort = 'min_delivery';
    } else if (this.sort === 'numberOfOrders') {
      this.sort = 'number_of_orders';
    } else if (this.sort === 'isActive') {
      this.sort = 'is_active';
    }

    this.filterSupplers();
  }

  public onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  public onScroll() {
    this.page++;
    this.apiService.filterSuppliers(this.filter, this.sort, this.dir, this.page).subscribe(
      (data: { suppliers: Supplier[], total: number }) => {
        this.suppliers = [...this.suppliers, ...data.suppliers];
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public getSelectedCount(type: number) {
    let count = 0;
    if (type === 1) {
      this.selected.forEach((x) => {
        if (x.isActive === type) {
          count++;
        }
      });
    } else if (type === 0) {
      this.selected.forEach((x) => {
        if (!x.isActive) {
          count++;
        }
      });
    }

    return count;
  }

  public onActivate(event) {
    if (event.type == 'click') {
      if (event.cellIndex != 0) {
        const supplierIndex = this.suppliers.findIndex(x => x.id == event.row.id);
        const oldSupplier = this.suppliers[supplierIndex];
        localStorage.setItem('oldSupplier', JSON.stringify(oldSupplier));
        const dialogRef = this.dialog.open(SupplierComponent, {
          data: {supplier: event.row, panelClass: 'warningDialog'}, maxWidth: '90vw'});

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const index = this.suppliers.findIndex(x => x.id == result.id);
            if (index != -1) {
              this.suppliers[index] = result;
              this.suppliers = [...this.suppliers];
            }
          } else {
            const oldSupplierGet = JSON.parse(localStorage.getItem('oldSupplier'));
            this.suppliers[supplierIndex] = oldSupplierGet;
            localStorage.removeItem('oldSupplier');
            this.suppliers = [...this.suppliers];

          }
        });
      }
    }
  }

  public getCities() {
    this.apiService.getCitiesAndCountries().subscribe(
      (data: { cities }) => {
        this.cities = data.cities;
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public getCity(id) {
    const city = this.cities.find((x) => x.id == id);
    if (city) {
      return city.name;
    } else {
      return '';
    }
  }

  public clearFilter() {
    this.filter = {
      id: '', active: -1, name: '', identifier: '', email: '', PIB: '',
      phone: '', dateFrom: -1, dateTo: -1, city: '', minDeliveryMin: 0,
      minDeliveryMax: 0, deliveryPriceMin: 0, deliveryPriceMax: 0,
    };
  }

  public getActive(id) {
    if (id == 1) {
      return 'active';
    } else if (id == 0) {
      return 'unactive';
    } else if (id == -1) {
      return 'all';
    }
  }

  public getRowClass(row) {
  if (row.isActive == undefined) {
      return 'unactive-row';
    }
  }

  public filterSupplers() {
    this.loading = true;
    this.apiService.filterSuppliers(this.filter, this.sort, this.dir, this.page).subscribe(
      (data: { suppliers: Supplier[], total: number }) => {
        this.suppliers = data.suppliers;
        this.total = data.total;
        this.loading = false;
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

}
