import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {Cart} from "./model";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor() { }

  public updatedOrder: Subject<Cart> = new Subject();
}
