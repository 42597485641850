import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {ApiServiceService} from '../api-service.service';
import {TranslateService} from '@ngx-translate/core';
import {NewsOrNotice} from '../model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {MainComponent} from '../main/main.component';


@Component({
  selector: 'app-new-news',
  templateUrl: './new-news.component.html',
  styleUrls: ['./new-news.component.scss']
})
export class NewNewsComponent implements OnInit {
  public type: number;
  public obj: NewsOrNotice;
  public Editor = ClassicEditor;
  public mode = '';
  constructor(private main: MainComponent, private router: Router, private snackBar: MatSnackBar, private apiService: ApiServiceService, private translate: TranslateService) {
  }

  public ngOnInit() {

    if (!localStorage.getItem('newsType')) {
      this.router.navigate(['main/start']);
    }

    this.type = +localStorage.getItem('newsType');
    localStorage.removeItem('newsType');

    if (localStorage.getItem('newsToEdit')) {
      this.obj = JSON.parse(localStorage.getItem('newsToEdit'));
      this.mode = 'edit';
      localStorage.removeItem('newsToEdit');
    } else {
      this.mode = 'new';
      this.obj = {title: '', subtitle: '', body: '', image: '', id: 0, createdAt: '', loaded: false};
    }
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadNewsImage(formData).subscribe(
      (data: {path, fullUrl}) => {
        this.obj.image = data.fullUrl;
      }, (err) => {
        this.main.autoLogin(err.status);
      });
  }

  public save() {
    if (this.mode == 'new') {
      this.apiService.createNewsOrNotice(this.type, this.obj).subscribe(
        (data: NewsOrNotice) => {
          this.router.navigate(['main/specifics', data.id, this.type]);
        }, (err) => {
          this.main.autoLogin(err.status);
        }
      );
    } else if (this.mode == 'edit') {
      this.apiService.updateNewsOrNotice(this.obj.id, this.type, this.obj).subscribe(
        (data) => {
          this.router.navigate(['main/specifics', this.obj.id, this.type]);
        }, (err) => {
          this.main.autoLogin(err.status);
        }
      );
    }
  }

}
