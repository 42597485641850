import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {Manufacturer, Product, Supplier, Unit} from '../model';
import {DialogComponent} from '../dialog/dialog.component';

class DialogData {
  public suppliers: Supplier[];
  public product: Product;
}

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  public hasLager;
  public product: Product;
  public oldProduct: Product;
  public manufacturers: Manufacturer[];
  public units: Unit[];
  public manufacturerName = '';
  public supplier: Supplier;
  public  myControl = new FormControl();
  public myControl2 = new FormControl();
  constructor(private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar , private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<EditProductComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  public ngOnInit() {

    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    this.product = this.data.product;
    this.oldProduct = this.data.product;

    this.apiService.getAllManufacturers().subscribe(
      (manufacturers: Manufacturer[]) => {
        this.manufacturers = manufacturers;
      },
    );
    this.apiService.getAllUnits().subscribe(
      (units: Unit[]) => {
        this.units = units;
      },
    );
    if(this.product.lager == null){
      this.hasLager = false;
    }else{
      this.hasLager = true;
    }


  }
  public filterManufacturers() {
    const goodManufacturers = [];
    if (this.manufacturers) {
    this.manufacturers.forEach((m) => {
      if (m.name.includes(this.product.manufacturerName)) {
        goodManufacturers.push(m);
      }
    });
    }
    return goodManufacturers;
  }

  public filterUnits() {

    const goodUnits = [];
    if (this.units) {
    this.units.forEach((u) => {
      // @ts-ignore
      if (u.name.includes(this.product.measurementUnit)) {
        goodUnits.push(u);
      }
    });
    }
    return goodUnits;
  }

  public findManufacturer() {
    let foundManufacturer: Manufacturer = {id: 0, name: ''};
    this.manufacturers.forEach((m) => {
      if (m.name == this.product.manufacturerName) {
        foundManufacturer = m;
      }
    });

    if (foundManufacturer.name != '') {
      this.product.manufacturerId = foundManufacturer.id;
    }
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadProductImage(formData).subscribe(
      (data: {path, fullUrl}) => {
        this.product.image = data.fullUrl;
      });
  }

  public updateProduct($event) {


    if (this.product.measurementUnitId != 18) {
      const index = this.units.findIndex((x) => x.id == this.product.measurementUnitId);
      if (index != -1) {
        this.product.packetNumber = this.units[index].amount;
      } else {
        this.product.packetNumber = 0;
      }
    }

    // @ts-ignore
    if (!this.product.price || this.product.price == '' || this.product.manufacturerName == '' || this.product.measurementUnit == '') {
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFields'), 'OK', {duration: 3000});
      return;
    }

    if(this.hasLager == false ||  this.product.lager < 0 || this.product.lagerLimit < 0){
      this.product.lager = null;
      this.product.lagerLimit = null;
    }
    // check if manufacturer exits

    let foundManufacturer: Manufacturer = {id: 0, name: ''};
    this.manufacturers.forEach((m) => {
      if (m.name == this.product.manufacturerName) {
        foundManufacturer = m;
      }
    });

    if (foundManufacturer.name != '') {
      this.product.manufacturerId = foundManufacturer.id;
    } else {
      this.product.manufacturerId = 0;
    }



    // check if unit exists

    let foundUnit: Unit = {id: 0, name: ''};
    this.units.forEach((u) => {
      // @ts-ignore
      if (u.name == this.product.measurementUnit) {
        foundUnit = u;
      }
    });

    if (foundUnit.name != '') {
      this.product.measurementUnitId = foundUnit.id;
    } else {
      this.product.measurementUnitId = 0;
    }

    this.product.supplierId = this.product.supplier.id;
    console.log(this.product);
    this.apiService.updateProduct(this.product).subscribe(
      (product: Product) => {
        this.product = product;
        console.log(this.product);
        console.log(product);
        if (this.supplier.role == 3) {
          this.snackBar.open(this.translate.instant('productUpdated'), 'OK', {duration: 3000});
          this.dialogRef.close(this.product);
        } else {
          this.product.price = this.product.fullPrice;
          this.snackBar.open(this.translate.instant('productUpdatedUnactive'), 'OK', {duration: 3000});
          this.dialogRef.close();
        }
      },
      (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public onSelect($event){

    if($event.target.checked){

      if(this.hasLager == true){
      $event.target.value = false;
    }else{
      $event.target.value = true;
    }
  }
  }
  public onCancel($event) {
    this.dialogRef.close(null);
  }

  public deactivate($event) {
    const dialogRef = this.dialog.open(DialogComponent, { maxWidth: '90vh !important',
      data: {type: 'actionButton', text: this.translate.instant('areYouSureYouWantToDeactivate')}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deactivateProduct(this.product.id).subscribe(
          (data) => {
            this.dialogRef.close('deactivate');
          }, (err) => {
            this.main.autoLogin(err.status);
          },
        );
      }
    });
  }

  public onConfirm(event) {
    this.dialogRef.close(event);
  }

}
