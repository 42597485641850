<div class="full">
  <div class="upperHeader">
    <img src="/assets/enabavka_long.png" class="logoLong"  (click)="onNavClick('')">
    <div class="help">{{'help' | translate}}</div>
  </div>
  <div class="header">
    <img src="/assets/arrow.png" class="backArrow" (click)="onNavClick('')">
    <div class="title">{{'creatingNewAccount' | translate | uppercase}}</div>
  </div>

  <div class="form row" *ngIf="!verification">
    <div class="titleBig col-12">
      {{'creatingNewAccount' | translate | uppercase}}
    </div>
    <div class="col-12 col-md-6 first">
      <div class="inputForm">
        <mat-label class="label">{{'fullName' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.name" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'identifierLegal' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.identifier" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'pib' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.PIB" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'country' | translate | uppercase}}</mat-label>
        <mat-select [(ngModel)]="supplier.country">
          <mat-option [value]="c.code" *ngFor="let c of countries">{{c.name}}</mat-option>
        </mat-select>
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'city' | translate | uppercase}}</mat-label>
        <mat-select [(ngModel)]="supplier.city">
          <mat-option [value]="c.id.toString()" *ngFor="let c of cities">{{c.name}}</mat-option>
        </mat-select>
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'contactPerson' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.contactPerson" >
      </div>

      <div class="inputForm">
        <mat-label class="label">{{'phone' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.phone" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'centralPhone' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.centralPhone" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'deliveryTime' | translate | uppercase}}</mat-label>
        <div class="inputFormTime">
          <input  class="timeInput" [format]="24" [ngxTimepicker]="startDeliveryPicker" [(ngModel)]="startDelivery" >
          -
          <input  class="timeInput" [format]="24" [ngxTimepicker]="endDeliveryPicker" [(ngModel)]="endDelivery" >

        </div>
        <ngx-material-timepicker  #startDeliveryPicker></ngx-material-timepicker>
        <ngx-material-timepicker  #endDeliveryPicker></ngx-material-timepicker>
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'orderTime' | translate | uppercase}}</mat-label>
        <div class="inputFormTime">
          <input  class="timeInput" [format]="24" [ngxTimepicker]="startOrderPicker" [(ngModel)]="startOrder" >
          -
          <input  class="timeInput" [format]="24" [ngxTimepicker]="endOrderPicker" [(ngModel)]="endOrder" >
        </div>
        <ngx-material-timepicker  #startOrderPicker></ngx-material-timepicker>
        <ngx-material-timepicker  #endOrderPicker></ngx-material-timepicker>
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'deliveryPrice' | translate | uppercase}}</mat-label>
        <input type="number" required class="userInput forPrice" [(ngModel)]="supplier.deliveryPrice"> <span class="rsd-suffix"> RSD </span>
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'minDelivery' | translate | uppercase}}</mat-label>
        <input type="number" required class="userInput forPrice" [(ngModel)]="supplier.minDelivery"> <span class="rsd-suffix"> RSD </span>
      </div>
    </div>
    <div class="col-12 col-md-6 second">
      <mat-label class="label" id="logo" title="{{'clickOnImage' | translate}}">{{'logo' | translate | uppercase}}</mat-label>
      <div class="for-image" (click)="fileInput.click()">
        <img class="supplier-image"  title="{{'clickOnImage' | translate}}"*ngIf="supplier.logo != ''" [src]="supplier.logo">
        <img class="supplier-image"  title="{{'clickOnImage' | translate}}"*ngIf="supplier.logo == ''" src="assets/defaultImage.png">

      </div>
      <input type="file" class="fileInput" #fileInput (change)="onSelectFile($event.target.files)">
      <div class="inputForm">
        <mat-label class="label">{{'address' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.address" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'postalCode' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.postalCode" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'website' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.link" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'description' | translate | uppercase}}</mat-label>
        <input type="text" required class="userInput" [(ngModel)]="supplier.description" >
      </div>
      <hr class="specific-line">
      <div class="inputForm">
        <mat-label class="label">{{'email' | translate | uppercase}}</mat-label>
        <input type="email" required class="userInput" [(ngModel)]="supplier.email" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'password' | translate | uppercase}}</mat-label>
        <input type="password" required class="userInput" [(ngModel)]="supplier.password" >
      </div>
      <div class="inputForm">
        <mat-label class="label">{{'confirmPass' | translate | uppercase}}</mat-label>
        <input type="password" required class="userInput" [(ngModel)]="supplier.confirmPassword" >
      </div>
      <div class="termsOfUse">
        {{'iAccept' | translate | uppercase}} <span class="termsSpecific">{{'termsOfUse' | translate | uppercase}}</span>
        <div class="round">
          <input type="checkbox" id="checkbox"  [(ngModel)]="supplier.termsOfUse"/>
          <label for="checkbox"></label>
        </div>
      </div>
      <div class="continueBig" (click)="register()">
        <div class="continueText">{{'continue' | translate | uppercase}}</div>
      </div>
    </div>
    <div class="continue" (click)="register()">
      <div class="continueText">{{'continue' | translate | uppercase}}</div>
    </div>

  </div>

  <div class="verification" *ngIf="verification">
    <img src="assets/added.png" class="verification-image">
    <div class="verification-text">{{'registrationVerificationText' | translate}}</div>
  </div>
</div>
