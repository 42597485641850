<div class="main-dialog">
  <div class="dialog-title">
    {{'updateProduct' | translate}}
  </div>
  <div class="dialog-body row">
    <div class="left-dialog col-12 col-md-6">
      <mat-form-field class="dialog-form">
        <div class="dialog-label">{{'productName' | translate}}</div>
        <input class="input" matInput type="text" [(ngModel)]="product.name">
      </mat-form-field>
      <mat-form-field>
        <div class="dialog-label">{{'manufacturer' | translate}}</div>
        <input type="text" (change)="findManufacturer()" [(ngModel)]="product.manufacturerName" matInput
               [formControl]="myControl" [matAutocomplete]="manufactur">
      </mat-form-field>
      <mat-autocomplete #manufactur="matAutocomplete">
        <mat-option *ngFor="let m of filterManufacturers()" [value]="m.name">{{m.name}}</mat-option>
      </mat-autocomplete>

      <br>
      <div class="dialog-label">{{'supplier' | translate}}</div>
      <mat-select [(ngModel)]="product.supplier.id">
        <div *ngIf="supplier.role === 3">
          <mat-option *ngFor="let s of data.suppliers" [value]="s.id">{{s.name}}</mat-option>
        </div>
        <div *ngIf="supplier.role === 2">
          <mat-option [value]="supplier.id">{{supplier.name}}</mat-option>
        </div>
      </mat-select>
      <mat-form-field>
        <div class="dialog-label">{{'measurumentUnit' | translate}}</div>
        <mat-select [(ngModel)]="product.measurementUnitId">
          <mat-option *ngFor="let u of units" [value]="u.id">{{u.parentUnit.name}} / {{u.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <div class="dialog-label">{{'price' | translate}}</div>
        <input matInput type="number" [(ngModel)]="product.price" min="0">
        <span class="suffix" *ngIf="product.supplier">{{product.supplier.currency}}</span>
        <span class="suffix" *ngIf="!product.supplier">RSD</span>

      </mat-form-field>

    </div>
    <div class="right-dialog col-12 col-md-6">
      <div class="dialog-label">{{'image' | translate}}</div>
      <div class="for-image" (click)="fileInput.click()">
        <img class="supHlier-image" title="{{'clickOnImage' | translate}}" *ngIf="product.image != ''"
             [src]="product.image">
        <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="product.image == ''"
             src="assets/defaultImage.png">

      </div>
      <input type="file" class="fileInput" #fileInput (change)="onSelectFile($event.target.files)">
      <mat-form-field class="dialog-form" id="first-pictured" *ngIf="product.measurementUnitId == 18">
        <div class="dialog-label">{{'packetNumber' | translate}}</div>
        <input matInput class="input" type="number" [(ngModel)]="product.packetNumber" min="0">
      </mat-form-field>
      <mat-form-field class="dialog-form">
        <div class="dialog-label">{{'description' | translate}}</div>
        <input matInput class="input" type="text" [(ngModel)]="product.description">
      </mat-form-field>


      <div class="text-center" >
        <mat-checkbox class="haslager" (change)='onSelect($event)' [(ngModel)]="hasLager">{{'hasLager' | translate}}</mat-checkbox>
      </div>
      <mat-form-field class="dialog-form " *ngIf="hasLager == true">
        <div class="dialog-label">{{'lager' | translate}}</div>
        <input matInput class="input" type="number" [(ngModel)]="product.lager">
      </mat-form-field>
      <mat-form-field class="dialog-form " *ngIf="hasLager == true">
        <div class="dialog-label">Lager Limit</div>
        <input matInput class="input" type="number" [(ngModel)]="product.lagerLimit">
      </mat-form-field>



      <mat-checkbox class="show-price-check"  [(ngModel)]="product.showPrice">{{'showPrice' | translate}}</mat-checkbox>
    </div>
    <div class="deactivate" *ngIf="supplier.role == 3 && product.active == 1" (click)="deactivate($event)">
      <span>{{'deactivate' | translate }} </span>
    </div>
    <div class="action-buttons">
      <div class="closeButton" (click)="onCancel($event)">
        <span class="buttonText">{{'close' | translate | uppercase}} </span>
      </div>
      <div class="confirmButton" (click)="updateProduct($event)">
        <span class="buttonText">{{'confirm' | translate | uppercase}} </span>
      </div>
    </div>

  </div>

</div>
