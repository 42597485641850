<div class="container">
  <div class="row">
    <div class="col-lg-2 col-md-12"></div>
    <div class="col-lg-8 col-md-12 pad">
      <div class="text-right">
        <button type="button" class="btn btn-outline-dark" (click)="createCity()"> &nbsp;&nbsp; Dodaj Grad + &nbsp;&nbsp;</button>
      </div>
      <table class="table">
        <thead class="heade_rcolor text-light">
        <tr>
          <th scope="col">ID</th>
          <th scope="col">NAME</th>
          <th scope="col">EDIT</th>
          <th scope="col">DELETE</th>
        </tr>
        </thead>
        <tbody *ngFor="let city of cities">

        <tr>

          <td>{{city.id}}</td>
          <td>{{city.name}}</td>
          <td><button type="button" class="btn btn-outline-dark" (click)="updateCity(city)"><mat-icon>edit
          </mat-icon></button></td>
          <td><button type="button" class="btn btn-outline-dark" (click)="deleteCity(city)"><mat-icon>delete
          </mat-icon></button>
          </td>
        </tr>

        </tbody>
      </table>


    </div>
    <div class="col-lg-2 col-md-12"></div>
  </div>
</div>

