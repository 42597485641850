import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

class DialogData {
  public type;
  public text
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {

  public action = 0;
  public dateTo = new Date();
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  public ngOnInit() {
    this.dateTo.setDate(this.dateTo.getDate() + 31);
  }

  public onCancel($event) {
    this.dialogRef.close(null);
  }

  public onConfirm(event) {
    this.dialogRef.close(event);
  }

  public onConfirmWithAction(event) {
    const data = [];
    data['action'] = this.action;
    data['dateTo'] = this.dateTo;
    this.dialogRef.close(data);
  }

}
