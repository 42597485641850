<div class="main-dialog">
  <div class="dialog-body row">
    <div class="left-big-dialog col-12 col-md-6">
      <div class="dialog-title">
        {{'actualProduct' | translate}}
      </div>
      <div class="row">
        <div class="left-dialog col-12 col-md-6">
          <mat-form-field class="dialog-form">
            <div class="dialog-label">{{'productName' | translate}}</div>
            <input class="input" matInput type="text" disabled [(ngModel)]="product.product.name">
          </mat-form-field>
          <mat-form-field>
            <div class="dialog-label">{{'manufacturer' | translate}}</div>
            <input type="text" disabled (change)="findManufacturer()" [(ngModel)]="product.product.manufacturerName" matInput>
          </mat-form-field>

          <br>
          <div class="dialog-label">{{'supplier' | translate}}</div>
          <mat-select [(ngModel)]="product.product.supplierId" disabled>
            <div *ngIf="supplier.role === 3">
              <mat-option *ngFor="let s of data.suppliers" [value]="s.id">{{s.name}}</mat-option>
            </div>
            <div *ngIf="supplier.role === 2">
              <mat-option [value]="supplier.id">{{supplier.name}}</mat-option>
            </div>
          </mat-select>
          <mat-form-field>
            <div class="dialog-label">{{'measurumentUnit' | translate}}</div>
            <input type="text" disabled [(ngModel)]="product.product.measurementUnitName" matInput>
          </mat-form-field>
          <mat-form-field>
            <div class="dialog-label">{{'price' | translate}}</div>
            <input matInput type="number" disabled [(ngModel)]="product.product.price" min="0">
            <span class="suffix" *ngIf="product.product.supplier">{{product.product.supplier.currency}}</span>
            <span class="suffix" *ngIf="!product.product.supplier">RSD</span>

          </mat-form-field>

        </div>
        <div class="right-dialog col-12 col-md-6">
          <div class="dialog-label">{{'image' | translate}}</div>
          <div class="for-image" >
            <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="product.product.image != ''"
                 [src]="product.product.image">
            <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="product.product.image == ''"
                 src="assets/defaultImage.png">

          </div>
          <mat-form-field class="dialog-form first-pictured">
            <div class="dialog-label">{{'packetNumber' | translate}}</div>
            <input matInput class="input" disabled type="number" [(ngModel)]="product.product.packetNumber" min="0">
          </mat-form-field>
          <mat-form-field class="dialog-form">
            <div class="dialog-label">{{'description' | translate}}</div>
            <input matInput class="input" type="text" disabled [(ngModel)]="product.product.description">
          </mat-form-field>
          <mat-checkbox class="show-price-check"  [(ngModel)]="product.product.showPrice">{{'showPrice' | translate}}</mat-checkbox>

        </div>

      </div>
    </div>
    <div class="right-big-dialog col-12 col-md-6">
      <div class="dialog-title">
        {{'newData' | translate}}
      </div>
  <div class="row">
    <div class="left-dialog col-12 col-md-6">
      <mat-form-field class="dialog-form">
        <div class="dialog-label">{{'productName' | translate}}</div>
        <input class="input" [ngClass]="{'bolded-input' : product.isNameEdited}" matInput disabled type="text" [(ngModel)]="product.name">
      </mat-form-field>
      <mat-form-field>
        <div class="dialog-label">{{'manufacturer' | translate}}</div>
        <input type="text" [ngClass]="{'bolded-input' : product.isManufacturEdited}" disabled (change)="findManufacturer()" [(ngModel)]="product.manufacturerName" matInput>
      </mat-form-field>

      <br>
      <div class="dialog-label">{{'supplier' | translate}}</div>
      <mat-select [(ngModel)]="product.supplierId" disabled>
        <div *ngIf="supplier.role === 3">
          <mat-option *ngFor="let s of data.suppliers" [value]="s.id">{{s.name}}</mat-option>
        </div>
        <div *ngIf="supplier.role === 2">
          <mat-option [value]="supplier.id">{{supplier.name}}</mat-option>
        </div>
      </mat-select>
      <mat-form-field>
        <div class="dialog-label">{{'measurumentUnit' | translate}}</div>
        <input type="text"  [ngClass]="{'bolded-input' : product.isUnitEdited}"  [(ngModel)]="product.measurementUnit" matInput disabled>
      </mat-form-field>
      <mat-form-field>
        <div class="dialog-label">{{'price' | translate}}</div>
        <input matInput type="number"  [ngClass]="{'bolded-input' : product.isPriceEdited}"  disabled [(ngModel)]="product.price" min="0">
        <span class="suffix">RSD</span>

      </mat-form-field>

    </div>
    <div class="right-dialog col-12 col-md-6">
      <div class="dialog-label">{{'image' | translate}}</div>
      <div class="for-image">
        <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="product.image != ''"
             [src]="product.image">
        <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="product.image == ''"
             src="assets/defaultImage.png">

      </div>
      <mat-form-field class="dialog-form first-pictured">
        <div class="dialog-label">{{'packetNumber' | translate}}</div>
        <input matInput disabled [ngClass]="{'bolded-input' : product.isPacketEdited}"  class="input" type="number" [(ngModel)]="product.packetNumber" min="0">
      </mat-form-field>
      <mat-form-field class="dialog-form"  [ngClass]="{'bolded-input' : product.isDescriptionEdited}" >
        <div class="dialog-label">{{'description' | translate}}</div>
        <input matInput disabled class="input" type="text" [(ngModel)]="product.description">
      </mat-form-field>
      <mat-checkbox class="show-price-check"  [(ngModel)]="product.showPrice">{{'showPrice' | translate}}</mat-checkbox>

    </div>

  </div>
    </div>
    <div class="action-buttons">
      <div class="closeButton" (click)="confirmApply(2)">
        <span class="buttonText">{{'cancel' | translate | uppercase}} </span>
      </div>
      <div class="confirmButton" (click)="confirmApply(1)">
        <span class="buttonText">{{'confirm' | translate | uppercase}} </span>
      </div>
    </div>
  </div>

</div>
