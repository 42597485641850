<div class="mainDialog" *ngIf="data.type == 'actionButton'">
  <img src="assets/question.png" class="questionImage">
  <div >
    <div class="dialogText">
      <span> {{data.text}} ? </span>
    </div>
    <div class="closeButton" (click)="onCancel($event)">
      <span class="buttonText">{{'close' | translate | uppercase}} </span>
    </div>

    <div class="confirmButton" (click)="onConfirm($event)">
      <span class="buttonText">{{'confirm' | translate | uppercase}} </span>
    </div>
  </div>
</div>

<div class="actionDialog" *ngIf="data.type == 'actionAdd'">
  <img src="assets/question.png" class="questionImage">
  <div >
    <div class="dialogTextAction">
        <span> {{data.text}}:   </span>
        <mat-form-field class="actionInput">
          <input type="number" min="0" [(ngModel)]="action" matInput>
          <br>
          <span matSuffix> % </span>
        </mat-form-field>
      <div class="for-date">
        <span class="calendarText">{{'dateTo' | translate}}: </span>
        <div class="forInput">
        <input #fromInput matInput [matDatepicker]="date"
               [(ngModel)]="dateTo">
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
        </div>
      </div>

    </div>
    <div class="closeButton" (click)="onCancel($event)">
      <span class="buttonText">{{'close' | translate | uppercase}} </span>
    </div>

    <div class="confirmButton" (click)="onConfirmWithAction($event)">
      <span class="buttonText">{{'confirm' | translate | uppercase}} </span>
    </div>
  </div>
</div>
<div class="chooseEdit" *ngIf="data.type == 'chooseEdit'">
  <div class="choice" (click)="onConfirm('edit')">
    {{'editProfile' | translate}}
  </div>
  <div class="choice" (click)="onConfirm('password')">
    {{'changePassword' | translate}}
  </div>
</div>



