import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MainComponent } from './main/main.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MessagingService} from './shared/messaging.service';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {
  MatAutocompleteModule, MatButtonModule,
  MatButtonToggleModule, MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule, MatDialogModule,
  MatExpansionModule, MatIconModule,
  MatInputModule, MatMenuModule, MatNativeDateModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBar,
  MatSnackBarModule
} from '@angular/material';
import {OverlayModule} from '@angular/cdk/overlay';
import { StartComponent } from './start/start.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { FooterBigComponent } from './footer-big/footer-big.component';
import { ForgottenPassComponent } from './forgotten-pass/forgotten-pass.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {DragScrollModule} from 'ngx-drag-scroll';
import { SuppliersComponent } from './suppliers/suppliers.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { DialogComponent } from './dialog/dialog.component';
import { ProductsComponent } from './products/products.component';
import {ClickOutsideModule} from 'ng-click-outside';
import { FilterProductsComponent } from './filter-products/filter-products.component';
import { NewProductComponent } from './new-product/new-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { ProductEditsComponent } from './product-edits/product-edits.component';
import { EditProductSpecificComponent } from './edit-product-specific/edit-product-specific.component';
import {ChartsModule} from 'ng2-charts';
import { CustomersComponent } from './customers/customers.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './order/order.component';
import { SupplierComponent } from './supplier/supplier.component';
import { CustomerComponent } from './customer/customer.component';
import { RabatsComponent } from './rabats/rabats.component';
import { RabatComponentComponent } from './rabat-component/rabat-component.component';
import { SpecificListComponent } from './specific-list/specific-list.component';
import { NewsNoticeComponent } from './news-notice/news-notice.component';
import { NewNewsComponent } from './new-news/new-news.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { ListOfBlocksComponent } from './list-of-blocks/list-of-blocks.component';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { ActionsComponent } from './actions/actions.component';
import { ActionFilterComponent } from './action-filter/action-filter.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { ProductHistoryComponent } from './product-history/product-history.component';
import { AdminsComponent } from './admins/admins.component';
import { AdminsUpdateComponent } from './admins-update/admins-update.component';
import { NewAdminComponent } from './new-admin/new-admin.component';
import { DeleteAdminComponent } from './delete-admin/delete-admin.component';
import {CitiesComponent} from "./cities/cities.component";
import { UpdateCityComponent } from './update-city/update-city.component';
import { CreateCityComponent } from './create-city/create-city.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    MainComponent,
    StartComponent,
    ContactPageComponent,
    FooterBigComponent,
    ForgottenPassComponent,
    SuppliersComponent,
    DialogComponent,
    ProductsComponent,
    FilterProductsComponent,
    NewProductComponent,
    EditProductComponent,
    ProductEditsComponent,
    EditProductSpecificComponent,
    CustomersComponent,
    OrdersComponent,
    OrderComponent,
    SupplierComponent,
    CustomerComponent,
    RabatsComponent,
    RabatComponentComponent,
    SpecificListComponent,
    NewsNoticeComponent,
    NewNewsComponent,
    ListOfBlocksComponent,
    ProfileComponent,
    EditProfileComponent,
    EditPasswordComponent,
    ActionsComponent,
    ActionFilterComponent,
    OrderEditComponent,
    ProductHistoryComponent,
    AdminsComponent,
    AdminsUpdateComponent,
    NewAdminComponent,
    DeleteAdminComponent,
    CitiesComponent,
    UpdateCityComponent,
    CreateCityComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'sr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatSidenavModule,
    DragScrollModule,
    MatInputModule,
    MatExpansionModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatIconModule,
    NgxDatatableModule,
    InfiniteScrollModule,
    ClickOutsideModule,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule,
    ChartsModule,
    MatCheckboxModule,
    CKEditorModule,
    MatButtonModule

  ],
  // tslint:disable-next-line:object-literal-sort-keys
  entryComponents: [OrderEditComponent, ListOfBlocksComponent, CreateCityComponent, RabatComponentComponent, SupplierComponent, CustomerComponent, AdminsUpdateComponent, NewAdminComponent, UpdateCityComponent, OrderComponent, EditProductSpecificComponent, EditProductComponent, DialogComponent, NewProductComponent],
  providers: [DialogComponent, MessagingService, RegisterComponent, MainComponent, MatSnackBar, StartComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
