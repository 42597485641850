<div class="full">
  <div class="upperHeader">
    <img class="logoLong" src="/assets/enabavka_long.png" (click)="goHome()">
    <div class="contactBig" (click)="openContact()">
      {{'contact' | translate }}
    </div>
  </div>
  <div class="header">
    <img src="/assets/arrow.png"  class="backArrow" (click)="goHome()">
    <div class="headerTitle">{{'forgottenPass' | translate | uppercase}}</div>
  </div>
  <div class="mainDiv">
  </div>

<div class="mainContainer">
  <div class="edit" *ngIf="!verification">
    <div class="bigTitle">{{'forgottenPass' | translate}} ?</div>
    <div class="verificationText">{{'insertEmail' | translate}}</div>
    <div class="labelText">{{'email' | translate | uppercase}}</div>
    <input type="email" class="inputField" [(ngModel)]="email">
    <div class="forChangeButton">
      <div class="changeButton" (click)="changeEmail();">{{'send' | translate | uppercase}}
      </div>

    </div>
  </div>
  <div class="verification" *ngIf="verification">
    <div class="verificationText">{{'forgottenPassText' | translate}}</div>
    <div class="forChangeButton">
      <div class="changeButton afterSent" (click)="goHome();">{{'login' | translate | uppercase}}
      </div>
    </div>


  </div>
</div>
</div>
