import {Component, Inject, OnInit} from '@angular/core';
import {Cart, Customer, Supplier} from '../model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {MainComponent} from '../main/main.component';
import {ApiServiceService} from '../api-service.service';
import {RabatComponentComponent} from '../rabat-component/rabat-component.component';
import {ListOfBlocksComponent} from '../list-of-blocks/list-of-blocks.component';

class DialogData {
  public customer: Customer;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  public supplier: Supplier;

  constructor(private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar, private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<CustomerComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));
  }

  public toggleBlock() {
    if (this.supplier.role == 2) {
      this.apiService.toggleBlock(this.supplier.id, this.data.customer.id).subscribe(
        (data) => {
          this.data.customer.isBlocked = !this.data.customer.isBlocked;
        }, (err) => {
          this.main.autoLogin(err.status);
        }
      );
    } else {
      let listOfBlocks = [];

      this.apiService.listOfBlocks(this.data.customer.id).subscribe(
        (data: object[]) => {
          listOfBlocks = data;
          const dialogRef = this.dialog.open(ListOfBlocksComponent, {
            data: {list: listOfBlocks, customerId: this.data.customer.id, panelClass: 'warningDialog'}
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            }
          });
        }, (err) => {
          this.main.autoLogin(err.status);
        },
      );
    }

  }


}
