import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiServiceService} from '../api-service.service';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-new-admin',
  templateUrl: './new-admin.component.html',
  styleUrls: ['./new-admin.component.scss'],
})

export class NewAdminComponent implements OnInit {
 public admin = {email: '' , password: ''};
  constructor(public dialogRef: MatDialogRef<NewAdminComponent>,  private ApiSerice: ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) { }

  public ngOnInit() {
  }
  public saveNew(){
    this.ApiSerice.newAdmin(this.admin).subscribe(
      (response )=>{
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
        this.dialogRef.close();
      }
    )
  }
}
