import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

class DialogData{
  public admin;
}

@Component({
  selector: 'app-admins-update',
  templateUrl: './admins-update.component.html',
  styleUrls: ['./admins-update.component.scss']
})
export class AdminsUpdateComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdminsUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData , private ApiService : ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) { }

  ngOnInit() {

  }

  public save(){
    this.ApiService.updateAdmin(this.data.admin).subscribe(
      (response)=>{
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
        this.dialogRef.close();
      }
    )
  }
}
