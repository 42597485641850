<div class="bigContainer">
  <div class="search">

    <div class="mainPart row">
      <div class="leftMenu col-12 col-md-3">
        <div class="menuTitle">{{'menu' | translate | uppercase}}</div>
        <div class="menuList">
      <span class="menuItem" (click)="openActions()"><img src="assets/Akcije.png"
                                                          class="menuLogo">{{'actions' | translate}}</span> <br>
          <!--      <span class="menuItem" (click)="onNavClick('main/list',-1, '2')"><img src="assets/Obavestenja.png" class="menuLogo">{{'notifications' | translate}}</span><br>-->
          <!--      <span class="menuItem" (click)="onNavClick('main/list',-1, '1')"><img src="assets/news.png" class="menuLogo">{{'news' | translate}}</span><br>-->
          <span class="menuItem" (click)="resetAndOpenProducts();main.text='products'"><img src="assets/products.png"
                                                                                            class="menuLogo">{{'products' | translate}}</span><br>
          <span class="menuItem" (click)="onNavClick('main/list',-1, '2')" *ngIf="supplier.role == 3"><img
            src="assets/Obavestenja.png" class="menuLogo">{{'notifications' | translate}}</span><br *ngIf="supplier.role == 3">

          <span class="menuItem" (click)="onNavClick('main/rabats', -1, -1)"><img src="assets/dollar.png"
                                                                                  class="menuLogo">{{'rabat' | translate}}</span><br>
          <span class="menuItem" *ngIf="supplier.role == 3" (click)="onNavClick('main/suppliers', -1, -1)"><img
            src="assets/truck.png" class="menuLogo">{{'suppliers' | translate}}</span><br *ngIf="supplier.role == 3">
          <span class="menuItem" *ngIf="supplier.role == 3" (click)="onNavClick('main/edits/product', -1, -1)"><img
            src="assets/settings.png" class="menuLogo">{{'productEdits' | translate}}</span><br
          *ngIf="supplier.role == 3">
          <span class="menuItem" (click)="onNavClick('main/customers', -1, -1)"><img src="assets/cart_blue.png"
                                                                                     class="menuLogo">{{'customers' | translate}}</span><br>
          <span class="menuItem" (click)="onNavClick('main/orders', -1, -1);main.text='ordersArchive'"><img
            src="assets/archive_blue.png" class="menuLogo">{{'ordersArchive' | translate}} <span class="text-danger smaller_font" *ngIf="countRead > 0">{{countRead}}</span> </span><br>
          <span class="menuItem" (click)="onNavClick('main/history', -1, -1);main.text='productHistory'"><img
            src="assets/cart_dollar.png" class="menuLogo">{{'productHistory' | translate}}</span><br>
          <!--      <span class="menuItem"><img src="assets/settings.png" class="menuLogo">{{'settings' | translate}}</span><br>-->
          <span class="menuItem" (click)="onNavClick('main/profile',-1, -1)"><img src="assets/profile.png"
                                                                                  class="menuLogo">{{'profile' | translate}}</span><br>
          <span class="menuItem" (click)="onNavClick('contact',-1, -1)"><img src="assets/contactus.png"
                                                                             class="menuLogo">{{'contactUs' | translate}}</span><br>
          <span class="menuItem" (click)="logOut()"><img src="assets/logout.png" class="menuLogo"><span
            (click)="logOut()">{{'logOut' | translate}}</span></span><br>

        </div>
      </div>
      <div class="for-charts col-12 col-md-6">
        <div class="for-chart">
          <h3 class="menuTitle">{{'orderCount' | translate}}</h3>
          <canvas baseChart width="600" height="300"
                  [datasets]="ordersData"
                  [labels]="ordersLabel"
                  [colors]="lineChartColors"
                  [options]="lineChartOptions"
                  [chartType]="'line'">

          </canvas>

        </div>
        <div class="for-chart" class="suppliers" *ngIf="supplier.role === 3">
          <h3 class="menuTitle">{{'mostPopularSuppliers' | translate}}</h3>
          <canvas baseChart width="600" height="300"
                  [datasets]="suppliersData"
                  [labels]="suppliersLabel"
                  [colors]="lineChartColors"
                  [options]="lineChartOptions"
                  [chartType]="'bar'">

          </canvas>

        </div>
        <div class="for-chart long-chart">
          <h3 class="menuTitle">{{'earning' | translate}}</h3>
          <h3 class="menuTitleMini">{{'totalEarning' | translate}}: {{totalEarning}} RSD</h3>
          <canvas baseChart width="600" height="300"
                  [datasets]="earningsData"
                  [labels]="ordersLabel"
                  [colors]="lineChartColors"
                  [options]="lineChartOptions"
                  [chartType]="'line'">

          </canvas>

        </div>

      </div>
      <div class="for-ranges col-12 col-md-3">
        <div class="section-menu" (click)="$event.stopPropagation()">
          <mat-form-field class="section-form">
            <input #fromInput matInput (dateChange)="getChart()" [(ngModel)]="start" [matDatepicker]="pickerFrom"
                   placeholder="{{'dateFrom' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="section-form">
            <input #fromInput matInput (dateChange)="getChart()" [(ngModel)]="end" [matDatepicker]="dateTo"
                   placeholder="{{'dateTo' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
            <mat-datepicker #dateTo></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="for-table">
          <h3 class="menuTitle">{{'mostPopularProducts' | translate}}</h3>

          <ngx-datatable
            class="material"
            [rows]="allTimeProducts"
            [columnMode]="'force'"
            [messages]="my_messages"
            [headerHeight]="50"
            [rowHeight]="50"
          >
            <ngx-datatable-column name="{{'productName' | translate}}" sortable="false" prop="name" [width]="55">
              <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'orderCountMini' | translate}}" sortable="false" prop="count" [width]="45">
              <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
        <div class="for-table">
          <h3 class="menuTitle">{{'mostPopularCustomers' | translate}}</h3>

          <ngx-datatable
            class="material"
            [rows]="allTimeCustomers"
            [messages]="my_messages"
            [columnMode]="'force'"
            [headerHeight]="50"
            [rowHeight]="50"
          >
            <ngx-datatable-column name="{{'productName' | translate}}" sortable="false" prop="legal_name" [width]="55">
              <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'orderCountMini' | translate}}" sortable="false" prop="count" [width]="45">
              <ng-template let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
  <div class="footerSmall">
    <div class="option" (click)="onNavClick('main/products', -1, -1); main.text = 'products'">
      <img class="downIcon" src="/assets/search_bottom.png">
      <p class="optionText">{{'products' | translate}}</p>
    </div>
    <div class="option" (click)="onNavClick('main/customers', -1, -1); main.text = 'customers'">
      <img class="downIcon" src="/assets/cart.png">
      <p class="optionText">{{'customers' | translate}}</p>
    </div>
    <div class="option">
      <img class="downIcon" src="/assets/archive.png">
      <p class="optionText"
         (click)="onNavClick('main/orders', -1, -1); main.text = 'ordersArchive'">{{'ordersArchive' | translate}}</p>
    </div>



  </div>
</div>
<div class="footerBig">
  <app-footer-big></app-footer-big>
</div>

<div class="loadingContainer"
     [ngClass]="{'visibleLoading' : loading }">
  <div class="divForSpinner">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>
