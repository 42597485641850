import {Component, Inject, OnInit} from '@angular/core';
import {Manufacturer, Product, ProductEdit, Supplier, Unit} from '../model';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {MainComponent} from '../main/main.component';
import {ApiServiceService} from '../api-service.service';

class DialogData {
  public suppliers: Supplier[];
  public product: ProductEdit;
}


@Component({
  selector: 'app-edit-product-specific',
  templateUrl: './edit-product-specific.component.html',
  styleUrls: ['./edit-product-specific.component.scss']
})
export class EditProductSpecificComponent implements OnInit {

  public product: ProductEdit;
  public manufacturers: Manufacturer[];
  public units: Unit[];
  public manufacturerName = '';
  public supplier: Supplier;
  public  myControl = new FormControl();
  public myControl2 = new FormControl();
  constructor(private translate: TranslateService, private snackBar: MatSnackBar , private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<EditProductSpecificComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    this.product = this.data.product;

    this.apiService.getAllManufacturers().subscribe(
      (manufacturers: Manufacturer[]) => {
        this.manufacturers = manufacturers;
      },
    );
    this.apiService.getAllUnits().subscribe(
      (units: Unit[]) => {
        this.units = units;
      },
    );
  }

  public findManufacturer() {
    let foundManufacturer: Manufacturer = {id: 0, name: ''};
    this.manufacturers.forEach((m) => {
      if (m.name == this.product.manufacturerName) {
        foundManufacturer = m;
      }
    });

    if (foundManufacturer.name != '') {
      this.product.manufacturerId = foundManufacturer.id;
    }
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadProductImage(formData).subscribe(
      (data: {path, fullUrl}) => {
        this.product.image = data.fullUrl;
      });
  }

  public confirmCancel(event) {
    const type = 2;
    const ids = [this.product.id];
    this.apiService.applyProductEdits(ids, type).subscribe(
      (data) => {
        this.dialogRef.close(this.product.id);
        this.snackBar.open(this.translate.instant("changeCanceled"), "OK", {duration: 3000});
      }, (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }
  public confirmApply(type) {
    const ids = [this.product.id];
    this.apiService.applyProductEdits(ids, type).subscribe(
      (data) => {
        this.dialogRef.close(this.product.id);
        this.snackBar.open(this.translate.instant("changeApplied"), "OK", {duration: 3000});
      }, (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

}
