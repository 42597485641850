import { Component, OnInit } from '@angular/core';
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-create-city',
  templateUrl: './create-city.component.html',
  styleUrls: ['./create-city.component.scss']
})
export class CreateCityComponent implements OnInit {
  public city = {name: null};
  constructor(public dialogRef: MatDialogRef<CreateCityComponent>, private ServiceApI: ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) { }

  ngOnInit() {
  }
  public create(){
    this.ServiceApI.createCity(this.city).subscribe(
      (response) => {
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
        this.dialogRef.close();
      }
    )
  }
}
