import {Component, HostListener, OnInit} from '@angular/core';
import {ApiServiceService} from "../api-service.service";
import {CartProduct, Product, Supplier} from "../model";
import {MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-product-history',
  templateUrl: './product-history.component.html',
  styleUrls: ['./product-history.component.scss']
})
export class ProductHistoryComponent implements OnInit {
  public endDate = new Date();
  public startDate = new Date();
  public filter: {startDate?, endDate?, supplierId: 0};
  public showHeader = true;
  public total = 0;
  public supplier: Supplier;
  public suppliers: Supplier[] = [];
  public showText = true;
  public page = 0;
  public products: CartProduct[] = [];
  public loading = false;
  public logoWidth = 30;
  public my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  constructor(private apiService: ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  public ngOnInit() {

    this.supplier = JSON.parse(localStorage.getItem('supplier'));


    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 30;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }

    this.startDate.setDate(this.endDate.getDate() -  31);
    this.filter = {startDate: this.startDate, endDate: this.endDate, supplierId: 0};
    this.getHistory();
    this.onResized();

    this.apiService.getAllSuppliers().subscribe(
      (data: Supplier[]) => {
        this.suppliers = data;
      }
    );

  }

  public onResized() {
    if (window.innerWidth > 800) {
      this.showText = true;
      this.logoWidth = 30;
    } else {
      this.logoWidth = 50;
      this.showText = false;
    }
  }

  public getHistory() {
    this.loading = true;
    this.apiService.getProductsHistory(this.filter).subscribe(
      (data: CartProduct[]) => {
        this.products = data;
        console.log(this.products);
        this.loading = false;
      }, (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
        this.loading = false;
      });
  }

  public findSupplier() {
    const id = this.filter.supplierId;
    if (id == 0) {
      return this.translate.instant('allSuppliers');
    }
    const index = this.suppliers.findIndex((x) => x.id == id);

    if (index != -1) {
      return this.suppliers[index].name;
    }
  }

  public clearFilter() {
    this.filter = {supplierId: 0};
  }


  @HostListener('window:scroll', ['$event'])
  public scrollWindow(event) {
    if (window.pageYOffset > 100) {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  }
}
