import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';

@Component({
  selector: 'app-forgotten-pass',
  templateUrl: './forgotten-pass.component.html',
  styleUrls: ['./forgotten-pass.component.scss'],
})
export class ForgottenPassComponent implements OnInit {
  public verification = false;
  public email = '';

  constructor(private translate: TranslateService, private router: Router, private apiService: ApiServiceService, private snackBar: MatSnackBar) {
  }

  public ngOnInit() {
  }

  public openContact() {
    this.router.navigate(['contact']);
  }

  public emailIsValid() {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email);
  }

  public changeEmail() {
    if (!this.emailIsValid()) {
      this.snackBar.open(this.translate.instant('emailNotValid'), 'OK', {duration: 3000});
      return;
    }
    this.apiService.forgottenPassword(this.email).subscribe(
      (data) => {
        this.verification = true;
      }, (err) => {
        if (err.status == 404) {
          this.snackBar.open(this.translate.instant('emailNotFound'), 'OK', {duration: 3000});
        }
      },
    );
  }

  public goHome() {
    this.router.navigate(['login']);
  }

}
