import { Component, OnInit } from '@angular/core';
import {Manufacturer, ProductFilter, Supplier} from "../model";
import {ApiServiceService} from "../api-service.service";
import {MainComponent} from "../main/main.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-action-filter',
  templateUrl: './action-filter.component.html',
  styleUrls: ['./action-filter.component.scss']
})
export class ActionFilterComponent implements OnInit {

  public productFilter: ProductFilter = {suppliers: [0]};
  public manufacturers: Manufacturer[];
  public suppliers: Supplier[];
  public supplier: Supplier;

  constructor(private apiService: ApiServiceService, private main: MainComponent, private router: Router) {
  }

  public ngOnInit() {
    if (localStorage.getItem('supplier')) {
      {
        this.supplier = JSON.parse(localStorage.getItem('supplier'));
      }
    }
    localStorage.getItem('actionFilter') ?
      this.productFilter = JSON.parse(localStorage.getItem('actionFilter'))
      : this.productFilter = {suppliers: [0]};
    this.getSuppliers();

    if (window.innerWidth > 1400) {
      this.router.navigate(['main/products']);
    }
  }

  public onResize(event) {
    if (window.innerWidth > 1400) {
      this.router.navigate(['main/products']);
    }
  }

  public getSuppliers() {
    this.apiService.getAllSuppliers().subscribe(
      (suppliers: Supplier[]) => {
        this.suppliers = suppliers;
      },
      (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );
  }

  public toggleCheck(id, type) {
    if (type === 'a') { // active

      this.productFilter.active = id;

    } else if (type === 's') {// supplier

      const index = this.productFilter.suppliers.findIndex(x => x == id);

      if (index == -1) {
        if (id == 0) {
          this.productFilter.suppliers = [0];
        }
        this.productFilter.suppliers.push(id);
      } else {
        this.productFilter.suppliers.splice(index, 1);
      }

      if (this.productFilter.suppliers.length > 0) { // if any other option checked remove 'all'
        const zeroIndex = this.productFilter.suppliers.findIndex(x => x == 0);
        if (zeroIndex != -1) {
          this.productFilter.suppliers.splice(zeroIndex, 1);
        }

      }

      if (this.productFilter.suppliers.length == 0) {
        this.productFilter.suppliers = [0];
      }

      this.productFilter.suppliers = [...this.productFilter.suppliers];

    } else if (type === 'act') {// action

      this.productFilter.actionOnly = id;
    }

    localStorage.setItem('actionFilter', JSON.stringify(this.productFilter));
  }

  public reset() {
    this.productFilter = {suppliers: [0]};
  }

  public checkSuppliers(event) {
    if (this.productFilter.suppliers.length > 1 && this.productFilter.suppliers.includes(0)) {
      const indexZero = this.productFilter.suppliers.findIndex((x) => x == 0);

      if (indexZero != -1) {
        this.productFilter.suppliers.splice(indexZero, 1);
        this.productFilter.suppliers = [...this.productFilter.suppliers];
      }
    }
  }

  public resetSuppliers() {
    this.productFilter.suppliers = [0];
    this.productFilter.suppliers = [...this.productFilter.suppliers];
  }

  public apply() {
    localStorage.setItem('actionFilter', JSON.stringify(this.productFilter));
    this.router.navigate(['main/actions']);
  }

  public toggleAction() {
    if (this.productFilter.action == 1) {
      delete this.productFilter.action;
    } else {
      this.productFilter.action = 1;
    }
  }

  public toggleActive() {
    if (this.productFilter.active && this.productFilter.active == 1) {
      delete this.productFilter.active;
    } else {
      this.productFilter.active = 1;
    }
  }

  public toggleStock() {
    if (this.productFilter.stock && this.productFilter.stock == 1) {
      delete this.productFilter.stock;
    } else {
      this.productFilter.stock = 1;
    }
  }

}
