import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiServiceService} from "../api-service.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
class DialogData{
  public city;
}
@Component({
  selector: 'app-update-city',
  templateUrl: './update-city.component.html',
  styleUrls: ['./update-city.component.scss']
})
export class UpdateCityComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<UpdateCityComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData , private ApiService : ApiServiceService, private snackBar: MatSnackBar, private translate: TranslateService) { }

  ngOnInit() {
  }
  public save(){
    this.ApiService.updateCity(this.data.city).subscribe(
      (response)=>{
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
        this.dialogRef.close();
      }
    )
  }

}
