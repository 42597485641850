import {Component, Inject, OnInit} from '@angular/core';
import {Manufacturer, Product, Supplier, Unit} from '../model';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {ApiServiceService} from '../api-service.service';
import {FormControl} from '@angular/forms';
import {MainComponent} from '../main/main.component';
import {TranslateService} from '@ngx-translate/core';

class DialogData {
  public suppliers: Supplier[];
}

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss']
})
export class NewProductComponent implements OnInit {

public product: Product = {name: '', hasLager: true, hasMeasurumentExtra: false, showPrice: true,  id: 0, price: 0, amount: 0, loaded: true, stock: 1, image: '', measurementUnitName: '', measurementUnit: 0, description: '', manufacturerName: '', manufacturerId: 0, lager: null , lagerLimit : null };
public manufacturers: Manufacturer[];
public units: Unit[];
public manufacturerName = '';
public supplier: Supplier;
public  myControl = new FormControl();
public hasMeasurumentExtra = true;
  constructor(private translate: TranslateService, private snackBar: MatSnackBar ,private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<NewProductComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role === 2){
      // @ts-ignore
      this.product.supplier = this.supplier.id;
      this.product.supplierName = this.supplier.name;
    }
    this.apiService.getAllManufacturers().subscribe(
      (manufacturers: Manufacturer[]) => {
        this.manufacturers = manufacturers;
    },
    );
    this.apiService.getAllUnits().subscribe(
      (units: Unit[]) => {
        console.log(units);
        this.units = units;
      },
    );
  }

  public clickedExtra() {
    if (this.hasMeasurumentExtra == false) {
      this.hasMeasurumentExtra  = true;
    } else {
      this.hasMeasurumentExtra = false;
    }
  }

  public filteredUnits() {
    const units = [];

    if (this.product.measurementUnitName) {
      this.units.forEach((u) => {
        if (u.name == this.product.measurementUnitName) {
          units.push(u);
        }
      });
    }

    return units;
  }

  public findManufacturer() {
    let foundManufacturer: Manufacturer = {id: 0, name: ''};
    this.manufacturers.forEach((m) =>
    {
      if (m.name == this.product.manufacturerName) {
        foundManufacturer = m;
      }
    });

    if (foundManufacturer.name != '') {
      this.product.manufacturerId = foundManufacturer.id;
    }

  }
public onSelect($event){
  if($event.target.checked){
    $event.target.value = 0;
  }
}
  public findSupplier() {
    let foundSupplier: Supplier = {id: 0, name: ''};
    this.data.suppliers.forEach((s) =>
    {
      if (s.name == this.product.supplierName) {
        foundSupplier = s;
      }
    });

    if (foundSupplier.name != '') {
      // @ts-ignore
      this.product.supplier = foundSupplier.id;
    } else {
    }
  }

  public filterSuppliers() {

    const suppliers = [];
    let name = '';

    if (this.product && this.product.supplierName) {
       name = this.product.supplierName;
    }


    this.data.suppliers.forEach((s) => {
      if (s.name.toLowerCase().includes(name.toLowerCase())) {
        suppliers.push(s);
      }
    });



    return suppliers;
  }

  public displayFn(value) {
    if (value) {
      return  value.name;
    } else {
      return '';
    }

  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadProductImage(formData).subscribe(
      (data: {path, fullUrl}) => {
        this.product.image = data.fullUrl;
      });
  }

  public createProduct($event) {
    // check if manufacturer exits
    this.findSupplier();
    let foundManufacturer: Manufacturer = {id: 0, name: ''};
    this.manufacturers.forEach((m) =>
    {
      if (m.name == this.product.manufacturerName) {
        foundManufacturer = m;
      }
    });

    if (foundManufacturer.name != '') {
      this.product.manufacturerId = foundManufacturer.id;
    }
    if ((this.product.lager < 0 && this.product.lager != null) || (this.product.lagerLimit < 0 && this.product.lagerLimit != null)) {
      this.product.lager = null;
      this.product.lagerLimit = null;
    }

    if (this.product.measurementUnit != 18) {
      const index = this.units.findIndex((x) => x.id == this.product.measurementUnit);
      if (index != -1) {
        this.product.packetNumber = this.units[index].amount;
      } else {
      this.product.packetNumber = 0;
      }
    }

    this.apiService.createProduct(this.product).subscribe(
      (product: Product) => {
        this.product = product;
        if (this.product.active == 1){
          this.snackBar.open(this.translate.instant('productCreated'), "OK");
        } else {
          this.snackBar.open(this.translate.instant('productCreatedUnactive'), "OK");
        }


        this.dialogRef.close(this.product);
    },
      (err) => {
        this.main.autoLogin(err.status);
      }
    )
  }

  public onCancel($event) {
    this.dialogRef.close(null);
  }

  public onConfirm(event) {
    this.dialogRef.close(event);
  }


}
