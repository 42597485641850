import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer-big',
  templateUrl: './footer-big.component.html',
  styleUrls: ['./footer-big.component.scss']
})
export class FooterBigComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

}
