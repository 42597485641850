import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {NewsOrNotice, Supplier} from '../model';
import {StartComponent} from '../start/start.component';
import {DialogComponent} from '../dialog/dialog.component';
import {MatDialog, MatDialogModule} from '@angular/material';

@Component({
  selector: 'app-news-notice',
  templateUrl: './news-notice.component.html',
  styleUrls: ['./news-notice.component.scss'],
})
export class NewsNoticeComponent implements OnInit {
  public id: number;
  public type: number;
  public obj: NewsOrNotice;
  public loading = {main: true, other: true};
  public objects: NewsOrNotice[];
  public supplier: Supplier;

  constructor(private dialog: MatDialog, private router: Router, public start: StartComponent, private route: ActivatedRoute, private main: MainComponent, private translate: TranslateService, private apiService: ApiServiceService) {
  }

  public ngOnInit() {


    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role !== 3) {
      this.router.navigate(['main/start']);
    }

    this.route.params.subscribe((params) => {
      this.type = +params.type;
      this.id = +params.id;

      this.apiService.getNewsOrNotice(this.id, this.type).subscribe(
        (data: NewsOrNotice) => {
          this.obj = data;
          this.loading.main = false;
        },
        (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        },
      );

    });
  }

  public delete() {
    let text = '';
    if (this.type == 2) {
      text = 'areYouSureYouWantToDeleteNotice';
    } else if (this.type == 1) {
      text = 'areYouSureYouWantToDeleteNews';
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'actionButton', text: this.translate.instant(text)}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteNewsOrNotice(this.id, this.type).subscribe(
          (data) => {
            this.router.navigate(['main/list', this.type]);
          },
          (err) => {
            this.main.autoLogin(err.status);
          }
      );
  }
  });
  }

  public edit() {
    localStorage.setItem('newsType', String(this.type));
    localStorage.setItem('newsToEdit', JSON.stringify(this.obj));
    this.router.navigate(['main/specifics/edit']);
  }
}
