<div class="mainContainer">
  <div class="miniDiv">
    <div class="customerInfo withBorder">
      <div class="bigText">{{supplier.name}}</div>

      <div class="normalText" *ngIf="supplier.role == 2">{{supplier.address}} {{supplier.city}}</div>
    </div>
    <div class="customerInfo">
      <div class="normalText" *ngIf="supplier.role == 2"> {{'phone' | translate}}: {{supplier.phone}}
        <div class="normalText" *ngIf="supplier.role == 2"> {{'minimalCart' | translate}}
          : {{supplier.minDelivery}} {{supplier.currency}}
          <div class="normalText" *ngIf="supplier.role == 2"> {{'deliveryPrice' | translate}}
            : {{supplier.deliveryPrice}} {{supplier.currency}}
          </div>

          <div class="normalText"> {{'email' | translate }}: {{supplier.email}} </div>
        </div>
      </div>

    </div>
  </div>
  <div class="bigDiv">
    <div class="leftPart">
      <div class="profileTitle">{{'profile' | translate}}</div>
      <div *ngIf="mode == 'info'">
        <div class="sectionDiv">
          <div class="bigTitle">{{supplier.name}}</div>
          <div class="normalText" *ngIf="supplier.role == 2">{{supplier.address}} {{supplier.city}}</div>
        </div>
        <div class="sectionDiv" *ngIf="supplier.role == 2">
          <div class="normalText" *ngIf="supplier.role == 2"> {{'phone' | translate}}: {{supplier.phone}} </div>
        </div>
        <div class="sectionDiv noBorder">
          <div class="normalText"> {{'email' | translate }}: {{supplier.email}} </div>
        </div>
      </div>
      <div *ngIf="mode == 'edit'">
        <div *ngIf="supplier.role == 3">
          <div class="inputDiv">
            <div class="labelText">{{'nameOnly' | translate | uppercase}}</div>
            <input type="text" class="inputField" [(ngModel)]="supplier.name">
          </div>
          <div class="inputDiv">
            <div class="labelText">{{'email' | translate | uppercase}}</div>
            <input type="text" class="inputField" [(ngModel)]="supplier.email">
          </div>
        </div>
        <div *ngIf="supplier.role == 2">
          <div class="row">
            <div class="col-12 first">
              <div class="inputDiv">
                <mat-label class="labelText">{{'fullName' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.name">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'identifierLegal' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.identifier">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'pib' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.PIB">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'contactPerson' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.contactPerson">
              </div>

              <div class="inputDiv">
                <mat-label class="labelText">{{'phone' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.phone">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'centralPhone' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.centralPhone">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'deliveryTime' | translate | uppercase}}</mat-label>
                <div class="inputField">
                  <input class="timeInput" [format]="24" [ngxTimepicker]="startDeliveryPicker"
                         [(ngModel)]="startDelivery">
                  -
                  <input class="timeInput" [format]="24" [ngxTimepicker]="endDeliveryPicker" [(ngModel)]="endDelivery">

                </div>
                <ngx-material-timepicker #startDeliveryPicker></ngx-material-timepicker>
                <ngx-material-timepicker #endDeliveryPicker></ngx-material-timepicker>
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'orderTime' | translate | uppercase}}</mat-label>
                <div class="inputField">
                  <input class="timeInput" [format]="24" [ngxTimepicker]="startOrderPicker" [(ngModel)]="startOrder">
                  -
                  <input class="timeInput" [format]="24" [ngxTimepicker]="endOrderPicker" [(ngModel)]="endOrder">
                </div>
                <ngx-material-timepicker #startOrderPicker></ngx-material-timepicker>
                <ngx-material-timepicker #endOrderPicker></ngx-material-timepicker>
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'deliveryPrice' | translate | uppercase}}</mat-label>
                <div>
                  <input matInput type="number" required class="inputField forPrice for-inline"
                         [(ngModel)]="supplier.deliveryPrice"> <span
                  class="rsd-suffix" matSuffix> {{supplier.currency}} </span>
                </div>

              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'minDelivery' | translate | uppercase}}</mat-label>
                <div>
                  <input type="number" required class="inputField forPrice for-inline"
                         [(ngModel)]="supplier.minDelivery"> <span
                  class="rsd-suffix" matSuffix> {{supplier.currency}} </span>
                </div>
              </div>
            </div>
            <div class="col-12 second">
              <mat-label class="labelText" id="logo"
                         title="{{'clickOnImage' | translate}}">{{'logo' | translate | uppercase}}</mat-label>
              <div class="for-image" (click)="fileInput.click()">
                <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="supplier.logo != ''"
                     [src]="supplier.logo">
                <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="supplier.logo == ''"
                     src="assets/defaultImage.png">

              </div>
              <input type="file" class="fileInput" #fileInput (change)="onSelectFile($event.target.files)">
              <div class="inputDiv">
                <mat-label class="labelText">{{'address' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.address">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'postalCode' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.postalCode">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'website' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.link">
              </div>
              <div class="inputDiv">
                <mat-label class="labelText">{{'description' | translate | uppercase}}</mat-label>
                <input type="text" required class="inputField" [(ngModel)]="supplier.description">
              </div>
              <hr class="specific-line">
              <div class="inputDiv">
                <mat-label class="labelText">{{'email' | translate | uppercase}}</mat-label>
                <input type="email" required class="inputField" [(ngModel)]="supplier.email">
              </div>
            </div>
          </div>
        </div>

        <div class="forChangeButton">
          <div class="changeButton" [ngClass]="{'disabledChange' : requestedEditChange}"
               (click)="updateProfile()">{{'change' | translate}}
          </div>
          <img src="assets/mini_added.png" *ngIf="requestedEditChange" class="clickedChange">
        </div>
        <div *ngIf="requestedEditChange">
          <div class="descriptionText">
            {{'youReceivedCode' | translate}}
          </div>
          <div class="labelText">{{'code' | translate | uppercase}}</div>
          <input #codeForm type="text" class="inputField" [(ngModel)]="code">
          <div class="changeButton" (click)="updateProfileWithCode()">{{'change' | translate}}
          </div>
        </div>
      </div>
      <div>
      </div>
      <div *ngIf="mode == 'password'">
        <div class="inputDiv">
          <div class="labelText">{{'actualPassword' | translate | uppercase}}</div>
          <input type="password" class="inputField" [(ngModel)]="actualPassword">
        </div>
        <div>
          <div class="labelText">{{'newPassword' | translate | uppercase}}</div>
          <input type="password" class="inputField" [(ngModel)]="newPassword">
        </div>
        <div>
          <div class="labelText">{{'confirmPass' | translate | uppercase}}</div>
          <input type="password" class="inputField" [(ngModel)]="repeatPassword">
        </div>
        <div class="forChangeButton">
          <div class="changeButton" [ngClass]="{'disabledChange' : requestedPasswordChange}"
               (click)="changePassword()">{{'change' | translate}}
          </div>
          <img src="assets/mini_added.png" class="clickedChange" *ngIf="requestedPasswordChange">
        </div>
        <div *ngIf="requestedPasswordChange">
          <div class="descriptionText">
            {{'youReceivedCode' | translate}}
          </div>
          <div>
            <div class="labelText">{{'code' | translate | uppercase}}</div>
            <input #codeForm type="text" class="inputField" [(ngModel)]="code">
            <div class="changeButton" (click)="changePasswordWithCode()">{{'change' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightPart">
      <div class="box" (click)="changeMode('info')">
        <div
          [ngClass]="{'boxTextSelected' : mode == 'info', 'boxText' : mode != 'info'}">{{'profileInformation' | translate}}</div>
      </div>
      <div class="box" (click)="changeMode('edit')">
        <div
          [ngClass]="{'boxTextSelected' : mode == 'edit', 'boxText' : mode != 'edit'}">{{'editProfile' | translate}}</div>
      </div>
      <div class="box" (click)="changeMode('password')">
        <div
          [ngClass]="{'boxTextSelected' : mode == 'password', 'boxText' : mode != 'password'}">{{'changePassword' | translate}}</div>
      </div>
    </div>
  </div>
  <div class="footerBig">
    <app-footer-big></app-footer-big>
  </div>
</div>

