import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {

  public actualPassword = '';
  public newPassword = '';
  public repeatPassword = '';
  public code = '';
  public verification = false;
  @ViewChild('codeForm', {static: false}) public codeForm: ElementRef;

  constructor(private router: Router, private apiService: ApiServiceService, private main: MainComponent, private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  public ngOnInit() {
  }

  public changePass() {
    if (this.newPassword == '' || this.actualPassword == '' || this.repeatPassword == '') {
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFields'), 'OK', {duration: 3000});
    } else if (this.newPassword != this.repeatPassword) {
      this.snackBar.open(this.translate.instant('passwordsAreNotSame'), 'OK', {duration: 3000});
    } else if (this.actualPassword == this.newPassword) {
      this.snackBar.open(this.translate.instant('newPasswordCantBeOld'), 'OK', {duration: 3000});
    } else if (this.newPassword.length < 6) {
      this.snackBar.open(this.translate.instant('passwordMustBeAtLeast6'), 'OK', {duration: 3000});
    } else {
      this.main.text = 'verification';
      this.apiService.requestUpdate().subscribe(
        (data) => {
          this.main.text = 'verification';
          this.verification = true;
          setTimeout(() => { // wait 100ms to load this input
            this.codeForm.nativeElement.focus();
          }, 100);
        }, (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        },
      );
      this.verification = true;
      setTimeout(() => { // wait 100ms to load this input
        this.codeForm.nativeElement.focus();
      }, 100);
    }
  }

  public confirmCode() {
    this.apiService.changePassword(this.code, this.newPassword, this.actualPassword).subscribe(
      (data) => {
        this.router.navigate(['main/profile']);
      }, (err) => {
        if (err.status == 405) {
          this.snackBar.open(this.translate.instant('newPasswordCantBeOld'), 'OK', {duration: 3000});
        } else if (err.status == 406) {
          this.snackBar.open(this.translate.instant('oldPasswordNotCorrect'), 'OK', {duration: 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      },
    );
  }

}
