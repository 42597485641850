import { Component, OnInit } from '@angular/core';
import {ApiServiceService} from "../api-service.service";
import {MatDialog} from "@angular/material";
import {AdminsUpdateComponent} from "../admins-update/admins-update.component";
import {NewAdminComponent} from "../new-admin/new-admin.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  public admins = [];

  constructor(private ApiService: ApiServiceService , private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService) {

  }

  ngOnInit() {
    this.ApiService.getAdmins().subscribe(
      (response: object[]) => {
        this.admins = response;
      }
    );
  }
  public openAdminUpdate(admin){
    const dialogRef = this.dialog.open(AdminsUpdateComponent, {
      data: {admin, panelClass: 'warningDialog'}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  public openNewAdmin(){
    const dialogRef = this.dialog.open(NewAdminComponent, {
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  public deleteAdmin(admin){
    this.ApiService.deleteAdmin(admin).subscribe(
      (response)=>{
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
      }
    )
  }




}
