import {Component, Inject, OnInit} from '@angular/core';
import {Customer, Supplier} from '../model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {MainComponent} from '../main/main.component';
import {ApiServiceService} from '../api-service.service';

class DialogData {
  public supplier: Supplier;
}

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {
  public supplier: Supplier;
  public startDelivery = '09:00';
  public endDelivery = '22:00';
  public startOrder = '09:00';
  public endOrder = '22:00';

  constructor(private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar, private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<SupplierComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    this.startOrder = this.data.supplier.orderTime.split(' - ')[0];
    this.endOrder = this.data.supplier.orderTime.split(' - ')[1];
    this.startDelivery = this.data.supplier.deliveryTime.split(' - ')[0];
    this.endDelivery = this.data.supplier.deliveryTime.split(' - ')[1];

  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadImage(formData).subscribe(
      (data: { path, fullUrl }) => {
        this.supplier.logo = data.fullUrl;
      });
  }

  public update() {
    this.data.supplier.deliveryTime = this.startDelivery + ' - ' + this.endDelivery;
    this.data.supplier.orderTime = this.startOrder + ' - ' + this.endOrder;

    this.apiService.updateSupplier(this.data.supplier).subscribe(
      (supplier: Supplier) => {
        this.data.supplier = supplier;
        this.dialogRef.close(this.data.supplier);
      }, (err) => {
          this.main.autoLogin(err.status);
      },
    );
  }


}
