<div class="main-container">
  <div class="upper-container">
    <div class="dialog-title">
      <span *ngIf="data.customer.legalName != data.customer.objectName">
         {{data.customer.legalName}}, {{data.customer.objectName}}
      </span>
      <span *ngIf="data.customer.legalName == data.customer.objectName">
         {{data.customer.legalName}}
      </span>
    </div>
    <hr>
    <div class="dialog-body row">
      <div class="left-container col-12 col-md-6">
        <table class="dialog-table">
          <tr>
            <td class="left-cell">{{'serialNumber' | translate}}</td>
            <td class="right-cell">{{data.customer.id}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'fullNameCustomer' | translate}}</td>
            <td class="right-cell">{{data.customer.legalName}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'fullNameOfObject' | translate}}</td>
            <td class="right-cell">{{data.customer.objectName}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'address' | translate}}</td>
            <td class="right-cell">{{data.customer.address}} {{data.customer.city}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'email' | translate}}</td>
            <td class="right-cell">{{data.customer.email}}</td>
          </tr>

        </table>
      </div>
      <div class="right-container col-12 col-md-6">
        <table class="dialog-table">
          <tr>
            <td class="left-cell">{{'identifierLegal' | translate}}</td>
            <td class="right-cell">{{data.customer.identifier}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'objectPhone' | translate}}</td>
            <td class="right-cell">{{data.customer.objectPhone}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'purchasePhone' | translate}}</td>
            <td class="right-cell">{{data.customer.purchasePhone}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'numberOfOrders' | translate}}</td>
            <td class="right-cell">{{data.customer.numberOfOrders}}</td>
          </tr>
          <tr>
            <td class="left-cell">{{'registeredAt' | translate}}</td>
            <td class="right-cell">{{data.customer.createdAt | date : 'dd/MM/yy' : 'Europe/Belgrade'}}</td>
          </tr>

        </table>
      </div>
    </div>
    <hr>
    <div class="actions-track">
      <div class="block">
        <button mat-flat-button class="toggle-block float-left"  (click)="toggleBlock()" >
          <span *ngIf="data.customer.isBlocked && supplier.role == 2">{{'unblock' | translate}}</span>
          <span *ngIf="!data.customer.isBlocked">{{'block' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
