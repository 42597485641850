import {Location} from '@angular/common';
import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {Supplier} from '../model';
import {MessagingService} from '../shared/messaging.service';
import {DialogComponent} from '../dialog/dialog.component';

class DataService {
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewChecked {

  public text = null;
  public showNav = false;
  public supplier: Supplier;
  public changeText = false;
  constructor(public dialog: MatDialog, private messagingService: MessagingService,
              private location: Location, public router: Router, private snackBar: MatSnackBar,
              private apiService: ApiServiceService, private translate: TranslateService,
              ) { }

  public ngOnInit() {
    if (!localStorage.getItem('supplier')) {
      localStorage.setItem('url', this.location.path());
      this.router.navigate(['login']);
    }

    if (this.router.url === '/main/products') {
      this.text = 'products';
    } else if (this.router.url === '/main/actions') {
      this.text = 'actions';
    } else if (this.router.url === '/main/filter' || this.router.url === '/main/favorite/filter') {
      this.text = 'filter';
    } else if (this.router.url === '/main/cart') {
      this.text = 'cart';
    } else if (this.router.url === '/main/cart/confirm') {
      this.text = 'checkOrder';
    } else if (this.router.url === '/main/orders' || this.router.url === '/main/orders/filter') {
      this.text = 'ordersArchive';
    } else if (this.router.url === '/main/favorite') {
      this.text = 'favorites';
    } else if (this.router.url.startsWith('/main/order/')) {
      this.text = 'order';
    } else if (this.router.url.startsWith('/main/product/')) {
      this.text = 'product';
    } else if (this.router.url == '/main/profile') {
      this.text = 'profile';
    }  else if (this.router.url == '/main/profile/edit') {
      this.text = 'editProfile';
    } else if (this.router.url == '/main/password') {
      this.text = 'changePassword';
    } else if (this.router.url == '/main/suppliers') {
      this.text = 'suppliers';
    }

    this.supplier = JSON.parse(localStorage.getItem('supplier'));
  }

  public ngAfterViewChecked() {
    if (this.router.url === '/main/products') {
      if (!this.changeText) {
      this.text = 'products';
      }
    } else if (this.router.url === '/main/actions') {
      this.text = 'actions';
    } else if (this.router.url === '/main/filter' || this.router.url === '/main/favorite/filter') {
      this.text = 'filter';
    } else if (this.router.url === '/main/cart') {
      this.text = 'cart';
    } else if (this.router.url === '/main/cart/confirm') {
      this.text = 'checkOrder';
    } else if (this.router.url === '/main/orders' || this.router.url === '/main/orders/filter') {
      this.text = 'ordersArchive';
    } else if (this.router.url === '/main/favorite') {
      this.text = 'favorites';
    } else if (this.router.url.startsWith('/main/order/')) {
      this.text = 'order';
    } else if (this.router.url.startsWith('/main/product/')) {
      this.text = 'product';
    } else if (this.router.url == '/main/profile') {
      this.text = 'profile';
    }  else if (this.router.url == '/main/profile/edit') {
      this.text = 'editProfile';
    } else if (this.router.url == '/main/password') {
      this.text = 'changePassword';
    } else if (this.router.url == '/main/suppliers') {
      this.text = 'suppliers';
    }
  }

  public goBack() {
    this.location.back();
  }

  public onNavClick(address: string, id = -1, type = -1) {
    if (id === -1 && type === -1) {
      this.router.navigate([address]);
    }  else if (id === -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  public openActions() {

    const filter = {active: 1, action: 1, stock: 1, manufacturers: [0], suppliers: [0], name: ''};
    localStorage.setItem('actionFilter', JSON.stringify(filter));
    localStorage.setItem('productsTitle', 'actions');
    this.router.navigate(['main/actions']);

  }

  public logOut() {
    localStorage.removeItem('supplier');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText');
    this.router.navigate(['login']);
  }

  public  autoLogin(status) {
    if (status == 401) {
      if (this.supplier.rememberMe === true && this.supplier.rememberToken !== null) {
        this.apiService.loginWithToken(this.supplier).subscribe(
          (data: {jwt}) => {
            localStorage.setItem('JWT', data.jwt);
            if (localStorage.getItem('autoLogin')) {
              location.reload();
              localStorage.removeItem('autoLogin');
            }
          },
          (error) => {
            this.snackBar.open(this.translate.instant('yourSessionTimedOut'), 'OK');
            localStorage.removeItem('supplier');
            this.router.navigate(['login']);
          },
        );
      } else {
        this.snackBar.open(this.translate.instant('yourSessionTimedOut'), 'OK');
        localStorage.removeItem('supplier');
        this.router.navigate(['login']);
      }
    } else {
      this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK');

    }
  }

  public findIcon() {
    if (this.router.url == '/main/profile') {
      return 'edit';
    } else {
      return 'home';
    }
  }

  public editProfile() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {type: 'chooseEdit'}, panelClass : 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'edit') {
          this.router.navigate(['main/profile/edit']);
        } else if (result == 'password') {
          this.router.navigate(['main/password']);
        }
      }
    });
  }

}
