<div class="main-container" (window:resize)="onResized()">
  <div class="section-header" [ngClass]="{'smaller-header' : !showHeader}">
    <div class="section-hide-show" (click)="showHeader = !showHeader">
      <span [ngClass]="{'up-arrow' : !showHeader, 'down-arrow' : showHeader}"><mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div class="small-section-title" [ngClass]="{'hidden-small-title' : showHeader}">
      <img src="assets/cart_dollar.png" class="section-icon-small">  {{'productHistory' | translate}}
    </div>
    <div class="section-header-inner" [ngClass]="{'hide-inner' : !showHeader}">
      <div class="section-title">
        <div class="inner-section-title">
          <img src="assets/cart_dollar.png" class="section-icon">  {{'productHistory' | translate}}
        </div>
      </div>
      <div class="section-track">
        <div style="display: flex">
        <div class="section-item" [matMenuTriggerFor]="date">
          <span *ngIf="showText">{{'date' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>date_range</mat-icon></span>
        </div>
          <div class="section-item" [matMenuTriggerFor]="supplierMenu" *ngIf="supplier.role != 2">
            <span *ngIf="showText">{{'supplier' | translate}} </span>
            <span *ngIf="!showText"><mat-icon>local_shipping</mat-icon></span>

        </div>

        </div>

        <!--        <div class="section-item" [matMenuTriggerFor]="active">-->
        <!--          <span *ngIf="showText">{{'delivery' | translate}} </span>-->
        <!--          <span *ngIf="!showText"><mat-icon>visibility</mat-icon></span>-->
        <!--        </div>-->
        <div class="search-item">
          <div class="search-left" (click)="page=0; getHistory()">
            <span *ngIf="showText">{{'search' | translate}} </span>
            <span *ngIf="!showText"><mat-icon>search</mat-icon></span>
          </div>
          <div class="search-right" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-menu #supplierMenu="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-select  class="section-select" *ngIf="supplier.role == 3" [(ngModel)]="filter.supplierId" placeholder="{{'supplier' | translate}}">
        <mat-option *ngFor="let c of suppliers" [value]="c.id">{{c.name}}</mat-option>
      </mat-select>
    </div>
  </mat-menu>
  <mat-menu #date="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'dateFrom' | translate}}"
               (dateChange)="filter.startDate = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="dateTo" placeholder="{{'dateTo' | translate}}"
               (dateChange)="filter.endDate = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-menu>


  <div class="main-body" [ngClass]="{'main-body-higher' : !showHeader}">
    <div class="chips-list">
      <mat-chip-list>
        <mat-chip *ngIf="filter.startDate != -1">{{'dateFrom' | translate}}: {{filter.startDate | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.startDate = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.endDate != -1">{{'dateTo' | translate}}: {{filter.endDate | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.endDate = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="supplier.role == 3">{{'supplier' | translate}}: {{findSupplier()}}
          <mat-icon class="chips-icon" (click)="filter.supplierId = 0">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="table-container">
      <ngx-datatable
        class="material table-list"
        infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]=true
        [infiniteScrollThrottle]="150"
        [rows]="products"
        [columnMode]="'force'"
        [headerHeight]="50"
        [scrollbarH]="true"
        [messages]="my_messages"
        [loadingIndicator]="loading"
        [rowHeight]="50">
        <ngx-datatable-column name="Id" sortable="false" prop="id" width="50">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            {{row.product?.id}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" sortable="false" prop="name" width="50">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <img class="img" [src]="row.product?.image">
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'productName' | translate}}" sortable="false" prop="name" width="50">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            {{row.product?.name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'count' | translate}}" sortable="false" prop="count" width="50">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <div class="big-footer" *ngIf="!loading" [ngClass]="{'absolute-footer' : products.length <= 5}">
    <app-footer-big></app-footer-big>
  </div>
</div>
