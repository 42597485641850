import {Location} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {ContactComment} from '../model';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  public loggedIn = false;
  public clickedConfirm = false;
  public comment: ContactComment = {name: '', email: '', comment: ''};
  constructor(private translate: TranslateService, private apiService: ApiServiceService, private snackBar: MatSnackBar, private router: Router, private location: Location, public main: MainComponent) { }

  public ngOnInit() {
    if (localStorage.getItem('supplier')) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  }

  public onNavClick(address: string, id = -1, type = -1) {
    if (id === -1 && type === -1) {
      this.router.navigate([address]);
    }  else if (id === -1) {
      this.router.navigate([address, type]);
    } else {
      this.router.navigate([address, id, type]);
    }

  }

  public logOut() {
    localStorage.removeItem('supplier');
    localStorage.removeItem('cart');
    localStorage.removeItem('searchText');
    this.router.navigate(['login']);
  }

  public openFavorites() {
    this.main.text = 'favorites';
    this.router.navigate(['main/favorite']);
  }

  public openCart() {
    this.main.text = 'cart';
    this.router.navigate(['main/cart']);
  }

  public goBack() {
    this.location.back();
  }

  public emailIsValid () {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.comment.email);
  }

  public sendComment() {
    this.clickedConfirm = true;
    if (this.comment.name == '' || this.comment.email == '' || this.comment.comment == '') {
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFields'), 'OK', {duration: 3000});
    } else if (!this.emailIsValid()) {
      this.snackBar.open(this.translate.instant('emailNotValid'), 'OK', {duration: 3000});
    } else
     {
      this.apiService.addComment(this.comment).subscribe(
        (data) => {
          this.clickedConfirm = false;
          this.comment =  {name: '', email: '', comment: ''};
          this.snackBar.open(this.translate.instant('commentSent'), 'OK', {duration: 3000});
        },
        (err) => {
          this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});

        },
      );
    }
  }

}
