
<div class="container">
  <div class="row">
    <div class="col-12">
      <mat-form-field class="example-full-width">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Name" type="text" [(ngModel)]="city.name" >
      </mat-form-field>
      <br>

      <br><br>

      <button mat-raised-button color="primary" (click)="create()">Sačuvaj izmene</button>
    </div>
  </div>
</div>
