<div class="mainContainer">
  <div class="edit" *ngIf="!verification">
    <div class="labelText">{{'actualPassword' | translate | uppercase}}</div>
    <input type="password" class="inputField" [(ngModel)]="actualPassword">
    <div class="labelText">{{'newPassword' | translate | uppercase}}</div>
    <input type="password" class="inputField" [(ngModel)]="newPassword">
    <div class="labelText">{{'confirmPass' | translate | uppercase}}</div>
    <input type="password" class="inputField" [(ngModel)]="repeatPassword">
    <div class="forChangeButton">
      <div class="changeButton" (click)="changePass();">{{'next' | translate | uppercase}}
      </div>

    </div>
  </div>
  <div class="verification" *ngIf="verification">
    <div class="verificationText">{{'verificationText' | translate}}</div>
    <div class="labelText">{{'code' | translate | uppercase}}</div>
    <input type="text" #codeForm class="inputField" [(ngModel)]="code">
    <div class="forChangeButton">
      <div class="changeButton" (click)="confirmCode()">{{'next' | translate | uppercase}}
      </div>

    </div>
  </div>
</div>
