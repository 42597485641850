<div class="bigContainer" >
  <div class="leftContainer">
    <div *ngIf="mode == 'new'">
      <div class="news" *ngIf="type == 1"> {{'newNews' | translate}}</div>
      <div class="news" *ngIf="type == 2"> {{'newNotification' | translate}}</div>
    </div>

    <div *ngIf="mode == 'edit'">
      <div class="news" *ngIf="type == 1"> {{'editNews' | translate}}</div>
      <div class="news" *ngIf="type == 2"> {{'editNotifications' | translate}}</div>
    </div>

    <div *ngIf="type !=3">
      <img class="image" (click)="fileInput.click()" src="/assets/defaultImage.png"  [ngClass]="{'invisibleImage' : obj.loaded}">
      <img class="image" (click)="fileInput.click()" [src]="obj.image" (load)="obj.loaded = true" (error)="obj.image='assets/defaultImage.png'" [ngClass]="{'invisibleImage' : !obj.loaded}">
    </div>
    <input type="file" class="fileInput" #fileInput (change)="onSelectFile($event.target.files)">
    <div class="text" >
      <div class="title">
       <mat-form-field>
         <input type="text" matInput placeholder="{{'title' | translate}}" [(ngModel)]="obj.title">
       </mat-form-field>
      </div>
      <div class="subtitle">
        <mat-form-field>
          <input type="text" matInput placeholder="{{'subtitle' | translate}}" [(ngModel)]="obj.subtitle">
        </mat-form-field>
      </div>
      <div class="body">
        <ckeditor [editor]="Editor" [(ngModel)]="obj.body" [data]="obj.body"></ckeditor>
      </div>
    </div>
  </div>
  <div class="rightContainer">
    <div class="save-button" (click)="save()">
      {{'save' | translate}}
    </div>
  </div>

</div>

<div class="footerBig" >
  <app-footer-big></app-footer-big>
</div>

