import {Component, Inject, OnInit} from '@angular/core';
import {BlockCustomer, Customer, Supplier} from '../model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {MainComponent} from '../main/main.component';
import {ApiServiceService} from '../api-service.service';

class DialogData {
  public list: BlockCustomer[];
  public customerId: number;
}

@Component({
  selector: 'app-list-of-blocks',
  templateUrl: './list-of-blocks.component.html',
  styleUrls: ['./list-of-blocks.component.scss']
})
export class ListOfBlocksComponent implements OnInit {

  public toAdd = false;
  public suppliers: Supplier[];
  public selectedSupplier = 0;

  constructor(private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar, private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<ListOfBlocksComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  public ngOnInit() {
    this.apiService.getAllSuppliers().subscribe(
      (data: Supplier[]) => {
        this.suppliers = data;
      },
    );
  }

  public toggleBlock(customerId, supplierId, blockId) {
    this.apiService.toggleBlock(supplierId, customerId).subscribe(
      (data) => {
        const index = this.data.list.findIndex((x) => x.id == blockId);
        if (index != -1) {
          this.data.list.splice(index, 1);
        }
      }, (err) => {
        this.main.autoLogin(err.status);
      });
  }

  public addNew() {
    this.apiService.toggleBlock(this.selectedSupplier, this.data.customerId).subscribe(
      (data: BlockCustomer) => {
        this.data.list.push(data);
        this.selectedSupplier = 0;
        this.toAdd = false;
      }, (err) => {
        this.main.autoLogin(err.status);
      });
  }

}
