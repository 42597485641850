<div class="main-container" (scroll)="scrollWindow($event)" (window:resize)="onResized()">
  <div class="section-header" [ngClass]="{'smaller-header' : !showHeader}">
    <div class="section-hide-show" (click)="showHeader = !showHeader">
      <span [ngClass]="{'up-arrow' : !showHeader, 'down-arrow' : showHeader}"><mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div class="small-section-title" [ngClass]="{'hidden-small-title' : showHeader}">
      <img src="assets/dollar.png" class="section-icon-small">  {{'rabat' | translate}} ({{total}})
    </div>
    <div class="section-header-inner" [ngClass]="{'hide-inner' : !showHeader}">
      <div class="section-title">
        <div class="inner-section-title">
          <img src="assets/dollar.png" class="section-icon">  {{'rabat' | translate}} ({{total}})
        </div>
      </div>
      <div class="section-track">
        <div class="section-item" [matMenuTriggerFor]="searchPer">
          <span *ngIf="showText">{{'searchPer' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>settings</mat-icon></span>
        </div>
        <div class="section-item" [matMenuTriggerFor]="date">
          <span *ngIf="showText">{{'date' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>date_range</mat-icon></span>
        </div>
        <div class="section-item"  [matMenuTriggerFor]="deliveries">
          <span *ngIf="showText">{{'discount' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>euro_symbol</mat-icon></span>
        </div>
        <div class="search-item">
          <div class="search-left" (click)="page=0; filterRabats()">
            <span *ngIf="showText">{{'search' | translate}} </span>
            <span *ngIf="!showText"><mat-icon>search</mat-icon></span>
          </div>
          <div class="search-right" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-menu #searchPer="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.id" placeholder="ID">
      </mat-form-field>
      {{'customers' | translate}}:
      <mat-select class="suppliers-select" multiple (selectionChange)="checkCustomers()" [(ngModel)]="filter.customer" placeholder="{{'customer' | translate}}">
        <mat-option [value]="0" (click)="resetCustomers()">{{'allCustomers' | translate}}</mat-option>
        <mat-option *ngFor="let c of customers" [value]="c.id">{{c.object_name}}</mat-option>
      </mat-select>
    </div>
  </mat-menu>
  <mat-menu #date="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'dateFrom' | translate}}"
               (dateChange)="filter.dateFrom = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="dateTo" placeholder="{{'dateTo' | translate}}"
               (dateChange)="filter.dateTo = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #deliveries="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      {{'condition' | translate}}:
      <mat-select class="suppliers-select" [(ngModel)]="filter.condition" placeholder="{{'active' | translate}}">
        <mat-option [value]="-1">{{'all' | translate}}</mat-option>
        <mat-option [value]="0">{{'noCondition' | translate}}</mat-option>
        <mat-option [value]="1">{{'withCondition' | translate}}</mat-option>

      </mat-select>

      {{'discountType' | translate}}:
      <mat-select (selectionChange)="validateDiscountType()" [(ngModel)]="filter.discountType" placeholder="{{'active' | translate}}">
        <mat-option [value]="-1">{{'all' | translate}}</mat-option>
        <mat-option [value]="0">{{'price' | translate}}</mat-option>
        <mat-option [value]="1">{{'percent' | translate}}</mat-option>
      </mat-select>
      <hr *ngIf="filter.discountType != -1">
      <mat-form-field class="section-form" *ngIf="filter.discountType == 0">
        <input matInput type="number" [(ngModel)]="filter.priceMin" min="0"
               placeholder="{{'priceMin' | translate}}">
        <span matSuffix>RSD</span>
      </mat-form-field>
      <mat-form-field class="section-form" *ngIf="filter.discountType == 0">
        <input matInput type="number" [(ngModel)]="filter.priceMax" min="0"
               placeholder="{{'priceMax' | translate}}">
        <span matSuffix>RSD</span>
      </mat-form-field>

      <mat-form-field class="section-form" *ngIf="filter.discountType == 1">
        <input matInput type="number" [(ngModel)]="filter.percentMin" min="0" max="100"
               placeholder="{{'percentMin' | translate}}">
        <span matSuffix>%</span>
      </mat-form-field>
      <mat-form-field class="section-form" *ngIf="filter.discountType == 1">
        <input matInput type="number" [(ngModel)]="filter.percentMax" min="0" max="100"
               placeholder="{{'percentMax' | translate}}">
        <span matSuffix>%</span>
      </mat-form-field>
    </div>
  </mat-menu>
  <div class="main-body" [ngClass]="{'main-body-higher' : !showHeader}">
    <div class="chips-list">
      <mat-chip-list>
        <mat-chip *ngIf="filter.id != ''">ID: {{filter.id}}
          <mat-icon class="chips-icon" (click)="filter.id = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.customer.length != customers.length">{{'customers' | translate}}:
          <div class="customerChip">
            <span *ngIf="filter.customer.length == 1 && filter.customer.includes(0)">
               {{'allCustomers' | translate}}
            </span>
            <span *ngIf="!filter.customer.includes(0)">
          <span *ngFor="let c of filter.customer; let i = index">
            {{findCustomerName(c)}} <span *ngIf="i < filter.customer.length">,</span>
          </span>
            </span>
          </div>
          <mat-icon class="chips-icon" (click)="resetFilterCustomer()">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.dateFrom != -1">{{'dateFrom' | translate}}: {{filter.dateFrom | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.dateFrom = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.dateTo != -1">{{'dateTo' | translate}}: {{filter.dateTo | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.dateTo = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.condition != -1">{{'condition' | translate}}:
           <span *ngIf="filter.condition == 0"> {{'noCondition' | translate}}</span>
           <span *ngIf="filter.condition == 1"> {{'withCondition' | translate}}</span>
          <mat-icon class="chips-icon" (click)="filter.condition = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.discountType != -1">{{'discountType' | translate}}:
           <span *ngIf="filter.discountType == 0"> {{'price' | translate}}</span>
           <span *ngIf="filter.discountType == 1"> {{'percent' | translate}}</span>
          <mat-icon class="chips-icon" (click)="filter.discountType = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.priceMin != 0">{{'priceMin' | translate}}: {{filter.priceMin}}
          <mat-icon class="chips-icon" (click)="filter.priceMin = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.priceMax != 0">{{'priceMax' | translate}}: {{filter.priceMax}}
          <mat-icon class="chips-icon" (click)="filter.priceMax = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.percentMin != 0 ">{{'percentMin' | translate}}: {{filter.percentMin}}
          <mat-icon class="chips-icon" (click)="filter.percentMin = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.percentMax != 0">{{'percentMax' | translate}}: {{filter.percentMax}}
          <mat-icon class="chips-icon" (click)="filter.percentMax = 0">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div class="action-list">
      <div class="action-list-inner" >
        <div class="button-action delete" (click)="buttonAction(3)" *ngIf="selected.length > 0">{{'deleteSelected' | translate}} ({{selected.length}})</div>
        <div class="button-action add" (click)="addNew()">{{'addNew' | translate}}</div>
      </div>
    </div>
    <div class="table-container">
      <ngx-datatable
        class="material table-list"
        infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]=true
        [infiniteScrollThrottle]="150"
        (scrolled)="onScroll()"
        [rows]="rabats"
        [rowClass]="getRowClass"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [scrollbarH]="true"
        [selected]="selected"
        [loadingIndicator]="loading"
        [selectionType]="'checkbox'"
        (activate)='onActivate($event)'
        [rowHeight]="50"
        (sort)="onSort($event)"
        (select)="onSelect($event)">
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true">
        </ngx-datatable-column>
        <ngx-datatable-column name="Id" sortable="false" prop="id" [width]="10">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'product' | translate}}" sortable="false" prop="product">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value.name}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'supplier' | translate}}" sortable="false" *ngIf="supplier.role === 3" prop="supplier">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'customer' | translate}}" sortable="false" prop="customer">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'price' | translate}}" sortable="false" prop="newPrice">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
            <span *ngIf="value">{{value}} {{row.product.currency}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'conditionAmount' | translate}}" sortable="false" prop="conditionAmount">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="!value"><mat-icon>clear</mat-icon></span>
            <span *ngIf="value">{{value}} x {{row.product.measurementUnit}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'percent' | translate}}" sortable="false" prop="percent">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="!value">0</span>
            <span *ngIf="value">{{value}}</span>
            %
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'date' | translate}}" sortable="false" prop="createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date : 'dd/MM/yy' : 'Europe/Belgrade'}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
  <div class="big-footer">
    <app-footer-big></app-footer-big>
  </div>
</div>
