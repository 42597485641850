import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {Cart, CartProduct, Product, Supplier} from '../model';

class DialogData {
  public order: Cart;
}

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  // tslint:disable-next-line:object-literal-sort-keys
  styleUrls: ['./order-edit.component.scss'],
})
export class OrderEditComponent implements OnInit {

  public editableOrder: Cart = {suppliers: [], products: [], deliveryPrice: 0, price: 0, currency: 'RSD'};
  public supplier: Supplier;
  public loadingEmail = false;
  public productToAdd: CartProduct = {name: '', priceInt: 0, chosenAmount: 0, product: {name: '', supplierName: '', measurementUnit: 0, manufacturerName: '', new: true}, new: true};
  public myProducts: Product[] = [];
  public chosenProduct: Product;
  // tslint:disable-next-line:max-line-length
  constructor(private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar , private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<OrderEditComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role == 2) {
      const suppliersProducts = [];
      this.data.order.products.forEach((product) => {
        if (product.product.supplier.id == this.supplier.id) {
          suppliersProducts.push(product);
        }
      });
      this.data.order.products = [...suppliersProducts];

      const supplierOnly = [];
      this.data.order.suppliers.forEach((supplier) => {
        if (supplier.supplierId == this.supplier.id) {
          supplierOnly.push(supplier);
        }
      });
      this.data.order.suppliers = [...supplierOnly];

      this.editableOrder.suppliers = this.data.order.suppliers;
      this.editableOrder.products = this.data.order.products;
      this.editableOrder.id = this.data.order.id;

      this.editableOrder.deliveryPrice = this.data.order.deliveryPrice;
      this.editableOrder.price = this.data.order.price;
      this.editableOrder.currency = this.data.order.currency;

      if (!this.data.order.suppliers[0].vehicle_number && localStorage.getItem('orderExtraFields')) {
        this.editableOrder.vehicle_number = JSON.parse(localStorage.getItem('orderExtraFields')).vehicle_number;
      } else {
      this.editableOrder.vehicle_number = this.data.order.suppliers[0].vehicle_number;
      }

      if (!this.data.order.suppliers[0].number_of_sendings && localStorage.getItem('orderExtraFields')) {
        this.editableOrder.number_of_sendings = JSON.parse(localStorage.getItem('orderExtraFields')).number_of_sendings;
      } else {
        this.editableOrder.number_of_sendings = this.data.order.suppliers[0].number_of_sendings;
      }

      if (!this.data.order.suppliers[0].order_date && localStorage.getItem('orderExtraFields')) {
        this.editableOrder.order_date = JSON.parse(localStorage.getItem('orderExtraFields')).order_date;
      } else {
        this.editableOrder.order_date = this.data.order.suppliers[0].order_date;
      }

      if (!this.data.order.suppliers[0].account_number && localStorage.getItem('orderExtraFields')) {
        this.editableOrder.account_number = JSON.parse(localStorage.getItem('orderExtraFields')).account_number;
      } else {
        this.editableOrder.account_number = this.data.order.suppliers[0].account_number;
      }

      if (!this.data.order.suppliers[0].suppliedBy && localStorage.getItem('orderExtraFields')) {
        this.editableOrder.suppliedBy = JSON.parse(localStorage.getItem('orderExtraFields')).suppliedBy;
      } else {
        this.editableOrder.suppliedBy = this.data.order.suppliers[0].suppliedBy;
      }

      this.apiService.getMyProducts().subscribe(
        (products: Product[]) => {
          this.myProducts = products;
          if (this.myProducts.length >= 1) {
          this.productToAdd.product = this.myProducts[0];
          this.productToAdd.new = true;
          }
        },
      );

    }

  }

  public save() {
    const extraFields: {number_of_sendings?, order_date?, account_number?, vehicle_number?, suppliedBy?} = { };

    if (this.editableOrder.number_of_sendings) {
      extraFields.number_of_sendings = this.editableOrder.number_of_sendings;
    }

    if (this.editableOrder.order_date) {
      extraFields.order_date = this.editableOrder.order_date;
    }

    if (this.editableOrder.account_number) {
      extraFields.account_number = this.editableOrder.account_number;
    }

    if (this.editableOrder.vehicle_number) {
      extraFields.vehicle_number = this.editableOrder.vehicle_number;
    }

    if (this.editableOrder.suppliedBy) {
      extraFields.suppliedBy = this.editableOrder.suppliedBy;
    }

    localStorage.setItem('orderExtraFields', JSON.stringify(extraFields));

    this.apiService.updateOrder(this.editableOrder).subscribe(
      (order: Cart) => {
        this.data.order = order;
        const suppliersProducts = [];
        this.data.order.products.forEach((product) => {
          if (product.product.supplier.id == this.supplier.id) {
            suppliersProducts.push(product);
          }
        });
        this.data.order.products = [...suppliersProducts];

        const supplierOnly = [];
        this.data.order.suppliers.forEach((supplier) => {
          if (supplier.supplierId == this.supplier.id) {
            supplierOnly.push(supplier);
          }
        });
        this.data.order.suppliers = [...supplierOnly];
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
        this.dialogRef.close(this.data.order);
      }, (err) => {
        this.snackBar.open(this.translate.instant('thereWasAnError'), 'OK', {duration: 3000});
      });
  }

  public changedProduct(event) {
    console.log(this.data.order.products);
    this.productToAdd.product = event;
    console.log(this.data.order.products);
  }

  public removeProduct(id) {
    const index = this.editableOrder.products.findIndex((x) => x.id == id);
    if (index != -1) {
      this.editableOrder.products.splice(index, 1);
    }

  }

  public calculatePrice(event, product) {
    if (typeof event === 'number') {
    product.priceInt = event * product.product.price;
    }
  }

  public getOrderPrice() {
    let price = 0;
    this.editableOrder.products.forEach((p) => {
      price = price + p.priceInt;
    });

    return price;
  }

  public add() {
    if (this.productToAdd.product.name != '') {
      this.editableOrder.products.push(JSON.parse(JSON.stringify(this.productToAdd))); // hard fix: bc change value applies to item even after being pushed into array.
      if (this.myProducts.length >= 1) {
        // this.productToAdd.product = this.myProducts[0];
        this.productToAdd.new = true;
      } else {
        this.productToAdd = {
          name: '',
          priceInt: 0,
          chosenAmount: 0,
          new: true,
          product: {name: '', supplierName: '', measurementUnit: 0, manufacturerName: ''},
        };
      }
      console.log(this.data.order.products);
    } else {
      this.snackBar.open(this.translate.instant('pleaseChooseProduct'), 'OK', {duration: 3000});
    }
  }

}
