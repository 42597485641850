<div class="mainContainer">
  <div class="upperHeader">
  </div>
  <div class="header">
    <img src="/assets/arrow.png" class="backArrow" (click)="goBack()">
    <div class="headerTitle">{{'contact' | translate}}</div>
  </div>
  <div class="bigHeader">
    <img class="logoLong" src="/assets/enabavka_long.png" (click)="onNavClick('main/start')">

    <div class="bigMenu" *ngIf="loggedIn">
      <div class="bigLink" (click)="onNavClick('main/start')">{{'home' | translate}}</div>
      <div class="bigLink"
           (click)="onNavClick('main/orders');this.main.text = 'ordersArchive'">{{'ordersArchive' | translate}}</div>
      <div class="bigLink" (click)="openFavorites()">{{'favoriteProducts' | translate}}</div>
      <div class="bigLink">{{'help' | translate}}</div>
      <div class="bigLink" (click)="logOut()">{{'logOut' | translate}}</div>
    </div>

  </div>
  <div class="miniDiv">
    <div class="forLogo">
      <img src="assets/logo-new.jpeg" class="logo">
    </div>
    <div class="sectionDiv">
      <div class="forText">
        <div class="textBig">{{'aboutUs' | translate}}</div>
        <div class="textNormal textPar">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
          rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
          dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
          sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
          magnam aliquam quaerat voluptatem.
        </div>
      </div>
    </div>
    <div class="sectionDiv">
      <div class="textBig">E-nabavka</div>
      <div class="textNormal">Beograd</div>
      <div class="textNormal">+381 11 12 34 567</div>
      <div class="textNormal">kontakt@enabavka.rs</div>
    </div>

  </div>
  <div class="bigDiv">
    <div class="leftPart">
      <div class="bigTitle">{{'contact' | translate}}</div>
      <div class="forImage">
        <img src="assets/logo-new.jpeg" class="logoImage">
      </div>
      <div class="forText">
        <div class="bigTitle">{{'aboutUs' | translate}}</div>
        <div class="textNormal textPar">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
          rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
          dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
          sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore
          magnam aliquam quaerat voluptatem.
        </div>
      </div>
      <div class="forForm">
        <div class="upperForm">
          <div class="name">
            <div class="formLabel">{{'name' | translate}}</div>
            <input type="text" class="inputField" [(ngModel)]="comment.name">
          </div>
          <div class="email">
            <div class="formLabel">{{'email' | translate}}</div>
            <input type="email" class="inputField" [(ngModel)]="comment.email">
          </div>
        </div>
      </div>
      <div class="forBox">
        <div class="formLabel">{{'comment' | translate}} </div>
        <textarea class="textBox" [(ngModel)]="comment.comment">
        </textarea>

        <div class="sendButton" (click)="sendComment()">
          <span *ngIf="!clickedConfirm"> {{'send' | translate | uppercase}} </span>
          <mat-spinner *ngIf="clickedConfirm" diameter="30" class="spinnerConfirm" color="white"></mat-spinner>
        </div>
      </div>
    </div>
    <div class="rightPart">
      <div class="sectionDiv">
        <div class="middleTitle">{{'contactInfo' | translate}}</div>
        <div class="bigText">E-nabavka</div>
        <div class="bigText">Beograd</div>
        <div class="bigText">+381 11 12 34 567</div>
        <div class="bigText">kontakt@enabavka.rs</div>
      </div>
    </div>
  </div>
</div>
<div class="footerBig">
  <app-footer-big></app-footer-big>
</div>
