import { Component, OnInit } from '@angular/core';
import {ApiServiceService} from "../api-service.service";
import {City} from "../model";
import {MatDialog} from "@angular/material/dialog";
import {AdminsUpdateComponent} from "../admins-update/admins-update.component";
import {UpdateCityComponent} from "../update-city/update-city.component";
import {CreateCityComponent} from "../create-city/create-city.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {
  public cities: City[] = [];
  constructor(private ServiceApi: ApiServiceService, private dialog: MatDialog, private snackBar: MatSnackBar, private translate: TranslateService) { }

  ngOnInit() {
    this.getCities();
  }
  public updateCity(city){
    const dialogRef = this.dialog.open(UpdateCityComponent, {
      data: {city, panelClass: 'warningDialog'}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  public createCity(){
    const dialogRef = this.dialog.open(CreateCityComponent, {
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  public deleteCity(city){
    this.ServiceApi.deleteCity(city).subscribe(
      (response) => {
        this.snackBar.open(this.translate.instant('saved'), 'OK', {duration: 3000});
      }
    )
  }



  public getCities(){
    this.ServiceApi.getCities().subscribe(
      (response: City[]) => {
        this.cities = response;
      }
    )
  }
}
