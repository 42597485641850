<div class="mainContainer">
  <div *ngIf="!verification" class="edit"  [ngClass]="{'fix-edit' : supplier.role == 3}" >
    <div *ngIf="supplier.role == 3">
      <div class="inputDiv">
        <div class="labelText">{{'nameOnly' | translate | uppercase}}</div>
        <input type="text" class="inputField" [(ngModel)]="supplier.name">
      </div>
      <div class="inputDiv">
        <div class="labelText">{{'email' | translate | uppercase}}</div>
        <input type="text" class="inputField" [(ngModel)]="supplier.email">
      </div>
    </div>
    <div *ngIf="supplier.role == 2">
      <div class="row">
        <div class="col-12 first">
          <div class="inputDiv">
            <mat-label class="labelText">{{'fullName' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.name">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'identifierLegal' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.identifier">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'pib' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.PIB">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'contactPerson' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.contactPerson">
          </div>

          <div class="inputDiv">
            <mat-label class="labelText">{{'phone' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.phone">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'centralPhone' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.centralPhone">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'deliveryTime' | translate | uppercase}}</mat-label>
            <div class="inputField2">
              <input class="timeInput" [format]="24" [ngxTimepicker]="startDeliveryPicker"
                     [(ngModel)]="startDelivery">
              -
              <input class="timeInput" [format]="24" [ngxTimepicker]="endDeliveryPicker" [(ngModel)]="endDelivery">

            </div>
            <ngx-material-timepicker #startDeliveryPicker></ngx-material-timepicker>
            <ngx-material-timepicker #endDeliveryPicker></ngx-material-timepicker>
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'orderTime' | translate | uppercase}}</mat-label>
            <div class="inputField2">
              <input class="timeInput" [format]="24" [ngxTimepicker]="startOrderPicker" [(ngModel)]="startOrder">
              -
              <input class="timeInput" [format]="24" [ngxTimepicker]="endOrderPicker" [(ngModel)]="endOrder">
            </div>
            <ngx-material-timepicker #startOrderPicker></ngx-material-timepicker>
            <ngx-material-timepicker #endOrderPicker></ngx-material-timepicker>
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'deliveryPrice' | translate | uppercase}}</mat-label>
            <div>
              <input matInput type="number" required class="inputField forPrice for-inline"
                     [(ngModel)]="supplier.deliveryPrice">
            </div>

          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'minDelivery' | translate | uppercase}}</mat-label>
            <div>
              <input type="number" required class="inputField forPrice for-inline"
                     [(ngModel)]="supplier.minDelivery">
            </div>
          </div>
        </div>
        <div class="col-12 second">
          <mat-label class="labelText" id="logo"
                     title="{{'clickOnImage' | translate}}">{{'logo' | translate | uppercase}}</mat-label>
          <div class="for-image" (click)="fileInput.click()">
            <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="supplier.logo != ''"
                 [src]="supplier.logo">
            <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="supplier.logo == ''"
                 src="assets/defaultImage.png">

          </div>
          <input type="file" class="fileInput" #fileInput (change)="onSelectFile($event.target.files)">
          <div class="inputDiv">
            <mat-label class="labelText">{{'address' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.address">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'postalCode' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.postalCode">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'website' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.link">
          </div>
          <div class="inputDiv">
            <mat-label class="labelText">{{'description' | translate | uppercase}}</mat-label>
            <input type="text" required class="inputField" [(ngModel)]="supplier.description">
          </div>
          <hr class="specific-line">
          <div class="inputDiv">
            <mat-label class="labelText">{{'email' | translate | uppercase}}</mat-label>
            <input type="email" required class="inputField" [(ngModel)]="supplier.email">
          </div>
        </div>
      </div>
    </div>
    <div class="forChangeButton">
    <div class="changeButton" [ngClass]="{'fix-to-bottom' : supplier.role == 3}" (click)="requestUpdateProfile();">{{'next' | translate | uppercase}}
    </div>

  </div>
  </div>
  <div class="verification" *ngIf="verification">
    <div class="verificationText">{{'verificationText' | translate}}</div>
    <div class="labelText">{{'code' | translate | uppercase}}</div>
    <input type="text" #codeForm class="inputField2" [(ngModel)]="code">
    <div class="forChangeButton">
      <div class="changeButton" [ngClass]="{'fix-to-bottom' : verification}" (click)="confirmCode()">{{'next' | translate | uppercase}}
      </div>

    </div>
  </div>
</div>
