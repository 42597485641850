<div class="full" >
  <div class="header">
    <img src="/assets/menu.png"  class="backArrow" (click)="sideNav.open(); showNav = true">

    <img src="/assets/mini_logo.png" class="miniLogo"  *ngIf="router.url == '/main/start'">
    <div class="headerTitle"  *ngIf="router.url != '/main/start'">{{text | translate | uppercase}}</div>

    <img src="/assets/home.png" class="cart" *ngIf="findIcon() == 'home'" (click)="onNavClick('main/start')">
    <img src="/assets/edit_icon.png" class="cart" *ngIf="findIcon() == 'edit'" (click)="editProfile()">
  </div>
  <div class="bigHeader">
    <img class="logoLong" src="/assets/enabavka_long.png" (click)="onNavClick('main/start')">

    <div class="bigMenu">
      <div class="bigLink"  (click)="onNavClick('main/start')">{{'home' | translate}}</div>
      <div class="bigLink" (click)="onNavClick('main/orders');this.text = 'ordersArchive'">{{'ordersArchive' | translate}}</div>
      <div class="bigLink" (click)="onNavClick('main/products');this.text = 'products'">{{'products' | translate}}</div>
      <div class="bigLink" (click)="logOut()">{{'logOut' | translate}}</div>
    </div>

  </div>
  <div class="mainContainer">
    <router-outlet></router-outlet>
  </div>

</div>

<mat-sidenav #sideNav mode="over" class="sideNav" [ngClass]="{'showSideNaw' : showNav}">
  <div class="upperHeaderNav">
  </div>
  <div class="sideNavHeader">
    <img src="/assets/menu.png"   class="backArrowNav menuClose" (click)="sideNav.close(); showNav = false">
    <div class="textMenu"> {{'menu' | translate | uppercase}}</div>
    <img src="/assets/close.png" class="cartNav menuClose closeSpecific"  (click)="sideNav.close()">
  </div>
  <div class="menuList">
    <span class="menuItem" (click)="openActions();sideNav.close()"><img src="assets/Akcije.png" class="menuLogo">{{'actions' | translate}}</span> <br>
<!--    <span class="menuItem" (click)="onNavClick('main/list',-1, 2); sideNav.close()"><img src="assets/Obavestenja.png" class="menuLogo">{{'notifications' | translate}}</span><br>-->
<!--    <span class="menuItem" (click)="onNavClick('main/list',-1, 1); sideNav.close()"><img src="assets/news.png" class="menuLogo">{{'news' | translate}}</span><br>-->
    <span class="menuItem" (click)="onNavClick('main/products'); text='products'; sideNav.close()"><img src="assets/products.png" class="menuLogo">{{'products' | translate}}</span><br>
    <span class="menuItem" (click)="onNavClick('main/list',-1, 2);sideNav.close()" *ngIf="supplier.role == 3"><img
      src="assets/Obavestenja.png" class="menuLogo">{{'notifications' | translate}}</span><br *ngIf="supplier.role == 3">
    <span class="menuItem" (click)="onNavClick('main/list',-1, 1);sideNav.close()" *ngIf="supplier.role == 3"><img
      src="assets/news.png" class="menuLogo">{{'news' | translate}}</span><br *ngIf="supplier.role == 3">

    <span class="menuItem" (click)="onNavClick('main/rabats'); text='rabat'; sideNav.close()"><img src="assets/dollar.png" class="menuLogo">{{'rabat' | translate}}</span><br>
    <span class="menuItem" *ngIf="supplier.role == 3" (click)="onNavClick('main/suppliers'); sideNav.close()"><img src="assets/truck.png" class="menuLogo">{{'suppliers' | translate}}</span><br *ngIf="supplier.role == 3" >
    <span class="menuItem" *ngIf="supplier.role == 3" (click)="onNavClick('main/edits/product'); sideNav.close()"><img src="assets/settings.png" class="menuLogo">{{'productEdits' | translate}}</span><br *ngIf="supplier.role == 3" >
    <span class="menuItem" (click)="onNavClick('main/customers'); sideNav.close()"><img src="assets/cart.png" class="menuLogo">{{'customers' | translate}}</span><br>
    <span class="menuItem" (click)="onNavClick('main/orders'); this.text = 'ordersArchive'; sideNav.close()"><img src="assets/archive.png" class="menuLogo">{{'ordersArchive' | translate}} </span><br>
    <span class="menuItem" (click)="onNavClick('main/history'); this.text = 'productHistory'; sideNav.close()"><img src="assets/cart_dollar.png" class="menuLogo">{{'productHistory' | translate}}</span><br>
    <!--    <span class="menuItem"><img src="assets/settings.png" class="menuLogo">{{'settings' | translate}}</span><br>-->
    <span class="menuItem" (click)="onNavClick('main/profile',-1, -1); sideNav.close()"><img src="assets/profile.png" class="menuLogo">{{'profile' | translate}}</span><br>
    <span class="menuItem" (click)="onNavClick('contact'); sideNav.close()"><img src="assets/contactus.png" class="menuLogo">{{'contactUs' | translate}}</span><br>
    <span class="menuItem" (click)="logOut()"><img src="assets/logout.png" class="menuLogo">{{'logOut' | translate}}</span><br>

  </div>
</mat-sidenav>
