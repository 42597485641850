import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './main/main.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {StartComponent} from './start/start.component';
import {ContactPageComponent} from './contact-page/contact-page.component';
import {ForgottenPassComponent} from './forgotten-pass/forgotten-pass.component';
import {SuppliersComponent} from './suppliers/suppliers.component';
import {ProductsComponent} from './products/products.component';
import {FilterProductsComponent} from './filter-products/filter-products.component';
import {ProductEditsComponent} from './product-edits/product-edits.component';
import {CustomersComponent} from './customers/customers.component';
import {OrdersComponent} from './orders/orders.component';
import {RabatsComponent} from './rabats/rabats.component';
import {SpecificListComponent} from './specific-list/specific-list.component';
import {NewsNoticeComponent} from './news-notice/news-notice.component';
import {NewNewsComponent} from './new-news/new-news.component';
import {ProfileComponent} from './profile/profile.component';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {EditPasswordComponent} from './edit-password/edit-password.component';
import {ActionsComponent} from "./actions/actions.component";
import {ActionFilterComponent} from "./action-filter/action-filter.component";
import {ProductHistoryComponent} from "./product-history/product-history.component";
import {AdminsComponent} from "./admins/admins.component";
import {CitiesComponent} from "./cities/cities.component";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'password',
    component: ForgottenPassComponent
  },
  {
    path: 'contact',
    component: ContactPageComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'main',
    component: MainComponent,
    children: [
      {
        path: 'start',
        component: StartComponent,
      },
      {
        path: 'suppliers',
        component: SuppliersComponent,
      },
      {
        path: 'history',
        component: ProductHistoryComponent,
      },
      {
        path: 'customers',
        component: CustomersComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
      {
        path: 'actions',
        component: ActionsComponent,
      },
      {
        path: 'orders',
        component: OrdersComponent,
      },
      {
        path: 'list/:type',
        component: SpecificListComponent,
      },
      {
        path: 'specifics/:id/:type',
        component: NewsNoticeComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'profile/edit',
        component: EditProfileComponent
      },
      {
        path: 'password',
        component: EditPasswordComponent
      },
      {
        path: 'specifics/edit',
        component: NewNewsComponent,
      },
      {
        path: 'rabats',
        component: RabatsComponent,
      },
      {
        path: 'edits/product',
        component: ProductEditsComponent,
      },
      {
        path: 'filter',
        component: FilterProductsComponent
      },
      {
        path: 'action/filter',
        component: ActionFilterComponent
      },
      {
        path: 'admins',
        component: AdminsComponent
      },
      {
        path: 'cities',
        component: CitiesComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
