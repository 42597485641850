import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Customer, Supplier} from '../model';
import {Router} from '@angular/router';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public supplier: Supplier;
  public mode = 'info';
  public actualPassword = '';
  public newPassword = '';
  public repeatPassword = '';
  public requestedEditChange = false;
  public requestedPasswordChange = false;
  public code = '';
  public startDelivery = '09:00';
  public endDelivery = '22:00';
  public startOrder = '09:00';
  public endOrder = '22:00';
  @ViewChild('codeForm', {static: false}) public codeForm: ElementRef;

  constructor(private router: Router, private main: MainComponent, private translate: TranslateService, private apiService: ApiServiceService, private snackBar: MatSnackBar) {
  }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));
    if (this.supplier.role == 2) {
      this.startOrder = this.supplier.orderTime.split(' - ')[0];
      this.endOrder = this.supplier.orderTime.split(' - ')[1];
      this.startDelivery = this.supplier.deliveryTime.split(' - ')[0];
      this.endDelivery = this.supplier.deliveryTime.split(' - ')[1];
    }


  }

  public changeMode(mode) {
    this.mode = mode;
  }

  public updateProfile() {
    this.requestedEditChange = true;
    this.apiService.requestUpdate().subscribe(
      (data) => {
        setTimeout(() => { // wait 100ms to load this input
          this.codeForm.nativeElement.focus();
        }, 100);
      }, (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadImage(formData).subscribe(
      (data: { path, fullUrl }) => {
        this.supplier.logo = data.fullUrl;
      });
  }

  public updateProfileWithCode() {
    this.supplier.code = this.code;
    if (this.supplier.role == 2) {
      this.supplier.deliveryTime = this.startDelivery + ' - ' + this.endDelivery;
      this.supplier.orderTime = this.startOrder + ' - ' + this.endOrder;
    }

    this.apiService.updateProfile(this.supplier).subscribe(
      (supplier: Supplier) => {
        this.supplier = supplier;
        this.code = '';
        localStorage.setItem('supplier', JSON.stringify(this.supplier));
        this.mode = 'info';
        this.requestedEditChange = false;
      }, (err) => {
        if (err.status == 404) {
          this.snackBar.open(this.translate.instant('wrongCode'), 'OK', {duration: 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      },
    );
  }

  public changePassword() {
    if (this.newPassword == '' || this.actualPassword == '' || this.repeatPassword == '') {
      this.snackBar.open(this.translate.instant('pleaseCompleteAllFields'), 'OK', {duration: 3000});
    } else if (this.newPassword != this.repeatPassword) {
      this.snackBar.open(this.translate.instant('passwordsAreNotSame'), 'OK', {duration: 3000});
    } else if (this.actualPassword == this.newPassword) {
      this.snackBar.open(this.translate.instant('newPasswordCantBeOld'), 'OK', {duration: 3000});
    } else if (this.newPassword.length < 6) {
      this.snackBar.open(this.translate.instant('passwordMustBeAtLeast6'), 'OK', {duration: 3000});
    } else {
      this.apiService.requestUpdate().subscribe(
        (data) => {
          this.requestedPasswordChange = true;
          setTimeout(() => { // wait 100ms to load this input
            this.codeForm.nativeElement.focus();
          }, 100);
        }, (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        },
      );
      setTimeout(() => { // wait 100ms to load this input
        this.codeForm.nativeElement.focus();
      }, 100);
    }
  }

  public changePasswordWithCode() {
    this.apiService.changePassword(this.code, this.newPassword, this.actualPassword).subscribe(
      (data) => {
        this.mode = 'info';
        this.requestedPasswordChange = false;
      }, (err) => {
        if (err.status == 405) {
          this.snackBar.open(this.translate.instant('newPasswordCantBeOld'), 'OK', {duration: 3000});
        } else if (err.status == 406) {
          this.snackBar.open(this.translate.instant('oldPasswordNotCorrect'), 'OK', {duration: 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      },
    );
  }

}
