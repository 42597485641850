import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Supplier} from '../model';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  public supplier: Supplier;
  public verification = false;
  public code = '';
  public startDelivery = '09:00';
  public endDelivery = '22:00';
  public startOrder = '09:00';
  public endOrder = '22:00';
  @ViewChild('codeForm', {static: false}) public codeForm: ElementRef;
  constructor(private translate: TranslateService, private snackBar: MatSnackBar, private apiService: ApiServiceService, private main: MainComponent, private router: Router) { }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));
    if (this.supplier.role == 2) {
      this.startOrder = this.supplier.orderTime.split(' - ')[0];
      this.endOrder = this.supplier.orderTime.split(' - ')[1];
      this.startDelivery = this.supplier.deliveryTime.split(' - ')[0];
      this.endDelivery = this.supplier.deliveryTime.split(' - ')[1];
    }
  }

  public changePassword() {
    this.router.navigate(['main/password']);
  }

  public onSelectFile(files: FileList) {
    const fileToUpload = files.item(0);
    const formData = new FormData();
    formData.append('image_file', fileToUpload, 'image');
    this.apiService.uploadImage(formData).subscribe(
      (data: { path, fullUrl }) => {
        this.supplier.logo = data.fullUrl;
      });
  }

  public requestUpdateProfile() {
    this.apiService.requestUpdate().subscribe(
      (data) => {
        this.main.text = 'verification';
        this.verification = true;
        setTimeout(() => { // wait 100ms to load this input
          this.codeForm.nativeElement.focus();
        }, 100);
      }, (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );

  }

  public confirmCode() {
    this.supplier.code = this.code;
    if (this.supplier.role == 2) {
      this.supplier.deliveryTime = this.startDelivery + ' - ' + this.endDelivery;
      this.supplier.orderTime = this.startOrder + ' - ' + this.endOrder;
    }
    this.apiService.updateProfile(this.supplier).subscribe(
      (supplier: Supplier) => {
        this.supplier = supplier;
        this.code = '';
        localStorage.setItem('supplier', JSON.stringify(this.supplier));
        this.router.navigate(['main/profile']);
      }, (err) => {
        if (err.status == 404) {
          this.snackBar.open(this.translate.instant('wrongCode'), 'OK', {duration : 3000});
        } else {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        }
      },
    );
  }

}
