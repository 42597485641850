import {Location} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {Customer, Supplier} from '../model';
import {MessagingService} from '../shared/messaging.service';
import {RegisterComponent} from '../register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public supplier: Supplier = {email: '', password: '', id: 0};

  constructor(private register: RegisterComponent, private location: Location, private messagingService: MessagingService,
              private router: Router, private parent: MainComponent, private translate: TranslateService,
              private apiService: ApiServiceService, private snackBar: MatSnackBar) { }

  public timer;
  public typing = false;
  public keyboardOpen = false;
  public height = 0;

  public ngOnInit() {
    this.height = window.innerHeight;
    this.messagingService.requestPermission(123);
    if (localStorage.getItem('supplier') != null) {
      this.router.navigate(['main/start']);
    }
    this.apiService.getLocale().subscribe(
      (data: {locale}) => {
        this.supplier.locale = data.locale;
      },
      (err) => {
        this.supplier.locale = 'sr';
      },
    );
  }

  public onNavClick(address: string) {
    this.router.navigate([address]);
  }

  public onResize() {

    // if new height is smaller than 70% of origin screen, keyboard open
    const seventyPercent = this.height - ((this.height / 100) * 30);
    if (window.innerHeight < seventyPercent ) {
      this.keyboardOpen = true;
    } else {
      this.keyboardOpen = false;
    }
  }

  public openContact() {
    this.router.navigate(['contact']);
    // this.parent.backRoute = 'login';
    // this.parent.iconType = 0;
    // this.parent.text = this.translate.instant('contact');
  }

  public searchUsername() {
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     this.typing = false;
    //     this.apiService.checkUsername(this.customer.username). subscribe(
    //       data => {
    //
    //       }
    //     );
    //
    //   }, 500);
  }

  public login() {
    const rememberMe = this.supplier.rememberMe;
    this.apiService.login(this.supplier).subscribe(
      (result: {user, jwt}) => {
        this.supplier = result.user;
        if (this.supplier.role == 2 && this.supplier.isActive != 1) {
          this.register.verification = true;
          localStorage.setItem('verification', '1');
          this.router.navigate(['register']);
        } else {
          localStorage.setItem('JWT', result.jwt);
          localStorage.setItem('supplier', JSON.stringify(this.supplier));

          // if remember me turned on, request and save remember me token
          // tslint:disable-next-line:triple-equals
          if (rememberMe == true) {
            this.apiService.requestRememberToken().subscribe(
              (data: { rememberToken }) => {
                this.supplier.rememberToken = data.rememberToken;
                this.supplier.rememberMe = rememberMe;
                localStorage.setItem('supplier', JSON.stringify(this.supplier));
              },
            );
          }
          if (localStorage.getItem('url')) {
            this.router.navigate([localStorage.getItem('url')]);
            localStorage.removeItem('url');
          } else {
            this.router.navigate(['main/start']);
          }
        }
      },
      (err) => {
        this.snackBar.open(err.error.message, 'OK', {duration : 3000});
      },
    );
  }

}
