<div class="main-container" (window:resize)="onResized()">
  <div class="section-header" [ngClass]="{'smaller-header' : !showHeader}">
    <div class="section-hide-show" (click)="showHeader = !showHeader">
      <span [ngClass]="{'up-arrow' : !showHeader, 'down-arrow' : showHeader}"><mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div class="small-section-title" [ngClass]="{'hidden-small-title' : showHeader}">
      <img src="assets/archive_blue.png" class="section-icon-small">  {{'orders' | translate}} ({{total}})
    </div>
    <div class="section-header-inner" [ngClass]="{'hide-inner' : !showHeader}">
      <div class="section-title">
        <div class="inner-section-title">
          <img src="assets/archive_blue.png" class="section-icon">  {{'orders' | translate}} ({{total}})
        </div>
      </div>
      <div class="section-track">
        <div class="section-item" [matMenuTriggerFor]="searchPer">
          <span *ngIf="showText">{{'searchPer' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>settings</mat-icon></span>
        </div>
        <div class="section-item" [matMenuTriggerFor]="date">
          <span *ngIf="showText">{{'date' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>date_range</mat-icon></span>
        </div>
        <div class="section-item" [matMenuTriggerFor]="price">
          <span *ngIf="showText">{{'sum' | translate}} </span>
          <span *ngIf="!showText"><mat-icon>euro_symbol</mat-icon></span>
        </div>
        <!--        <div class="section-item" [matMenuTriggerFor]="active">-->
        <!--          <span *ngIf="showText">{{'delivery' | translate}} </span>-->
        <!--          <span *ngIf="!showText"><mat-icon>visibility</mat-icon></span>-->
        <!--        </div>-->
        <div class="search-item">
          <div class="search-left" (click)="page=0; filterOrders()">
            <span *ngIf="showText">{{'search' | translate}} </span>
            <span *ngIf="!showText"><mat-icon>search</mat-icon></span>
          </div>
          <div class="search-right" (click)="clearFilter()">
            <mat-icon>clear</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-menu #price="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.priceMin" min="0"
               placeholder="{{'sumMin' | translate}}">
        <span matSuffix>RSD</span>
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.priceMax" min="0"
               placeholder="{{'sumMax' | translate}}">
        <span matSuffix>RSD</span>
      </mat-form-field>
      <hr>
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.deliveryPriceMin" min="0"
               placeholder="{{'deliveryPriceFrom' | translate}}">
        <span matSuffix>RSD</span>
      </mat-form-field>
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.deliveryPriceMax" min="0"
               placeholder="{{'deliveryPriceTo' | translate}}">
        <span matSuffix>RSD</span>
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #searchPer="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input matInput type="number" [(ngModel)]="filter.id" placeholder="ID">
      </mat-form-field>
      <mat-select multiple (selectionChange)="checkCustomers()" [(ngModel)]="filter.customer"
                  placeholder="{{'customer' | translate}}">
        <mat-option [value]="0" (click)="resetCustomers()">{{'allCustomers' | translate}}</mat-option>
        <mat-option *ngFor="let c of customers" [value]="c.id">{{c.object_name}}</mat-option>
      </mat-select>
      <hr>
      <mat-select  *ngIf="supplier.role == 3" [(ngModel)]="filter.supplier" placeholder="{{'supplier' | translate}}">
        <mat-option *ngFor="let c of suppliers" [value]="c.id">{{c.name}}</mat-option>
      </mat-select>
    </div>
  </mat-menu>
  <mat-menu #date="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="pickerFrom" placeholder="{{'dateFrom' | translate}}"
               (dateChange)="filter.dateFrom = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="section-form">
        <input #fromInput matInput [matDatepicker]="dateTo" placeholder="{{'dateTo' | translate}}"
               (dateChange)="filter.dateTo = $event.value.toISOString()">
        <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
        <mat-datepicker #dateTo></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-menu>
  <mat-menu #deliveries="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">

    </div>
  </mat-menu>
  <mat-menu #active="matMenu" xPosition="after">
    <div class="section-menu" (click)="$event.stopPropagation()">

    </div>
  </mat-menu>
  <div class="main-body" [ngClass]="{'main-body-higher' : !showHeader}">
    <div class="chips-list">
      <mat-chip-list>
        <mat-chip *ngIf="filter.id != ''">ID: {{filter.id}}
          <mat-icon class="chips-icon" (click)="filter.id = ''">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="(filter.customer.length != customers.length)">{{'customers' | translate}}:
          <div class="customerChip">
             <span *ngIf="filter.customer.length == 1 && filter.customer.includes(0)">
               {{'allCustomers' | translate}}
            </span>
            <span *ngIf="!filter.customer.includes(0)">
          <span *ngFor="let c of filter.customer; let i = index">
            {{findCustomerName(c)}} <span *ngIf="i < filter.customer.length -1">,</span>
          </span>
              </span>
          </div>
          <mat-icon class="chips-icon" (click)="resetFilterCustomer()">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.supplier != 0 && supplier.role == 3">{{'supplier' | translate}}: {{findSupplier(filter.supplier)}}
          <mat-icon class="chips-icon" (click)="filter.supplier = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.dateFrom != -1">{{'dateFrom' | translate}}: {{filter.dateFrom | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.dateFrom = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.dateTo != -1">{{'dateTo' | translate}}: {{filter.dateTo | date : 'dd/MM/yy'}}
          <mat-icon class="chips-icon" (click)="filter.dateTo = -1">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.priceMin != 0">{{'sumMin' | translate}}: {{filter.priceMin}} RSD
          <mat-icon class="chips-icon" (click)="filter.priceMin = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.priceMax != 0">{{'sumMax' | translate}}: {{filter.priceMax}} RSD
          <mat-icon class="chips-icon" (click)="filter.priceMax = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.deliveryPriceMin != 0">{{'deliveryPriceFrom' | translate}}: {{filter.deliveryPriceMin}}
          RSD
          <mat-icon class="chips-icon" (click)="filter.deliveryPriceMin = 0">cancel</mat-icon>
        </mat-chip>
        <mat-chip *ngIf="filter.deliveryPriceMax != 0">{{'deliveryPriceTo' | translate}}: {{filter.deliveryPriceMax}}
          RSD
          <mat-icon class="chips-icon" (click)="filter.deliveryPriceMax = 0">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <div *ngIf="sum != 0" class="d-flex">
        <mat-chip-list>
          <mat-chip>
            {{'sumAmount' | translate}}: {{sum}} RSD
          </mat-chip>
        </mat-chip-list>

      </div>
      <div class="action-list">
        <div class="action-list-inner" >
          <div class="button-action delete" (click)="changeMultipleRead(3)" *ngIf="selected.length > 0">{{'confirm' | translate}} ({{selected.length}})</div>
        </div>
      </div>

    <div class="table-container">
      <ngx-datatable
        class="material table-list"
        infiniteScroll [infiniteScrollDistance]="2" [scrollWindow]=true
        [infiniteScrollThrottle]="150"
        (scrolled)="onScroll()"
        [rows]="orders"
        [columnMode]="'force'"
        [headerHeight]="50"
        [scrollbarH]="true"
        [messages]="my_messages"
        [selected]="selected"
        [rowClass]="getRowClassRead"
        [loadingIndicator]="loading"
        [selectionType]="'checkbox'"
        (activate)='onActivate($event)'
        [rowHeight]="50"
        (sort)="onSort($event)"
        (select)="onSelect($event)">
        <ngx-datatable-column
          [width]="50"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true">
        </ngx-datatable-column>
        <ngx-datatable-column name="Id" sortable="false" prop="id" width="50">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'customer' | translate}}" sortable="false" prop="customer">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'sum' | translate}}" sortable="false" prop="price">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{value}}</span>
            <span *ngIf="!value">0</span>
            {{row.currency}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'deliveryPrice' | translate}}" sortable="false" prop="deliveryPrice">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span *ngIf="value">{{value}}</span>
            <span *ngIf="!value">0</span>
            {{row.currency}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'address' | translate}}" sortable="false" prop="address">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value.address}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'date' | translate}}" sortable="false" prop="createdAt">
          <ng-template let-value="value" ngx-datatable-cell-template>
            {{value | date : 'dd/MM/yy' : 'Europe/Belgrade'}}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>

  <div class="big-footer" *ngIf="!loading" >
    <app-footer-big></app-footer-big>
  </div>
</div>
