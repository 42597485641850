<div class="main-container">
  <div class="upper-container">
    <div class="dialog-title">
      {{'order' | translate}} #{{data.order.id}}
    </div>
    <div class="dialog-body row">
      <div class="left-container col-12 col-md-6">
        <div class="dialog-title-mini">
          {{'info' | translate}}:
        </div>
        <table class="dialog-table">
          <tr><td class="left-cell">{{'customer' | translate}}:</td> <td class="right-cell">{{data.order.customer}}
           <span *ngIf="data.order.address">{{data.order.address.name}}</span>
          </td></tr>
          <tr><td class="left-cell">{{'address' | translate}}:</td> <td class="right-cell"><span *ngIf="data.order.address">{{data.order.address.address}} {{data.order.address.city}}</span></td></tr>
          <tr><td class="left-cell">{{'phone' | translate}}:</td> <td class="right-cell"><span *ngIf="data.order.address">{{data.order.address.phone}}</span></td></tr>
       <div *ngIf="supplier.role != 2">
         <tr><td class="left-cell">{{'orderAmount' | translate}}:</td> <td class="right-cell">{{data.order.price - data.order.deliveryPrice}} {{data.order.currency}}</td></tr>
          <tr><td class="left-cell">{{'deliveryCost' | translate}}:</td> <td class="right-cell">
            <span *ngIf="data.order.deliveryPrice">{{data.order.deliveryPrice}}</span>
            <span *ngIf="!data.order.deliveryPrice">0</span>
            {{data.order.currency}}</td></tr>
          <tr class="total-sum"><td class="left-cell">{{'totalSum' | translate | titlecase}}:</td> <td class="right-cell">
            {{data.order.price}}
            {{data.order.currency}}</td></tr>
       </div>
        </table>

        <hr>
        <div class="suppliers-info">
          <div class="dialog-title-mini">
            {{'suppliers' | translate}}
          </div>
          <div class="product" *ngFor="let s of data.order.suppliers">
            <table class="dialog-table-product">
              <tr><td class="left-cell">{{'productName' | translate}}:</td> <td class="right-cell font-weight-bold">{{s.name}}</td></tr>
              <tr><td class="left-cell">{{'orderAmount' | translate}}:</td> <td class="right-cell">{{s.price}} {{s.currency}}</td></tr>
              <tr><td class="left-cell">{{'deliveryCost' | translate}}:</td> <td class="right-cell">{{s.delivery}} {{s.currency}}</td></tr>
              <tr *ngIf="s.comment"><td class="left-cell">{{'comment' | translate}}:</td><td class="right-cell">{{s.comment}}</td></tr>
            </table>
            <div class="product-id">
              #{{s.supplierId}}
            </div>
            <hr>
          </div>
        </div>


      </div>
      <div class="right-container col-12 col-md-6">
        <div class="dialog-title-mini">
          {{'products' | translate}}:
        </div>

        <div class="product" *ngFor="let p of data.order.products">
          <table class="dialog-table-product">
            <tr><td class="left-cell">{{'productName' | translate}}:</td> <td class="right-cell font-weight-bold">{{p.product.name}}</td></tr>
            <tr><td class="left-cell">{{'amount' | translate}}:</td> <td class="right-cell">{{p.chosenAmount}} x {{p.product.measurementUnit}}</td></tr>
            <tr><td class="left-cell">{{'price' | translate}}:</td> <td class="right-cell">{{p.price}} {{p.product.currency}}</td></tr>
            <tr><td class="left-cell">{{'supplier' | translate}}:  </td> <td class="right-cell">{{p.product.supplierName}}</td></tr>
          </table>
          <div class="product-id">
            #{{p.product.id}}
          </div>
          <hr>
        </div>
      </div>
    </div>

  </div>
  <div class="hour-date">
    <hr>
    <span>{{data.order.createdAt | date : 'HH:mm' : 'Europe/Belgrade'}}</span>
    <span>  {{data.order.createdAt | date : 'dd/MM/yyyy' : 'Europe/Belgrade'}}</span>
    <hr>
  </div>
</div>
<div class="down-container" *ngIf="supplier.role == 2">
  <div class="send-button" (click)="sendInvoice()">
    <div class="forEmailSpinner"  *ngIf="loadingEmail">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <span class="miniText" *ngIf="!loadingEmail">{{'sendEmail' | translate }}</span>
    <mat-icon *ngIf="!loadingEmail" class="mini_icon">mail</mat-icon>
  </div>
  <div class="send-button" (click)="printOrder()">
    {{'print' | translate}}
    <mat-icon class="mini_icon">print</mat-icon>
  </div>
  <div class="send-button" *ngIf="data.order.isRead == false" (click)="chengeRead(data.order)">
    {{'confirm' | translate}}
    <mat-icon class="mini_icon">done</mat-icon>
  </div>
  <div class="send-button" (click)="editOrder()" *ngIf="supplier.role == 2">
  {{'edit' | translate}}
    <mat-icon class="mini_icon">create</mat-icon>
  </div>
</div>
