<div class="main-container">
  <div class="upper-container">
    <div class="dialog-title">
    {{data.supplier.name}}
    </div>
    <hr>
    <div class="row">
      <div class="col-12 col-md-6 first">
        <div class="inputDiv" style="margin-top: 0px !important;">
          <mat-label class="labelText">{{'fullName' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.name">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'identifierLegal' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.identifier">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'pib' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.PIB">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'contactPerson' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.contactPerson">
        </div>

        <div class="inputDiv">
          <mat-label class="labelText">{{'phone' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.phone">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'centralPhone' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.centralPhone">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'deliveryTime' | translate | uppercase}}</mat-label>
          <div class="inputField">
            <input class="timeInput" [format]="24" [ngxTimepicker]="startDeliveryPicker"
                   [(ngModel)]="startDelivery">
            -
            <input class="timeInput" [format]="24" [ngxTimepicker]="endDeliveryPicker" [(ngModel)]="endDelivery">

          </div>
          <ngx-material-timepicker #startDeliveryPicker></ngx-material-timepicker>
          <ngx-material-timepicker #endDeliveryPicker></ngx-material-timepicker>
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'orderTime' | translate | uppercase}}</mat-label>
          <div class="inputField">
            <input class="timeInput" [format]="24" [ngxTimepicker]="startOrderPicker" [(ngModel)]="startOrder">
            -
            <input class="timeInput" [format]="24" [ngxTimepicker]="endOrderPicker" [(ngModel)]="endOrder">
          </div>
          <ngx-material-timepicker #startOrderPicker></ngx-material-timepicker>
          <ngx-material-timepicker #endOrderPicker></ngx-material-timepicker>
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'deliveryPrice' | translate | uppercase}}</mat-label>
          <div>
            <input matInput type="number" required class="inputField forPrice for-inline"
                   [(ngModel)]="data.supplier.deliveryPrice"> <span
            class="rsd-suffix" matSuffix> {{supplier.currency}} </span>
          </div>

        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'minDelivery' | translate | uppercase}}</mat-label>
          <div>
            <input type="number" required class="inputField forPrice for-inline"
                   [(ngModel)]="data.supplier.minDelivery"> <span
            class="rsd-suffix" matSuffix> {{supplier.currency}} </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 second">
        <mat-label class="labelText" id="logo"
                   title="{{'clickOnImage' | translate}}">{{'logo' | translate | uppercase}}</mat-label>
        <div class="for-image" (click)="fileInput.click()">
          <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="data.supplier.logo != ''"
               [src]="data.supplier.logo">
          <img class="supplier-image" title="{{'clickOnImage' | translate}}" *ngIf="data.supplier.logo == ''"
               src="assets/defaultImage.png">

        </div>
        <input type="file" class="fileInput" #fileInput (change)="onSelectFile($event.target.files)">
        <div class="inputDiv" style="margin-top: 68px !important;">
          <mat-label class="labelText">{{'address' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.address">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'postalCode' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.postalCode">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'website' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.link">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'description' | translate | uppercase}}</mat-label>
          <input type="text" required class="inputField" [(ngModel)]="data.supplier.description">
        </div>
        <div class="inputDiv">
          <mat-label class="labelText">{{'email' | translate | uppercase}}</mat-label>
          <input type="email" required class="inputField" [(ngModel)]="data.supplier.email">
        </div>
      </div>
    </div>
  </div>
  <button mat-flat-button class="toggle-block float-right"  (click)="update()" >
    <span>{{'save' | translate}}</span>
  </button>
</div>
