<div class="main-container">
  <div class="upper-container">
    <div class="dialog-title">
      {{'order' | translate}} #{{data.order.id}} test
    </div>
    <div class="dialog-body row">
      <div class="left-container col-12 col-md-6">
        <div class="dialog-title-mini">
          {{'info' | translate}}:
        </div>
        <table class="dialog-table">
          <tr><td class="left-cell">{{'customer' | translate}}:</td> <td class="right-cell">{{data.order.customer}}
            <span *ngIf="data.order.address">{{data.order.address.name}}</span>
          </td></tr>
          <tr><td class="left-cell">{{'address' | translate}}:</td> <td class="right-cell"><span *ngIf="data.order.address">{{data.order.address.address}} {{data.order.address.city}}</span></td></tr>
          <tr><td class="left-cell">{{'phone' | translate}}:</td> <td class="right-cell"><span *ngIf="data.order.address">{{data.order.address.phone}}</span></td></tr>
          <div *ngIf="supplier.role != 2">
          <tr><td class="left-cell">{{'orderAmount' | translate}}:</td> <td class="right-cell">{{data.order.price - data.order.deliveryPrice}} {{data.order.currency}}</td></tr>
          <tr><td class="left-cell">{{'deliveryCost' | translate}}:</td> <td class="right-cell">
            <span *ngIf="data.order.deliveryPrice">{{data.order.deliveryPrice}}</span>
            <span *ngIf="!data.order.deliveryPrice">0</span>
            {{data.order.currency}}</td></tr>
          <tr class="total-sum"><td class="left-cell">{{'totalSum' | translate | titlecase}}:</td> <td class="right-cell">
            {{data.order.price}}
            {{data.order.currency}}</td></tr>
          </div>
        </table>
        <hr>
        <div class="suppliers-info">
          <div class="dialog-title-mini">
            {{'suppliers' | translate}}
          </div>
          <div class="product" *ngFor="let s of editableOrder?.suppliers">
            <table class="dialog-table-product">
              <tr><td class="left-cell">{{'productName' | translate}}:</td> <td class="right-cell font-weight-bold">{{s.name}}</td></tr>
              <tr><td class="left-cell">{{'orderAmount' | translate}}:</td> <td class="right-cell">
                <span *ngIf="supplier.role == 2">{{getOrderPrice()}}</span>
                <span *ngIf="supplier.role != 2">{{s.price}}</span>
                {{s.currency}}</td></tr>
              <tr><td class="left-cell">{{'deliveryCost' | translate}}:</td> <td class="right-cell">
                <mat-form-field>
                  <input type="number" [(ngModel)]="s.deliveryInt" matInput>
                  <span matSuffix>{{s.currency}}</span>
                </mat-form-field>
              </td></tr>
              <tr *ngIf="s.comment"><td class="left-cell">{{'comment' | translate}}:</td><td class="right-cell">{{s.comment}}</td></tr>
            </table>
            <div class="product-id">
              #{{s.supplierId}}
            </div>
            <hr>

            <div class="for-new-fields w-50">
              <div class="d-flex justify-content-between">
                <mat-form-field class="pr-2">
                  <mat-label>{{"vehicleNumber" | translate}}</mat-label>
                  <input matInput type="text" class="vehicle_number" [(ngModel)]="editableOrder.vehicle_number">
                </mat-form-field>



                <mat-form-field class="pl-2">
                  <mat-label>{{"suppliedBy" | translate}}</mat-label>
                  <input matInput type="text" class="vehicle_number" [(ngModel)]="editableOrder.suppliedBy">
                </mat-form-field>
              </div>
              <br>
              <div class="d-flex justify-content-between">

                <mat-form-field class="pr-2">
                  <mat-label>{{"orderDate" | translate}}</mat-label>
                  <input matInput type="text" class="order_dated" [(ngModel)]="editableOrder.order_date">
                </mat-form-field>

                <mat-form-field class="pl-2">
                  <mat-label>{{"numberOfSendings" | translate}}</mat-label>
                  <input matInput type="number" class="number_of_sendings" [(ngModel)]="editableOrder.number_of_sendings">
                </mat-form-field>
              </div>
              <div class="w-100">
                <mat-form-field>
                  <mat-label>{{"accountNumber" | translate}}</mat-label>
                  <input matInput type="number" class="account_number" [(ngModel)]="editableOrder.account_number">
                </mat-form-field>
              </div>
            </div>


          </div>
        </div>


      </div>
      <div class="right-container col-12 col-md-6">
        <div class="dialog-title-mini">
          {{'products' | translate}}:
        </div>

        <div class="product" *ngFor="let p of editableOrder?.products">
          <table class="dialog-table-product">
            <mat-icon class="to-remove" title="{{'remove' | translate}} {{p.product.name}}" (click)="removeProduct(p.id)">close</mat-icon>
            <tr><td class="left-cell">{{'productName' | translate}}:</td> <td class="right-cell font-weight-bold">{{p.product.name}}</td></tr>
            <tr><td class="left-cell">{{'amount' | translate}}:</td> <td class="right-cell">
              <mat-form-field>
                <input type="number" min="0" matInput [(ngModel)]="p.chosenAmount" (ngModelChange)="calculatePrice($event, p)">
                <span matSuffix>x {{p.product.measurementUnit}}</span>
              </mat-form-field>
              </td></tr>
            <tr><td class="left-cell">{{'price' | translate}}:</td> <td class="right-cell">
              <mat-form-field>
                <input matInput type="number" min="0" [(ngModel)]="p.priceInt">
                <span matSuffix>{{p.product.currency}}</span>
              </mat-form-field>
            </td></tr>
            <tr><td class="left-cell">{{'supplier' | translate}}:  </td> <td class="right-cell">{{p.product.supplierName}}</td></tr>
          </table>
          <div class="product-id">
            #{{p.product.id}}
          </div>
          <hr>
        </div>

        <div class="dialog-title-mini">
          {{'addNewProduct' | translate}}:
        </div>
        <div class="add-product-div">
          <table class="dialog-table-product">
            <tr><td class="left-cell">{{'productName' | translate}}:</td> <td class="right-cell font-weight-bold">
              <mat-select [(ngModel)]="productToAdd.product" (ngModelChange)="changedProduct($event)">
                <mat-option *ngFor="let p of myProducts" [value]="p">{{p.name}}</mat-option>
              </mat-select>
            </td></tr>
            <tr><td class="left-cell">{{'amount' | translate}}:</td> <td class="right-cell">
              <mat-form-field>
                <input type="number" min="0" matInput [(ngModel)]="productToAdd.chosenAmount" (ngModelChange)="calculatePrice($event, productToAdd)">
                <span matSuffix>x {{productToAdd.product.measurementUnit}}</span>
              </mat-form-field>
            </td></tr>
            <tr><td class="left-cell">{{'price' | translate}}:</td> <td class="right-cell">
              <mat-form-field>
                <input matInput type="number" min="0" [(ngModel)]="productToAdd.priceInt">
                <span matSuffix>{{productToAdd.product.currency}}</span>
              </mat-form-field>
            </td></tr>
            <tr><td class="left-cell">{{'supplier' | translate}}:  </td> <td class="right-cell">{{supplier.name}}</td></tr>
          </table>
          <div class="add-button" (click)="add()">
            {{'add' | translate}}
          </div>
        </div>

      </div>
    </div>

  </div>



  <div class="hour-date">
    <hr>
    <span>{{data.order.createdAt | date : 'HH:mm' : 'Europe/Belgrade'}}</span>
    <span>  {{data.order.createdAt | date : 'dd/MM/yyyy' : 'Europe/Belgrade'}}</span>
    <hr>
  </div>
</div>
<div class="down-container" *ngIf="supplier.role == 2">
  <div class="send-button" (click)="save()">
    <div class="forEmailSpinner"  *ngIf="loadingEmail">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <span class="miniText" *ngIf="!loadingEmail">{{'save' | translate }}</span>
  </div>
</div>
