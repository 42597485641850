import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiServiceService {

  constructor(private http: HttpClient) { }
  public BASE_API_URL = environment.url;
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  public options = { headers: this.headers };

  private authToken() {
    // create authorization header with jwt token
    const jwt = localStorage.getItem('JWT');

    if (jwt) {
      const headers = new HttpHeaders({
        Authorization: jwt,
        'Content-Type': 'application/json',
      });
      return {headers};
    }
  }

  private authTokenBlob(): any {
    // create authorization header with jwt token
    const jwt = localStorage.getItem('JWT');

    if (jwt) {
      const headers = new HttpHeaders({
        Authorization: jwt,
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
        responseType: 'blob',
      });

      return {headers, responseType: 'blob'};
    }

    return {};
  }

  private authTokenImg() {
    // create authorization header with jwt token
    const jwt = localStorage.getItem('JWT');

    if (jwt) {
      const headers = new HttpHeaders({
        Authorization: jwt,
      });
      return {headers};
    }
  }

  private noAuthTokenImg() {
      const headers = new HttpHeaders({
      });
      return {headers};

  }

  public login(user) {
    return this.http.post(this.BASE_API_URL + '/supplier/login',  JSON.stringify(user), this.options);
  }

  public getLocale() {
    return this.http.get(this.BASE_API_URL + '/locale/get', this.options);
  }

  public requestRememberToken() {
    return this.http.get(this.BASE_API_URL + '/supplier/remember/request', this.authToken());
  }

  public getCitiesAndCountries() {
    return this.http.get(this.BASE_API_URL + '/city/country/get', this.authToken());
  }
  public uploadImage(file) {
    return this.http.post(this.BASE_API_URL + '/admin/supplier/image/upload', file,
      this.noAuthTokenImg());
  }

  public uploadNewsImage(file) {
    return this.http.post(this.BASE_API_URL + '/admin/other/image/upload', file,
      this.authTokenImg());
  }

  public uploadProductImage(file) {
    return this.http.post(this.BASE_API_URL + '/admin/product/image/upload', file,
      this.authTokenImg());
  }
  public uploadExcelFile(file) {
    return this.http.post(this.BASE_API_URL + '/insert/from/excel', file,
      this.authTokenImg());
  }
  public addComment(comment) {
    return this.http.post(this.BASE_API_URL +  '/comment/add',  JSON.stringify(comment), this.options);
  }

  public getNews(page) {
    return this.http.get(this.BASE_API_URL + '/admin/news/all/' + page, this.authToken());
  }

  public listOfBlocks(id) {
  return this.http.get(this.BASE_API_URL + '/admin/block/customer/' + id, this.authToken());
}

  public toggleBlock(customerId, supplierId) {
    return this.http.get(this.BASE_API_URL + '/admin/customer/block/' + customerId + '/' + supplierId, this.authToken());
  }

  public getNewsOrNotice(id, type) {
    return this.http.get(this.BASE_API_URL + '/admin/newsOrNotice/get/' + id + '/' + type,  this.authToken());
  }

  public deleteNewsOrNotice(id, type) {
    return this.http.get(this.BASE_API_URL + '/admin/newsOrNotice/delete/' + id + '/' + type,  this.authToken());
  }

  public updateNewsOrNotice(id, type, obj) {
    return this.http.post(this.BASE_API_URL + '/admin/newsOrNotice/update/' + id + '/' + type, JSON.stringify(obj), this.authToken());
  }

  public createNewsOrNotice(type, obj) {
    return this.http.post(this.BASE_API_URL + '/admin/newsOrNotice/create/'  + type, JSON.stringify(obj), this.authToken());
  }

  public getNotices(page) {
    return this.http.get(this.BASE_API_URL + '/admin/notices/all/' + page, this.authToken());
  }

  public loginWithToken(user) {
    return this.http.post(this.BASE_API_URL + '/supplier/remember/login',  JSON.stringify(user), this.options);
  }

  public register(user) {
    return this.http.post(this.BASE_API_URL + '/supplier/register',  JSON.stringify(user), this.options);
  }

  public filterSuppliers(filter, sort = 'created_at', dir = 'desc', page = 0) {
    return this.http.post(this.BASE_API_URL + '/admin/suppliers/filter/' + sort + '/' + dir + '/' + page,  JSON.stringify(filter), this.authToken());
  }

  public filterCustomers(filter, sort = 'created_at', dir = 'desc', page = 0) {
    return this.http.post(this.BASE_API_URL + '/admin/customers/filter/' + sort + '/' + dir + '/' + page,  JSON.stringify(filter), this.authToken());
  }

  public filterOrders(filter, sort = 'created_at', dir = 'desc', page = 0) {
    return this.http.post(this.BASE_API_URL + '/admin/orders/filter/' + sort + '/' + dir + '/' + page,  JSON.stringify(filter), this.authToken());
  }

  public filterEdits(filter, sort = 'created_at', dir = 'desc', page = 0) {
    return this.http.post(this.BASE_API_URL + '/admin/edits/get/' + sort + '/' + dir + '/' + page,  JSON.stringify(filter), this.authToken());
  }

  public getProductsPerSupplierId(id) {
    return this.http.get(this.BASE_API_URL + '/admin/products/supplier/' + id, this.authToken());
  }

  public createRabat(rabat) {
    return this.http.post(this.BASE_API_URL + '/supplier/rabat/create', JSON.stringify(rabat), this.authToken());
  }

  public updateRabat(rabat) {
    return this.http.post(this.BASE_API_URL + '/supplier/rabat/update', JSON.stringify(rabat), this.authToken());
  }

  public filterRabats(filter, sort = 'created_at', dir = 'desc', page = 0) {
    return this.http.post(this.BASE_API_URL + '/admin/rabats/filter/' + sort + '/' + dir + '/' + page,  JSON.stringify(filter), this.authToken());
  }

  public getAllCustomers() {
    return this.http.get(this.BASE_API_URL + '/admin/customers/all', this.authToken());
  }

  public sendInvoice(id) {
    return this.http.get(this.BASE_API_URL + '/supplier/order/invoice/send/' + id, this.authToken());
  }

  public printInvoice(id) {
    // @ts-ignore
    return this.http.get(this.BASE_API_URL + '/invoice/print/' + id, this.authTokenBlob());
  }

  public applyProductEdits(ids, type) {
    const data = {ids, type};
    return this.http.post(this.BASE_API_URL + '/admin/edits/apply', JSON.stringify(data), this.authToken());
  }

  public getAllSuppliers() {
    return this.http.get(this.BASE_API_URL + '/admin/suppliers/all', this.authToken());
  }

  public getAllManufacturers() {
    return this.http.get(this.BASE_API_URL + '/admin/manufacturers/all', this.authToken());
  }
  public getCountOrders() {
    return this.http.get(this.BASE_API_URL + '/order/supplier/count', this.authToken());
  }
  public getAllUnits() {
    return this.http.get(this.BASE_API_URL + '/admin/units/get', this.authToken());
  }

  public updateProduct(product) {
    return this.http.post(this.BASE_API_URL + '/admin/product/update', JSON.stringify(product), this.authToken());
  }

  public updateProductLimit(product) {
    return this.http.post(this.BASE_API_URL + '/admin/product/limit/update', JSON.stringify(product), this.authToken());
  }

public updateRead(order) {
  return this.http.post(this.BASE_API_URL + '/update/read/' + order.id, JSON.stringify(order), this.authToken());
}

  public updateMultipleRead(order) {
    return this.http.post(this.BASE_API_URL + '/update/multiple/read', JSON.stringify(order), this.authToken());
  }
  public getStatistics(from, to) {
    const data = {from, to};
    return this.http.post(this.BASE_API_URL + '/admin/statistics', JSON.stringify(data), this.authToken());
  }

  public createProduct(product) {
    return this.http.post(this.BASE_API_URL + '/admin/product/create', JSON.stringify(product), this.authToken());
  }

  public deleteProduct(id) {
    return this.http.get(this.BASE_API_URL + '/admin/product/delete/' + id, this.authToken());
  }

  public activateProduct(id) {
    return this.http.get(this.BASE_API_URL + '/admin/product/activate/' + id, this.authToken());
  }

  public deactivateProduct(id) {
    return this.http.get(this.BASE_API_URL + '/admin/product/deactivate/' + id, this.authToken());
  }

  public getProductPrediction(name) {
    return this.http.get(this.BASE_API_URL + '/admin/manufacturer/prediction/' + name, this.authToken());
  }

  public filterProducts(filter, page = 0) {
    return this.http.post(this.BASE_API_URL + '/admin/products/filter/' + page,  JSON.stringify(filter), this.authToken());
  }

  public changePassword(code, password, oldPassword) {
    const supplier = {code, password, oldPassword};
    return this.http.post(this.BASE_API_URL + '/supplier/password/change',  JSON.stringify(supplier), this.authToken());
  }

  public requestUpdate() {
    return this.http.get(this.BASE_API_URL + '/supplier/update/request', this.authToken());
  }

  public updateProfile(supplier) {
    return this.http.post(this.BASE_API_URL + '/supplier/update',  JSON.stringify(supplier), this.authToken());
  }

  public updateSupplier(supplier) {
    return this.http.post(this.BASE_API_URL + '/admin/supplier/update',  JSON.stringify(supplier), this.authToken());
  }

  public deleteSuppliers(ids) {
    const data = {ids};
    return this.http.post(this.BASE_API_URL + '/admin/suppliers/delete',  JSON.stringify(data), this.authToken());
  }

  public deleteRabats(ids) {
    const data = {ids};
    return this.http.post(this.BASE_API_URL + '/admin/rabats/delete',  JSON.stringify(data), this.authToken());
  }

  public deleteCustomers(ids) {
    const data = {ids};
    return this.http.post(this.BASE_API_URL + '/admin/customers/delete',  JSON.stringify(data), this.authToken());
  }

  public activateSuppliers(ids, type) {
    const data = {ids, type};
    return this.http.post(this.BASE_API_URL + '/admin/suppliers/activate',  JSON.stringify(data), this.authToken());
  }

  public forgottenPassword(email) {
    return this.http.get(this.BASE_API_URL +  '/supplier/pass/forgot/' + email, this.options);
  }

  public getMyProducts() {
    return this.http.get(this.BASE_API_URL + '/supplier/products/get', this.authToken());
  }

  public updateOrder(order) {
    return this.http.post(this.BASE_API_URL + '/supplier/order/update', JSON.stringify(order), this.authToken());
  }

  public getProductsHistory(filter) {
    return this.http.post(this.BASE_API_URL + '/supplier/products/history', JSON.stringify(filter), this.authToken());
  }
  public getAdmins(){
    return this.http.get(this.BASE_API_URL + '/select/all/admin', this.authToken() )
  }
  public updateAdmin(admin){
    return  this.http.post(this.BASE_API_URL + '/update/admin/' + admin.id, JSON.stringify(admin), this.authToken());
  }
  public newAdmin(admin){
    return  this.http.post(this.BASE_API_URL + '/new/admin', JSON.stringify(admin), this.authToken());
  }
  public deleteAdmin(admin){
    return  this.http.post(this.BASE_API_URL + '/delete/admin/' + admin.id, JSON.stringify(admin), this.authToken());
  }
  public getCities() {
    return this.http.get(this.BASE_API_URL + '/select/all/city', this.authToken());
  }
  public updateCity(city){
    return this.http.post(this.BASE_API_URL + '/update/city/' + city.id , JSON.stringify(city) , this.authToken());
  }
  public createCity(city){
    return this.http.post(this.BASE_API_URL + '/new/city' , JSON.stringify(city) , this.authToken());
  }
  public deleteCity(city){
    return this.http.post(this.BASE_API_URL + '/delete/city/' + city.id , JSON.stringify(city) , this.authToken());
  }
}
