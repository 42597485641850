<div class="main-container">
  <div class="upper-container">
    <div class="dialog-title">
      <span *ngIf="data.mode == 'edit'">
              {{'rabatFor' | translate}} {{data.rabat.product.name}}
      </span>
      <span *ngIf="data.mode == 'new'">
              {{'newRabat' | translate}}
      </span>
    </div>
    <hr>
    <div class="dialog-body row">
      <div class="left-container col-12 col-md-6">
        <div class="form-section">
          <span class="select-label">{{'supplier' | translate}}</span>
          <mat-select placeholder="{{'supplier' | translate}}" [(ngModel)]="data.rabat.supplierId"
                      (selectionChange)="getProducts(data.rabat.supplierId)">
            <mat-option *ngFor="let s of data.suppliers" [value]="s.id">
              {{s.name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-section">
          <span class="select-label">{{'customer' | translate}}</span>
          <mat-select [disabled]="!data.rabat.supplierId" placeholder="{{'customer' | translate}}" [(ngModel)]="data.rabat.customerId">
            <mat-option *ngFor="let c of data.customers" [value]="c.id">
              {{c.legal_name}}
            </mat-option>
          </mat-select>
        </div>
        <div class="form-section">
          <span class="select-label">{{'product' | translate}}</span>
          <mat-select (selectionChange)="changeRabatProduct()" [disabled]="!data.rabat.supplierId" placeholder="{{'product' | translate}}" [(ngModel)]="data.rabat.productId">
            <mat-option *ngFor="let p of products" [value]="p.id">
              {{p.name}}
            </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="right-container col-12 col-md-6">
        <div class="form-section">
          <span class="select-label"><mat-checkbox [(ngModel)]="this.data.rabat.hasCondition">{{'condition' | translate}}</mat-checkbox></span>
          <br>
          <mat-form-field>
          <input [disabled]="!data.rabat.supplierId || !this.data.rabat.hasCondition" type="number" matInput [(ngModel)]="data.rabat.conditionAmount" min="0"> <span matSuffix *ngIf="this.data.rabat.product">x {{this.data.rabat.product.measurementUnit}} </span>
          </mat-form-field>
        </div>
        <div class="form-section">
          <span class="select-label">{{'discountType' | translate}}</span>
          <mat-select  (selectionChange)="changeDiscountType()" [disabled]="!data.rabat.supplierId" placeholder="{{'discountType' | translate}}" [(ngModel)]="data.rabat.discountType">
            <mat-option [value]="0">
              {{'price' | translate}}
            </mat-option>
            <mat-option [value]="1">
              {{'percent' | translate}}
            </mat-option>
          </mat-select>
        </div>
        <mat-form-field class="form-section" *ngIf="this.data.rabat.discountType == 1" >
          <input matInput [disabled]="!data.rabat.supplierId"  type="number" [(ngModel)]="this.data.rabat.percent" min="0" max="100"
                 placeholder="{{'percent' | translate}}">
          <span matSuffix>%</span>
        </mat-form-field>
        <mat-form-field class="form-section" *ngIf="this.data.rabat.discountType == 0" >
          <input matInput [disabled]="!data.rabat.supplierId"  type="number" [(ngModel)]="this.data.rabat.newPrice" min="0"
                 placeholder="{{'price' | translate}}">
          <span matSuffix>RSD</span>
        </mat-form-field>
      </div>
    </div>
    <hr>
    <div class="closeButton" (click)="onCancel($event)">
      <span class="buttonText">{{'close' | translate | uppercase}} </span>
    </div>

    <div class="confirmButton" (click)="onConfirm($event)">
      <span class="buttonText">{{'confirm' | translate | uppercase}} </span>
    </div>
  </div>
</div>
