import {Component, OnInit, Renderer2} from '@angular/core';
import {Product, ProductFilter, Supplier} from "../model";
import {MatDialog, MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {StartComponent} from "../start/start.component";
import {ApiServiceService} from "../api-service.service";
import {MainComponent} from "../main/main.component";
import {NewProductComponent} from "../new-product/new-product.component";
import {EditProductComponent} from "../edit-product/edit-product.component";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  public page = 0;
  public timer;
  public products: Product[];
  public loading: boolean;
  public searchText: string;
  public hovered = [];
  public showPlaceholder = true;
  public srcIcon = '/assets/gear.png';
  public suppliers: Supplier[];
  public allSuppliersList: Supplier[];
  public supplier: Supplier;
  public manufacturerPage = 0;
  public supplierPage = 0;
  public allManufactures = false;
  public allSuppliers = false;
  public title = 'products';
  public predictions = [];
  public productFilter: ProductFilter = {suppliers: [0]};

  constructor(private snackBar: MatSnackBar, private translate: TranslateService, private renderer: Renderer2, private dialog: MatDialog, private router: Router, public start: StartComponent, private apiService: ApiServiceService, public main: MainComponent) {
  }

  public ngOnInit() {
    this.getSuppliers();
    if (localStorage.getItem('searchText')) {
      this.start.searchText = localStorage.getItem('searchText');
      if (this.start.searchText != '') {
        this.showPlaceholder = false;
        this.productFilter.name = this.start.searchText;
        this.renderer.selectRootElement('#searchInput').focus();
      }
    }
    if (localStorage.getItem('productsTitle')) {
      this.title = localStorage.getItem('productsTitle');
      this.main.changeText = true;
      this.main.text = this.title;
      localStorage.removeItem('productsTitle');
    }

    if (localStorage.getItem('supplier')) {
      this.supplier = JSON.parse(localStorage.getItem('supplier'));
    } else {
      this.router.navigate(['main/start']);
    }

    if (localStorage.getItem('actionFilter')) {
      this.productFilter = JSON.parse(localStorage.getItem('actionFilter'));
      this.filterProducts();
    } else {
      this.productFilter = {};
      this.getProducts();

    }

    if (window.innerWidth > 1400) {
      this.srcIcon = '/assets/search_top.png';
    } else if (window.innerWidth <= 1400) {
      this.srcIcon = '/assets/gear.png';
    }
  }

  public addToHovered(id) {
    this.hovered.push(id);
  }

  public removeHovered(id) {
    const index = this.hovered.findIndex((x) => x == id);

    if (index != -1) {
      this.hovered.splice(index, 1);
      this.hovered = [...this.hovered];
    }
  }

  public checkIfHovered(id) {
    const index = this.hovered.findIndex((x) => x == id);

    if (index != -1) {
      return true;
    } else {
      return false;
    }
  }

  public openProductDetails(id) {
    this.router.navigate(['main/product', id]);
  }

  public newProduct() {
    const dialogRef = this.dialog.open(NewProductComponent, { maxHeight: '70vh !important',
      data: {suppliers: this.allSuppliersList}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.products = [...[result], ...this.products];
      }
    });
  }

  public ifMobileFilter() {
    if (window.innerWidth <= 1400) {
      this.router.navigate(['main/action/filter']);
    }
  }

  public adjustPrice(index) {
    let price = 0;

    const product = this.products[index];

    // check if rabat exists

    if (product.rabat) {
      if (product.rabat.newPrice) { // first case, price automatically changed
        price = product.rabat.newPrice;
      } else if (product.rabat.percent && !product.rabat.conditionAmount) { // second case, no condition
        price = product.fullPrice - (product.fullPrice / 100 * product.rabat.percent);
      } else if (product.chosenAmount >= product.rabat.conditionAmount) { // condition met
        price = product.fullPrice - (product.fullPrice / 100 * product.rabat.percent);
      } else if (product.action && product.action > 0) { // if rabat condition not met, check if there's action
        price = product.fullPrice - (product.fullPrice / 100 * product.action);
      } else {
        price = product.fullPrice;
      }

    } else if (product.action && product.action > 0) { // if rabat doesn't exist, check if there's action
      price = product.fullPrice - (product.fullPrice / 100 * product.action);
    }

    this.products[index].price = price;
    this.products = [...this.products];

  }

  public resetFilter() {
    this.productFilter = {suppliers: [0]};
    localStorage.setItem('actionFilter', JSON.stringify(this.productFilter));
    this.filterProducts();
  }

  public searchProducts() {
    this.productFilter.name = this.start.searchText;
    localStorage.setItem('searchText', this.start.searchText);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.loading = true;
      this.apiService.filterProducts(this.productFilter, 0).subscribe(
        (products: Product[]) => {
          this.products = [...products];
          this.loading = false;
        }, (err) => {
          localStorage.setItem('autoLogin', this.router.url);
          this.main.autoLogin(err.status);
        },
      );
      if (this.start.searchText != '') {
        this.apiService.getProductPrediction(this.start.searchText).subscribe(
          (data: string[]) => {
            this.predictions = data;
          }, (err) => {
            localStorage.setItem('autoLogin', this.router.url);
            this.main.autoLogin(err.status);
          },
        );
      }

    }, 500);
  }

  public closePrediction() {
    this.predictions = [...[]];
  }

  public chosePrediction(name) {
    this.start.searchText = name;
    this.productFilter.name = name;
    localStorage.setItem('searchText', this.start.searchText);
    this.predictions = [];
    this.loading = true;
    this.apiService.filterProducts(this.productFilter, 0).subscribe(
      (products: Product[]) => {
        this.products = [...products];
        this.loading = false;
      }, (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );
  }

  public checkIfSelected(id, type) {

    if (type == 'a') { // active


      if (!this.productFilter.active) {
        return 1;
      }

      if (id == 1) {
        return this.productFilter.active;
      } else if (id == 0) {
        return this.productFilter.unactive;
      }

    } else if (type == 's') {// stock

      if (!this.productFilter.stock) {
        return true;
      }

      if (id === 1) {
        if (this.productFilter.stock == 1) {
          return true;
        }
      } else if (id === 0) {
        if (this.productFilter.stock == 0) {
          return true;
        }
      }
    } else if (type == 'act') {// action

      if (!this.productFilter.actionOnly) {
        return true;
      }

      if (id === 1) {
        if (this.productFilter.actionOnly == 1) {
          return true;
        }
      } else if (id === 0) {
        if (this.productFilter.actionOnly == 0) {
          return true;
        }
      }
    } else if (type == 'supplier') {
      const index = this.productFilter.suppliers.findIndex(x => x == id);

      if (index == -1) {
        return false;
      } else {
        return true;
      }
    }

  }

  public toggleCheck(id, type) {
    if (type === 'a') { // active

      if (id == 1) {
        this.productFilter.active = 1;
        this.productFilter.unactive = 0;
      } else {
        this.productFilter.active = 0;
        this.productFilter.unactive = 1;
      }


    } else if (type === 's') {// stock

      if (id == 1) {
        this.productFilter.stock = 1;
        this.productFilter.nonStock = 0;
      } else {
        this.productFilter.stock = 0;
        this.productFilter.nonStock = 1;
      }

    } else if (type === 'act') {// action

      if (id == 1) {
        this.productFilter.actionOnly = 1;
        this.productFilter.actionNo = 0;
      } else {
        this.productFilter.actionOnly = 0;
        this.productFilter.actionNo = 1;
      }
    } else if (type === 'supplier') {
      const index = this.productFilter.suppliers.findIndex(x => x == id);

      if (index == -1) {
        if (id == 0) {
          this.productFilter.suppliers = [0];
        }
        this.productFilter.suppliers.push(id);
      } else {
        this.productFilter.suppliers.splice(index, 1);
      }

      if (this.productFilter.suppliers.length > 0) { // if any other option checked remove 'all'
        const zeroIndex = this.productFilter.suppliers.findIndex(x => x == 0);
        if (zeroIndex != -1) {
          this.productFilter.suppliers.splice(zeroIndex, 1);
        }

      }

      if (this.productFilter.suppliers.length == 0) {
        this.productFilter.suppliers = [0];
      }

      this.productFilter.suppliers = [...this.productFilter.suppliers];
    }

    localStorage.setItem('actionFilter', JSON.stringify(this.productFilter));
    this.filterProducts();
  }

  public editProduct(id) {
    const newProductForSending = this.products[this.products.findIndex((p) => p.id == id)];
    localStorage.setItem('productForLater', JSON.stringify(this.products[this.products.findIndex((p) => p.id == id)]));
    const dialogRef = this.dialog.open(EditProductComponent, { maxHeight: '70vh !important',
      data: {suppliers: this.allSuppliersList, product: newProductForSending}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result == 'deactivate') {
          this.filterProducts();
        } else {
          const newProduct = result;
          const index = this.products.findIndex((p) => p.id === newProduct.id);
          if (index !== -1) {
            this.products[index] = newProduct;
          }
          this.products = [...this.products];
        }
      } else {
        const newProduct = JSON.parse(localStorage.getItem('productForLater'));
        localStorage.removeItem('productForLater');
        const index = this.products.findIndex((p) => p.id === newProduct.id);
        if (index !== -1) {
          this.products[index] = newProduct;
        }
        this.products = [...this.products];
      }
    });
  }

  public filterProducts() {
    this.productFilter.name = this.start.searchText;
    this.loading = true;
    this.apiService.filterProducts(this.productFilter, 0).subscribe(
      (products: Product[]) => {

        this.products = [...products];
        this.loading = false;
      },
      (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.loading = false;
        this.main.autoLogin(err.status);
      },
    );
  }

  public getSuppliers() {
    this.apiService.getAllSuppliers().subscribe(
      (data: Supplier[]) => {
        this.allSuppliersList = data;
        this.suppliers = data.slice(0, 4);
      },
      (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );
  }

  public onResize(event) {
    if (window.innerWidth > 1400) {
      this.srcIcon = '/assets/search_top.png';
    } else {
      this.srcIcon = '/assets/gear.png';
    }
  }

  public getProducts() {
    this.loading = true;
    const filter = {active: 1, stock: 1};
    this.apiService.filterProducts(filter, this.page).subscribe(
      (products: Product[]) => {
        this.products = products;
        this.loading = false;
      },
      (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.loading = false;
        this.main.autoLogin(err.status);
      },
    );
  }

  public activate(id) {
    const dialogRef = this.dialog.open(DialogComponent, {maxWidth: '90vw !important',
      data: {type: 'actionButton', text: this.translate.instant('areYouSureYouWantToActivate')}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.activateProduct(id).subscribe(
          (data) => {
            this.filterProducts();
          }, (err) => {
            this.main.autoLogin(err.status);
          }
        );
      }
    });
  }

  public delete(id) {
    const dialogRef = this.dialog.open(DialogComponent, { maxWidth: '90vw !important',
      data: {type: 'actionButton', text: this.translate.instant('areYouSureYouWantToDelete')}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService.deleteProduct(id).subscribe(
          (data) => {
            this.filterProducts();
          }, (err) => {
            this.main.autoLogin(err.status);
          }
        );
      }
    });
  }

  public onFocus() {
    this.showPlaceholder = false;
  }

  public onBlur() {
    if (this.start.searchText === '') {
      this.showPlaceholder = true;
    }
  }

  public expandProduct(id) {
    const index = this.products.findIndex((x) => x.id === id);
    if (this.products[index].expanded === true) {
      this.products[index].expanded = false;
    } else {
      this.products[index].expanded = true;
    }

    this.products = [...this.products];
  }


  public toggleChosenPacket(id) {
    const index = this.products.findIndex((x) => x.id === id);
    if (this.products[index].chosenPacket === true) {
      this.products[index].chosenPacket = false;
    } else {
      this.products[index].chosenPacket = true;
    }

    this.products = [...this.products];
  }


  public addAmount(id) {
    const diff = 0;
    const index = this.products.findIndex((x) => x.id === id);

    if (!this.products[index].chosenAmount) {
      this.products[index].chosenAmount = 0;
    }

    if (this.products[index].chosenPacket) {

      this.products[index].chosenAmount = this.products[index].chosenAmount + (1 * this.products[index].packetNumber);
    } else {
      this.products[index].chosenAmount = this.products[index].chosenAmount + 1;
    }
    this.adjustPrice(index);
    this.products = [...this.products];
  }

  public removeAmount(id) {
    const index = this.products.findIndex((x) => x.id === id);

    if (!this.products[index].chosenAmount) {
      this.products[index].chosenAmount = 0;
    }

    if (this.products[index].chosenPacket) {
      this.products[index].chosenAmount = this.products[index].chosenAmount - (1 * this.products[index].packetNumber);
    } else {
      this.products[index].chosenAmount = this.products[index].chosenAmount - 1;
    }

    if (this.products[index].chosenAmount < 0) {
      this.products[index].chosenAmount = 0;
    }

    this.adjustPrice(index);
    this.products = [...this.products];
  }

  public onScroll() {
    this.page++;
    this.apiService.filterProducts(this.productFilter, this.page).subscribe(
      (products: Product[]) => {
        this.products = [...this.products, ...products];
      },
      (err) => {
        localStorage.setItem('autoLogin', this.router.url);
        this.main.autoLogin(err.status);
      },
    );

  }

  public showAll(type) {
    this.suppliers = this.allSuppliersList;

    this.allSuppliers = true;
  }

  public changeAction(type, id) {
    let text = '';
    let dialogType = '';
    const product = {action: 0, id: 0, actionEnd: ''
    };
    product.id = id;
    if (type === 'add') {
      text = this.translate.instant('insertAmountOfAction');
      dialogType = 'actionAdd';
    } else if (type === 'remove') {
      text = this.translate.instant('areYouSureYouWantToRemoveAction');
      dialogType = 'actionButton';
    }

    const dialogRef = this.dialog.open(DialogComponent, { maxWidth: '90vw !important',
      data: {type: dialogType, text}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (type === 'remove') {
            product.action = 0;
          } else {
            product.action = result['action'];
            product.actionEnd = result['dateTo'];
          }
          this.apiService.updateProductLimit(product).subscribe(
            (liveProduct: Product) => {
              const index = this.products.findIndex((x) => x.id === id);
              if (index !== -1) {
                this.products[index].action = liveProduct.action;
                this.products[index].price = liveProduct.price;
                this.products[index].fullPrice = liveProduct.fullPrice;
                this.products = [...this.products];
              }

            },
            (err) => {
              this.main.autoLogin(err.status);
            },
          );
        }
      },
    );
  }

  public changeStock(type, id) {
    let text = '';
    const product = {stock: 0, id: 0};
    product.id = id;
    if (type === 'add') {
      text = this.translate.instant('areYouSureYouWantToAddStock');
      product.stock = 1;
    } else if (type === 'remove') {
      text = this.translate.instant('areYouSureYouWantToRemoveStock');
      product.stock = 0;
    }

    const dialogRef = this.dialog.open(DialogComponent, { maxWidth: '90vw !important',
      data: {type: 'actionButton', text}, panelClass: 'warningDialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.apiService.updateProductLimit(product).subscribe(
            (liveProduct: Product) => {
              const index = this.products.findIndex((x) => x.id === id);
              if (index !== -1) {
                this.products[index].inStock = liveProduct.inStock;
                this.products = [...this.products];
              }

            },
            (err) => {
              this.main.autoLogin(err.status);
            },
          );
        }
      },
    );
  }

}

