<div [ngClass]="{'footerBig' : router.url != '/contact' && router.url != '/main/orders', 'footerBigBottom' : router.url == '/contact' || router.url == '/main/profile', 'footerBigOrders' :  router.url == '/main/history', 'footerBigOrders2' : router.url == '/main/orders'}">
  <div class="footerUp">
    <div class="leftFooter">
      <span class="footerBigTitle">{{'usefulLinks' | translate}}</span> <br> <br>
      <span class="footerBigEl">{{'faq' | translate}}</span> <br>
      <span class="footerBigEl">{{'termsOfUse' | translate}}</span> <br>
      <span class="footerBigEl">{{'privacyPolicy' | translate}}</span> <br>

    </div>

    <div class="rightFooter">
      <span class="footerBigTitle">{{'contactUs' | translate}}</span> <br> <br>
      <span class="footerBigEl">Call centar: +381 63 70 84 005 </span> <br>
      <span class="footerBigEl">E-mail: kontakt@enabavka.rs</span> <br>
      <span class="footerBigEl">{{'address' | translate}}: Beograd 123</span> <br>
    </div>

  </div>
  <div class="footerDown">
    <span class="copyright">E-nabavka</span>
  </div>
</div>
