import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {ApiServiceService} from '../api-service.service';
import {MainComponent} from '../main/main.component';
import {Cart, Product, Supplier} from '../model';
import {OrderEditComponent} from "../order-edit/order-edit.component";
import {CommunicationService} from "../communication.service";
import * as FileSaver from 'file-saver';

class DialogData {
  public order: Cart;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})

export class OrderComponent implements OnInit {

  public supplier: Supplier;
  public loadingEmail = false;

  constructor(private communicator: CommunicationService, private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar, private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<OrderComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role == 2) {
      const suppliersProducts = [];
      this.data.order.products.forEach((product) => {
        if (product.product.supplier.id == this.supplier.id) {
          suppliersProducts.push(product);
        }
      });
      this.data.order.products = [...suppliersProducts];

      const supplierOnly = [];
      this.data.order.suppliers.forEach((supplier) => {
        if (supplier.supplierId == this.supplier.id) {
          supplierOnly.push(supplier);
        }
      });
      this.data.order.suppliers = [...supplierOnly];
    }
    console.log(this.data.order);

  }

  public sendInvoice() {
    this.loadingEmail = true;
    this.apiService.sendInvoice(this.data.order.id).subscribe(
      (data: { mail }) => {
        this.loadingEmail = false;
        this.snackBar.open(this.translate.instant('emailSentTo') + data.mail, 'OK', {duration: 3000});
      }, (err) => {
        this.main.autoLogin(err.status);
      },
    );
  }

  public editOrder() {
    const dialogRef = this.dialog.open(OrderEditComponent, {
      data: {order: this.data.order, panelClass: 'warningDialog'}
    });

    const oldOrderProducts = this.data.order.products;
    const oldOrderPrice = this.data.order.price;
    const oldOrderDelivery = this.data.order.deliveryPrice;



    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
       this.data.order = result;
       this.communicator.updatedOrder.next(this.data.order);
      } else {
        this.data.order.price = oldOrderPrice;
        this.data.order.deliveryPrice = oldOrderDelivery;
        this.data.order.products = oldOrderProducts;
      }
    });
  }
 public chengeRead(order){
    this.apiService.updateRead(order).subscribe(
      (response : Cart) => {
        this.snackBar.open(this.translate.instant('successfully_confirmed')
      , 'OK', {duration: 3000});
        this.data.order.isRead = true;
        this.communicator.updatedOrder.next(order);
      }
    )
 }
  public printOrder() {
    this.apiService.printInvoice(this.data.order.id).subscribe(
      (data) => {
        FileSaver.saveAs(data, "enabavka.pdf");
      });

  }

}
