import {Component, Inject, OnInit} from '@angular/core';
import {CustomerLite, Product, Rabat, Supplier} from '../model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {MainComponent} from '../main/main.component';
import {ApiServiceService} from '../api-service.service';

class DialogData {
  public rabat: Rabat;
  public mode: string;
  public suppliers: Supplier[];
  public customers: CustomerLite[];
}

@Component({
  selector: 'app-rabat-component',
  templateUrl: './rabat-component.component.html',
  styleUrls: ['./rabat-component.component.scss']
})
export class RabatComponentComponent implements OnInit {

  public supplier: Supplier;
  public products: Product[] = [];
  constructor(private dialog: MatDialog, private translate: TranslateService, private snackBar: MatSnackBar , private main: MainComponent, private apiService: ApiServiceService, public dialogRef: MatDialogRef<RabatComponentComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  public ngOnInit() {
    this.supplier = JSON.parse(localStorage.getItem('supplier'));

    if (this.supplier.role == 2) {
      this.getProducts(this.supplier.id);
    }

    if (this.supplier.role == 3 && this.data.mode == 'edit') {
      this.getProducts(this.data.rabat.supplierId);
    }

    if (this.data.mode == 'edit') {
      if (this.data.rabat.conditionAmount) {
        this.data.rabat.hasCondition = true;
      } else {
        this.data.rabat.hasCondition = false;
      }

      if (this.data.rabat.newPrice) {
        this.data.rabat.discountType = 0;
      } else if (this.data.rabat.percent) {
        this.data.rabat.discountType = 1;
      }
    } else if (this.data.mode == 'new') {
      this.data.rabat.conditionAmount = 0;
    }
  }

  public changeRabatProduct() {
    const index = this.products.findIndex((x) => x.id == this.data.rabat.productId);

    if (index != -1) {
      this.data.rabat.product = this.products[index];
    }

  }

  public getProducts(id) {
      this.apiService.getProductsPerSupplierId(id).subscribe(
        (products: Product[]) => {
          this.products = products;
        },
        (err) => {
          this.main.autoLogin(err.status);
        }
      );
  }

  public onCancel($event) {
    this.dialogRef.close(null);
  }

  public onConfirm(event) {
   if (this.data.rabat.discountType == 1) { // percent
     if (!this.data.rabat.percent || this.data.rabat.percent < 0 || this.data.rabat.percent > 100) {
       this.snackBar.open(this.translate.instant('insertValidValues'), 'OK', {duration: 3000});
       return;
     }
   }

   if (this.data.rabat.discountType == 0) { // percent
      if (!this.data.rabat.newPrice || this.data.rabat.newPrice <= 0) {
        this.snackBar.open(this.translate.instant('insertValidValues'), 'OK', {duration: 3000});
        return;
      }
    }

   if (this.data.mode == 'new') {

     this.data.rabat.conditionUnit = this.data.rabat.product.measurementUnitId;

     this.apiService.createRabat(this.data.rabat).subscribe(
       (rabat) => {
         this.dialogRef.close(rabat);
       }, (err) => {
         this.main.autoLogin(err.status);
       }
     );

   } else if (this.data.mode == 'edit') {
     this.data.rabat.conditionUnit = this.data.rabat.product.measurementUnitId;
   }

   this.apiService.updateRabat(this.data.rabat).subscribe(
     (rabat) => {
       this.dialogRef.close(rabat);
     }, (err) => {
       this.main.autoLogin(err.status);
     }
   );
  }

  public changeDiscountType() {
    if (this.data.rabat.discountType == 1) { //percent
      this.data.rabat.newPrice = null;
    } else { //new price
      this.data.rabat.percent = null;
    }
  }



}
